<template>
  <div class="min-h-screen flex items-center justify-center bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
    <div class="max-w-md w-full space-y-8 bg-white p-8 rounded-xl shadow-lg">
      <!-- Header -->
      <div class="text-center">
        <h2 class="text-3xl font-bold text-gray-900">
          Mirë se vini
        </h2>
        <p class="mt-2 text-sm text-gray-600">
          Ju lutemi hyni në llogarinë tuaj
        </p>
      </div>

      <!-- Form -->
      <form class="mt-8 space-y-6">
        <!-- Email Field -->
        <div>
          <label for="email" class="block text-sm font-medium text-gray-700">
            Adresa e emailit
          </label>
          <div class="mt-1 relative rounded-md shadow-sm">
            <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
              <i class="fa-regular fa-envelope text-gray-400"></i>
            </div>
            <input
              id="email"
              v-model="user.email"
              type="email"
              required
              class="block w-full pl-10 pr-3 py-2 border border-gray-300 rounded-md leading-5 bg-white placeholder-gray-500 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent transition-colors"
              placeholder="Shkruani emailin tuaj"
            />
          </div>
        </div>

        <!-- Password Field -->
        <div>
          <label for="password" class="block text-sm font-medium text-gray-700">
            Fjalëkalimi
          </label>
          <div class="mt-1 relative rounded-md shadow-sm">
            <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
              <i class="fa-solid fa-lock text-gray-400"></i>
            </div>
            <input
              id="password"
              v-model="user.password"
              type="password"
              required
              class="block w-full pl-10 pr-3 py-2 border border-gray-300 rounded-md leading-5 bg-white placeholder-gray-500 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent transition-colors"
              placeholder="Shkruani fjalëkalimin tuaj"
            />
          </div>
        </div>

        <!-- Action Buttons -->
        <div class="flex items-center justify-between pt-6 border-t border-gray-200">
          <!-- Register Link -->
          <router-link 
            to="/register"
            class="group flex items-center text-sm font-medium text-gray-600 hover:text-gray-900 transition-colors"
          >
            <i class="fa-solid fa-chevron-left mr-2 text-gray-400 group-hover:text-gray-600 transition-colors"></i>
            Regjistrohu
          </router-link>

          <!-- Login Button -->
          <button
            @click.prevent="login"
            class="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 transition-colors"
          >
            Hyr
            <i class="fa-solid fa-arrow-right ml-2"></i>
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
	import { storeToRefs } from 'pinia'
	import { useUserStore } from '@/stores/user'
	import Api from '@/services/Api'
	import permissionMixin from '@/mixins/permissions-mixin.js'
	import { useConfigurationsStore } from '@/stores/configurations'
	var CryptoJS = require("crypto-js");

	export default{
		mixins: [permissionMixin],
		data(){
			return {
				user: {
					email: '',
					password: ''
				},
				
				user_store: storeToRefs(useUserStore())
			}
		},
		created(){
			this.$document.title = "Login"
		},
		methods:{
			login(){
				Api(false).post('user/login', this.user)
				.then(async (r) => {
					if(!r.data.token){
						// Toaster for incorrect credentials
						this.$toast.error('Gabim! Kontrolloni email dhe password.')
					}
					localStorage.user = JSON.stringify(r.data.user)
					this.user_store.user = r.data.user
					localStorage.token = r.data.token
					localStorage.user_steps = JSON.stringify(r.data.user_steps.map(x => {return x.step_id}))

					// Get permissions list
					await Api(true).post('permissions/list').then(res => {
						this.permissions_modules = res.data
						localStorage.user_roles = JSON.stringify(res.data)
					})

					// Save permissions to localstorage
					if(r.data.user_permissions){
						var a = CryptoJS.AES.encrypt(JSON.stringify(r.data.user_permissions), localStorage.token).toString();
						localStorage.permissions = a
					}

					this.$router.push({ path: '/home'})
					this.user_store.is_loggedin = true

					// get general configurations 
					useConfigurationsStore().getConfigs()

					// Get user notifications
					useUserStore().get_user_notifications(r.data.user)	

					// setTimeout(() => {
					// 	window.location.reload();
					// }, 1000);
				})
			}
		}
	}
</script>

<style scoped>
@keyframes fadeIn {
  from { opacity: 0; transform: translateY(-20px); }
  to { opacity: 1; transform: translateY(0); }
}

.max-w-md {
  animation: fadeIn 0.5s ease-out;
}
</style>

