import axios from 'axios'

export default (token) => {
	var auth = "none"
	if (token) auth = "Bearer " + localStorage.token
	return axios.create({
		baseURL: process.env.NODE_ENV == 'production'
			? 'https://backend.sistemi.globe.com.al/api/'
			: 'http://' + location.hostname + ':8000/api/',
		headers: {
			Authorization: auth
		}
	})
}
