<template>
  <div class="flex flex-col h-screen">
    <!-- Header -->
    <div class="px-4 py-3 shadow-md" :class="props.isClosed ? 'bg-orange-700' : 'bg-blue-600' ">
      <div class="flex items-center justify-between ">
				<h1 class="text-white text-xl font-semibold">Ditare te {{props.isClosed ? 'mbyllur' : 'hapur'}}</h1>
        <div class="flex space-x-3" v-if="!props.isClosed">
          <button @click="toggle_discount_request_list()" 
            class="flex items-center px-3 py-1.5 text-sm text-white border border-white/70 rounded-md hover:bg-blue-500 transition-colors">
            <i :class="['fa-regular mr-2', useDiaryStore().discount_list_mode ? 'fa-circle-dot' : 'fa-circle']"></i>
            Pret zbritje
          </button>
          <button @click="toggle_customer_waiting_mode()"
            class="flex items-center px-3 py-1.5 text-sm text-white border border-white/70 rounded-md hover:bg-blue-500 transition-colors">
            <i :class="['fa-regular mr-2', useDiaryStore().customer_waiting_mode ? 'fa-circle-dot' : 'fa-circle']"></i>
            Ne pritje nga klienti
          </button>
        </div>
      </div>
    </div>

    <!-- Filters -->
    <div class="px-4 py-3 border-b">
      <div class="flex items-center justify-center">
        <div class="flex items-center w-full max-w-3xl px-4 py-2 bg-white border rounded-lg shadow-sm">
          <button @click="useDiaryStore().resetFilters" 
            class="p-2 text-gray-500 hover:text-blue-600 border-r">
            <svg class="w-4 h-4" viewBox="0 0 24 24">
              <path d="M17.65 6.35A7.958 7.958 0 0012 4c-4.42 0-7.99 3.58-7.99 8s3.57 8 7.99 8c3.73 0 6.84-2.55 7.73-6h-2.08A5.99 5.99 0 0112 18c-3.31 0-6-2.69-6-6s2.69-6 6-6c1.66 0 3.14.69 4.22 1.78L13 11h7V4l-2.35 2.35z"/>
            </svg>
            <div v-if="useDiaryStore().filtersEnabled" 
              class="absolute bottom-0 right-0 w-2 h-2 bg-red-500 rounded-full"></div>
          </button>

          <div class="relative px-4 border-r">
            <button @click="useDiaryStore().show_status_dropdown = !useDiaryStore().show_status_dropdown"
              class="flex items-center text-gray-600 hover:text-blue-600">
              <span>Statusi</span>
              <i class="fa-solid fa-angle-down ml-2"></i>
            </button>
            
            <div v-if="useDiaryStore().show_status_dropdown" 
              class="absolute left-0 z-10 w-48 mt-2 bg-white border rounded-md shadow-lg">
              <div class="py-1">
                <button @click="useDiaryStore().diary_filter_status = null, useDiaryStore().show_status_dropdown = null, useDiaryStore().change_page(1)"
                  class="block w-full px-4 py-2 text-left hover:bg-gray-100">
                  Te gjithe
                </button>
                <button v-for="status in useDiaryStore().diary_statuses.filter(status => status.is_closed == 0)"
                  :key="status.id"
                  @click="useDiaryStore().diary_filter_status = status.id, useDiaryStore().show_status_dropdown = null, useDiaryStore().change_page(1)"
                  :class="{'bg-blue-50': status.id === useDiaryStore().diary_filter_status}"
                  class="block w-full px-4 py-2 text-left hover:bg-gray-100">
                  {{ status.name }}
                </button>
              </div>
            </div>
          </div>

          <div class="px-4 border-r">
            <input v-model="useDiaryStore().diary_filter_id"
              @keyup="useDiaryStore().change_page(1)"
              placeholder="Kerko ID"
              class="w-24 px-3 py-1.5 text-sm border rounded-md focus:outline-none focus:ring-1 focus:ring-blue-500">
          </div>

          <div class="flex items-center flex-1 px-4">
            <input type="text"
              v-model="useDiaryStore().diary_filter_text"
              @keyup="useDiaryStore().change_page(1)"
              placeholder="Kerko..."
              class="w-full px-3 py-1.5 text-sm border rounded-md focus:outline-none focus:ring-1 focus:ring-blue-500">
            <i class="fa fa-search fa-sm ml-3 text-gray-400"></i>
          </div>
        </div>
      </div>
    </div>

    <!-- Table -->
    <div class="flex-1 px-4 overflow-auto">
      <table class="w-full border-collapse">
        <thead>
          <tr class="text-sm text-left text-gray-700 bg-gray-50">
            <th @click="useDiaryStore().sortBy('id')" 
              class="px-4 py-3 font-medium border cursor-pointer hover:bg-gray-100 w-16 text-center">
              #
            </th>
            <th @click="useDiaryStore().sortBy('customer_name')" 
              class="px-4 py-3 font-medium border cursor-pointer hover:bg-gray-100">
              <div class="flex items-center justify-between">
                <span>Klienti</span>
                <i v-if="useDiaryStore().sort['customer_name']" 
                  :class="['fa', useDiaryStore().sort['customer_name'] === 'desc' ? 'fa-sort-down' : 'fa-sort-up']">
                </i>
              </div>
            </th>
            <th @click="useDiaryStore().sortBy('status')" 
              class="px-4 py-3 font-medium border cursor-pointer hover:bg-gray-100">
              <div class="flex items-center justify-between">
                <span>Statusi</span>
                <i v-if="useDiaryStore().sort['status']" 
                  :class="['fa', useDiaryStore().sort['status'] === 'desc' ? 'fa-sort-down' : 'fa-sort-up']">
                </i>
              </div>
            </th>
            <th @click="useDiaryStore().sortBy('created_at')" 
              class="px-4 py-3 font-medium border cursor-pointer hover:bg-gray-100">
              <div class="flex items-center justify-between">
                <span>Krijuar</span>
                <i v-if="useDiaryStore().sort['created_at']" 
                  :class="['fa', useDiaryStore().sort['created_at'] === 'desc' ? 'fa-sort-down' : 'fa-sort-up']">
                </i>
              </div>
            </th>
            <th @click="useDiaryStore().sortBy('valid_until')" 
              class="px-4 py-3 font-medium border cursor-pointer hover:bg-gray-100">
              <div class="flex items-center justify-between">
                <span>Skadon me</span>
                <i v-if="useDiaryStore().sort['valid_until']" 
                  :class="['fa', useDiaryStore().sort['valid_until'] === 'desc' ? 'fa-sort-down' : 'fa-sort-up']">
                </i>
              </div>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="diary in useDiaryStore().diaries_list" 
            :key="diary.id"
            class="hover:bg-gray-50 transition-colors">
            <td @click.prevent="openDiary(diary.guid)"
              class="px-4 py-3 border cursor-pointer hover:bg-blue-50 text-center">
              <i class="fa fa-hashtag text-gray-300 fa-xs"></i>
              {{ diary.id }}
            </td>
            <td class="border">
              <div class="flex items-center justify-between px-4 py-3">
                <span v-if="diary.customer_name">
                  <i class="fa fa-user text-gray-400 mr-2"></i>
                  {{ diary.customer_name }}
                </span>
                <span>
                  <i class="fa fa-list"></i>
                </span>
              </div>
            </td>
            <td class="border">
              <div class="px-4 py-3 flex justify-between items-center">
                <div class="flex flex-col">
                  <span
                    @click="openDiaryDiscount(diary)"
                    :style="{
                      backgroundColor: diary.bg_color,
                      color: diary.text_color,
                    }"
                    class="px-2 py-1 rounded text-xs"
                    :class="{'cursor-pointer': diary.status_id == 6}"
                    style="width: fit-content;">
                    <i class="fa fa-circle fa-xs mr-1"></i>
                    {{ diary.status }}
                  </span>
                  <span v-if="diary.requested_by_customer"
                    class="px-2 py-1 rounded text-xs bg-teal-300 mt-2">
                    <i class="fa fa-circle fa-xs mr-1"></i>
                    Ne pritje te konfirmimit te klientit
                  </span>
                </div>
                <span class="text-gray-400 flex flex-col items-end text-xs">
                  <span>
                    {{ diary.status_created_by }}
                  </span>
                  <span class="font-thin">
                    {{ moment(diary.status_created_at).fromNow() }}
                  </span>
                </span>
              </div>
            </td>
            <td class="border">
              <div class="px-4 py-3 flex justify-between items-center">
                <span>
                  {{ diary.name }}
                </span>
                <span class="text-gray-400 text-xs">
                  {{ moment(diary.status_created_at).format("DD/MM/YYYY HH:mm") }}
                </span>
              </div>
            </td>
            <td :class="['border', getStatusClass(diary)]">
              <div class="px-4 py-3 flex items-center">
                <span class="text-gray-500 text-xs ml-auto">
                  {{ moment(diary.valid_until).format("DD/MM/YYYY HH:mm") }}
                </span>
              </div>
              <div v-if="diary.requested_by_customer && !diary.reject_reason_text"
                class="flex flex-col text-xs text-right border-t border-b px-4 py-2">
                <span>Ne pritje te konfirmimit nga klienti</span>
                <span>
                  {{ moment(diary.requested_by_customer).format("DD/MM/YYYY HH:mm") }}
                </span>
              </div>
              <div v-if="diary.requested_text && !diary.reject_reason_text"
                class="flex flex-col text-xs text-right border-t border-b px-4 py-2">
                <span>Arsyet e pritjes</span>
                <span>
                  {{ diary.requested_text }}
                </span>
              </div>
              <div v-if="diary.reject_reason_text"
                class="flex flex-col text-xs text-right border-t border-b px-4 py-2">
                <span>Pritje e anulluar nga klienti: </span>
                <span>
                  {{ diary.reject_reason_text }}
                </span>
                <span v-if="diary.reject_text">
                  {{diary.reject_text}}
                </span>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <!-- Pagination -->
    <div class="px-4 py-3 border-t">
      <Dapaginator 
        :totalPages="useDiaryStore().total_pages" 
        :currentPage="useDiaryStore().current_page" 
        @changePage="useDiaryStore().change_page"
        class="flex justify-center"/>
    </div>
  </div>
</template>

<script setup>
import { useDiaryStore } from "@/stores/diary";
import { onMounted } from "vue";
import { useRouter } from "vue-router";
import moment from "moment";
import { defineProps } from 'vue'

import Dapaginator from '@/components/Dapaginator.vue'

const router = useRouter();

const openDiary = (id) => {
  router.push({ name: "Ditar Shiko", params: { id } });
};

const openDiaryDiscount = (diary) => {
  if (diary.status_id == 6) {
    router.push({ name: "Konfirmo zbritje", params: { id: diary.discount_request_id } });
  }
};

const getStatusClass = (diary) => {
  const expirationDate = moment(diary.valid_until);
  const currentDate = moment();

  if (expirationDate.isBefore(currentDate)) {
    return "bg-red-50";
  } else if (
    expirationDate.isSameOrAfter(currentDate) &&
    expirationDate.diff(currentDate, "minutes") <= 5
  ) {
    return "bg-yellow-50";
  } else {
    return "bg-green-50";
  }
};

const toggle_discount_request_list = async () => {
  useDiaryStore().discount_list_mode = !useDiaryStore().discount_list_mode;
  useDiaryStore().customer_waiting_mode = false;
};

const toggle_customer_waiting_mode = async () => {
  useDiaryStore().discount_list_mode = false;
  useDiaryStore().customer_waiting_mode = !useDiaryStore().customer_waiting_mode;
};
const props = defineProps({
  isClosed: {
    type: Boolean,
    default: false
  }
})
onMounted(async () => {
	if(props.isClosed){ 
		await useDiaryStore().getDiaries({closed: true});
	}else{ 
		await useDiaryStore().getDiaries(); 
	}
  await useDiaryStore().get_diary_statuses();
  useDiaryStore().current_page = 1;
});
</script>

<style scoped>
/* Remove custom table styles since we're using Tailwind classes */
</style>
