import helpers from "@/helpers.js";
import CategoriesServices from "@/services/Category";
import ProductsServices from "@/services/Product";
import ServiceServices from "@/services/Service";
import ConfigurationService from "@/services/Configuration";
import PointsOfSaleServices from "@/services/PointOfSale";
import Api from "@/services/Api";

export default async function editProduct(pr) {
  var product_categories = await CategoriesServices.getCategories(
    "products"
  );


  var exclusive_entities = await ProductsServices.get_exclusive_entity(pr, null)

  var product_accessories = await CategoriesServices.getCategories("accessories")
  var details_types = helpers.toTree(product_categories, "id")
  var accessories = helpers.toTree(product_accessories, 'id')
  var selected_category = {};

  if (pr.is_accessory == 0)
    selected_category[pr.type_id] = true;
  else
    selected_category[pr.accessory_id] = true;

  // Get attributes categories
  var category_attributes_list = await CategoriesServices.getCategoryAttributes(
    selected_category,
    pr
  );
  var category_attributes = helpers.create_attributes_field(
    category_attributes_list,
    selected_category
  )

  // Get services categories
  var category_services_list = await CategoriesServices.getCategoryAttributes(
    selected_category,
    pr,
    null,
    'services'
  );
  var category_services = helpers.create_attributes_field(
    category_services_list,
    selected_category
  )
  var existing_exclusive_attribute = exclusive_entities.attributes.filter(a => a.category_id == 'EXCLUSIVE')
  var exclusive_attributes = helpers.create_attributes_field(existing_exclusive_attribute, selected_category)
  var exclusive_options = exclusive_entities.attributes
    .sort((a, b) => a.attribute_name < b.attribute_name ? -1 : 1)
    .filter(ee => ee.attribute_value == null)
  var exclusive_attr_list = {}
  for (let i = 0; i < exclusive_options.length; i++) {
    if (!exclusive_attr_list[exclusive_options[i].attribute_id]) {
      exclusive_attr_list[exclusive_options[i].attribute_id] = {
        attributes: [],
        label: exclusive_options[i].attribute_name,
        id: exclusive_options[i].attribute_id
      }
    }
    exclusive_attr_list[exclusive_options[i].attribute_id].attributes.push(
      exclusive_options[i]
    )
  }

  var unit = await ProductsServices.getUnits();
  var brand = await ProductsServices.getBrands();
  var warranties = await ProductsServices.getWarranties();
  var vat = await ProductsServices.getVat();
  var products_selected_types = {}
  var products_selected_accessory = {}
  var services = await ServiceServices.getServices()
  services.unshift(
    { label: 'Asnje sherbim', id: null, type: 1 },
    { label: 'Asnje sherbim', id: null, type: 2 },
  )

  products_selected_types[pr.type_id] = pr.type_id_name
  products_selected_accessory[pr.accessory_id] = pr.type_id_name

  var selected_services = []
  if (exclusive_entities.selected_services.length > 0) {
    exclusive_entities.selected_services.map(es => {
      selected_services[es.guid] = es.name
    })
  }

  var selected_accessories_options = []
  if (exclusive_entities.selected_accessories.length > 0) {
    exclusive_entities.selected_accessories.map(ea => {
      selected_accessories_options[ea.guid] = ea.name
    })
  }

  // Managers
  var managers = await ProductsServices.getManagers()

  // Energy_efficiency
  var energy_efficiency = await ConfigurationService.getEnergyEfficiency()
  energy_efficiency = energy_efficiency.map(r => {
    r.label = r.name
    r.id = r.guid
    return r
  })

  // Country list
  var country_list = [
    { id: 'CA', label: 'Kanada' },
    { id: 'CN', label: 'Kina' },
    { id: 'DE', label: 'Gjermani' },
    { id: 'FR', label: 'Francë' },
    { id: 'IN', label: 'Indi' },
    { id: 'IT', label: 'Itali' },
    { id: 'JP', label: 'Japoni' },
    { id: 'KR', label: 'Kore' },
    { id: 'MX', label: 'Meksikë' },
    { id: 'TR', label: 'Turqi' },
    { id: 'US', label: 'Shtetet e Bashkuara' },
    { id: 'VN', label: 'Vietnam' }
  ]

  // Product - Pos
  var pos_list = await PointsOfSaleServices.getPointsOfSale()
  pos_list = [...helpers.toTree(pos_list, "id")]

  // Get product_pos
  var product_pos_list = []
  var selected_product_pos = {}
  await Api(true).post('get/single/product/pos', { product_id: pr.guid }).then(r => {
    product_pos_list = r.data
    // Selected user_pos
    if (product_pos_list.length > 0)
      product_pos_list.map(up => {
        selected_product_pos[up.guid] = up.name
      })
  })

  // if(exclusive_entities.selected_accessories.length) {
  //   var hash = {}

  //   hash['guid'] = []


  //   for(var i = 0; i < exclusive_entities.accessories.length; i++){
  //       hash['acc_id'] = exclusive_entities.accessories[i].guid
  //       hash['guid'].push(hash.acc_id)

  //     for(var j = 0; j < hash['guid'].length; j++){
  //       if(exclusive_entities.accessories[i].guid == hash['guid'][j] 
  //       && exclusive_entities.selected_accessories.length == 0 ){
  //         exclusive_entities.accessories = [...exclusive_entities.accessories.filter(e => e.guid != hash['guid'][j])]
  //       }
  //     }
  //   }
  // }


  return {
    name: "Modifiko produktin",
    submit: {
      label: "Modifiko",
      action: "submitProduct",
    },
    fields: [
      [
        {
          value: pr.is_recipe,
          name: "is_recipe",
          label: "I perbere",
          style: "min-width: 2%; margin-left:auto; display:none;",
          type: "checkbox-field",
        },
        {
          value: pr.is_accessory,
          name: "is_accessory",
          label: "Aksesor",
          style: "min-width: 2%;  margin-top:5px;",
          type: "checkbox-field",
        },
        {
          value: pr.stock_limit,
          name: "stock_limit",
          label: "Limiti i stokut",
          type: "number-field",
          style: "min-width: 15%",
        },
      ],
      [
        {
          value: pr.code,
          name: "code",
          label: "Kodi",
          type: "text-field",
          style: "min-width: 24%",
          required: true,
          required_text: "Kjo fushe eshte e detyrueshme",
          unique: {
            model: "product",
            field: "code",
            where: [
              {
                column: "guid",
                condition: "!=",
                value: pr.guid,
              },
            ],
          },
          left_icon: "fa-solid fa-hashtag",
        },
        {
          value: pr.name,
          name: "name",
          label: "Emri",
          type: "text-field",
          style: "min-width: 45%",
          required: true,
          required_text: "Kjo fushe eshte e detyrueshme",
        },
        {
          value: pr.model,
          name: "model",
          label: "Modeli",
          type: "text-field",
          style: "min-width: 29%",
        },
      ],
      [
        {
          name: "type_id",
          label: "Kategoria e produktit",
          type: "tree-select",
          style: "min-width: 100%",
          options: details_types,
          mode: "single",
          selected_options: products_selected_types,
          vif: [{ field: "is_accessory", value_not: 1 }],
        },
      ],
      [
        {
          name: "accessory_id",
          label: "Kategoria e aksesoreve",
          type: "tree-select",
          style: "min-width: 100%",
          options: accessories,
          mode: "single",
          selected_options: products_selected_accessory,
          vif: [{ field: "is_accessory", value_not: 0 }],
        },
      ],
      [
        {
          value: pr.unit,
          name: "unit",
          type: "select-field",
          label: "Njesia",
          style: "min-width: 24%",
          options: unit,
          required: true,
        },
        {
          value: pr.description,
          name: "description",
          label: "Pershkrimi",
          type: "text-field",
          style: "min-width: 45%",
          required: true,
          required_text: "Kjo fushe eshte e detyrueshme",
        },
        {
          value: pr.warranty_id,
          name: "warranty_id",
          label: "Garancia",
          type: "select-field",
          options: warranties,
          style: "min-width: 29%",
          required: true,
          required_text: "Kjo fushe eshte e detyrueshme",
        },
      ],
      [
        {
          value: pr.brand,
          name: "brand",
          type: "select-field",
          label: "Marka",
          style: "min-width: 24%",
          options: brand,
          required: true,
        },
        {
          value: pr.price,
          name: "price",
          type: "number-field",
          label: "Cmimi",
          style: "min-width: 45%",
          required: true,
        },
        {
          value: pr.vat,
          name: "vat",
          type: "select-field",
          label: "TVSH(%)",
          style: "min-width: 29%",
          options: vat,
        },
      ],
      [
        {
          value: pr.default_price,
          name: "default_price",
          type: "number-field",
          label: "Cmimi baze",
          style: "min-width: 100%",
          required: true,
        },
      ],
      [
        {
          value: pr.known_as,
          name: "known_as",
          label: "Emri I njohur universal",
          type: "text-field",
          style: "min-width: 24%",
          required: false,
        },
        {
          value: pr.barcode,
          name: "barcode",
          label: "Barkodi",
          type: "text-field",
          style: "min-width: 45%",
          required: false,
        },
        {
          value: pr.origin,
          name: "origin",
          type: "select-field",
          label: "Origjina e produktit",
          style: "min-width: 29%",
          options: country_list, // LIsta e shitesve
          required: false,
        }
      ],
      [
        {
          value: pr.status,
          name: "status",
          label: "Statusi",
          type: "select-field",
          style: "min-width: 24%",
          options: [
            { id: 1, label: 'Aktiv' },
            { id: 0, label: 'Jo Aktiv' }
          ],
          required: false,
        },
        {
          value: pr.manager,
          name: "manager",
          label: "Menaxher",
          type: "select-field",
          style: "min-width: 45%",
          options: managers, // Product manager - Users list
          required: false,
        },
        // {
        //   value: pr.suplier_category,
        //   name: "suplier_category",
        //   label: "Kategoria e furnitorit",
        //   type: "select-field",
        //   style: "min-width: 24%",
        //   options: [], // Suplier category list
        //   required: false,
        // },
        {
          value: pr.energy_efficiency,
          name: "energy_efficiency",
          label: "Klasi i energjise",
          type: "select-field",
          style: "min-width: 29%",
          options: energy_efficiency, // Category eficency
          required: false,
        }
      ],
      [
        {
          value: pr.packaging,
          name: "packaging",
          label: "Paketimi",
          type: "select-field",
          style: "min-width: 24%",
          options: [
            { id: 0, label: 'Paketim i thjeshte' },
            { id: 1, label: 'Kuti me 10 cope' },
            { id: 2, label: 'Kuti me 20 cope' }
          ], // Category eficency
          required: false,
        },
        {
          value: pr.in_web,
          name: "in_web",
          label: "Shitet ne web",
          style: "min-width: 2%;  margin-top:5px;",
          type: "checkbox-field",
        },
      ],
      [
        {
          value: pr.warranty_airbag,
          name: "warranty_airbag",
          label: "Garanci Airbag",
          style: "min-width: 24%",
          type: "select-field",
          options: [
            { id: 0, label: 'Nuk ka garanci' },
            { id: 3, label: '3 muaj' },
            { id: 6, label: '6 muaj' },
            { id: 12, label: '12 muaj' },
            { id: 24, label: '24 muaj' },
          ], // Category eficency
        },
        {
          value: pr.warranty_airbag_value,
          name: "warranty_airbag_value",
          type: "number-field",
          label: "Cmimi i airbag",
          style: "min-width: 45%",
          // vif: [{ field: "warranty_airbag" }],
          vif: [{ field: "warranty_airbag", value_not: 0 }],
        }
      ],
      [
        {
          name: 'products_pos',
          label: 'Poset ku mund te tregtohet ky produkt',
          type: 'tree-select',
          style: 'width: 100%',
          options: pos_list,
          selected_options: selected_product_pos,
          mode: 'multiple',
          required: false
        },
      ],

      [
        {
          data: [],
          name: "attributes",
          label: "Atribute",
          type: "attributes",
          options: category_attributes,
          style: "min-width: 100%",
          depends_on: "type_id",
          api_call: "category/attributes",
        },
      ],
      [
        {
          data: [],
          name: "services",
          label: "Sherbime",
          type: "attributes",
          options: category_services,
          style: "min-width: 100%",
          depends_on: "type_id",
          api_call: "category/services",
        }
      ],
      [
        {
          name: 'exclusive_accessories',
          label: 'Aksesore ekskluzive',
          type: 'tree-select',
          style: 'width: 100%',
          options: exclusive_entities.accessories.map(e => {
            e.label = e.name
            e.id = e.guid
            return e
          }),
          selected_options: selected_accessories_options,
          mode: 'multiple',
          required: false,
          vif: [{ field: "is_accessory", value_not: 1 }],
        }
      ],
      [
        {
          name: 'exclusive_services',
          label: 'Sherbime ekskluzive',
          type: 'tree-select',
          style: 'width: 100%',
          options: exclusive_entities.services_with_services_types,
          selected_options: selected_services,
          mode: 'multiple',
          required: false
        }
      ],
      [
        {
          name: 'exclusive_attributes',
          label: 'Atribute ekskluzive',
          type: 'attributes',
          is_exclusive: true,
          exclusive_options: exclusive_attr_list,
          style: 'width: 100%',
          options: exclusive_attributes,
          selected_options: [],
          required: false,
          // vif: [{ field: "is_accessory", value_not: 1 }],
        }
      ]
    ],
  };
}
