<template >
  <div v-click-outside="handleClickOutside" 
    :class="{ 'collapsed_menu bg-sky-800': is_collapsed }" 
    class="collapsed_menu p-0 prevent-select" style="">
    <div v-if="!is_collapsed"  
      class="is-fullheight has-text-white left_menu_scrollbar left-menu-wrap  is-flex is-flex-direction-column"
      :class="[keep_open ? 'sticky-menu' : 'opened-menu']"
      style="">
      <p class="is-subtitle has-text-left py-3">

        <span v-if="!keep_open">
          <!-- <i v-if="socket_connected" class="fa fa-circle text-sky-600 fa-xs mr-2"></i> -->
          <a href="" @click.prevent="toggle_menu">
            <i class="fa fa-bars fa-xs mr-2"></i>
          </a>
          {{configs.company_name}}
        </span>
        <a href="" @click.prevent="toggleKeepOpen()" :class="[keep_open ? '' : 'is-pulled-right']">
          <i class="fa fa-thumbtack fa-xs ml-2 mr-2" :class="[keep_open ? 'text-green-600' : 'text-gray-300']"></i> 
        </a>
      </p>
      <ul class="list flex-1" style="overflow-y:auto;" >
        <li v-for="(menu_item, index) in menu_items" :key="index" class="">
          <!-- {{'menu.can_see_' + menu_item.key}} - {{am_allowed('menu.can_see_' + menu_item.key)}} -->
          <div v-if="am_allowed('menu_roles.can_see_' + menu_item.key)"
            class="list-item px-3 is-flex is-flex-direction-column">
            <a @click.prevent="menu_item.collapsed = !menu_item.collapsed"
              :class="{ 'parent-item-collapsed': !menu_item.collapsed }" class="parent_item px-2 py-1">
                <i class="fa" 
                  :class="menu_item.collapsed ? 'fa-angle-down' : 'fa-angle-up text-white'"></i>
              <span class="menu-item-name is-uppercase ml-3 font-bold" style="font-family: 'Varela', sans-serif;">
                {{ menu_item.name }}
              </span>
            </a>
            <ul v-if="menu_item.items.length > 0 && !menu_item.collapsed" class="menu-sub-items-list p-1">
              <li v-for="item, indexa in  menu_item.items" :key="indexa" class="is-clickable">
                <div v-if="!item?.is_row" class="is-flex items-center justify-between pr-4">
                  <a v-if="menu_item.key == 'processes' && item.route != '/process/activities'" :title="item.name + ' Krijo Dokument'" 
                    class="mr-2" @click.prevent="setPage(item.route + 'new')" :href="item.route + 'new'">
                      <i class="fa-solid fa-plus"></i>
                  </a>
                  <a @click.prevent="menu_item.key == 'processes' ? null : setPage(item.route)" 
                    class="is-flex items-start flex-1"
                    :class="{ 'active': currentRoutePath == item.route, 'cursor-default': menu_item.key == 'processes' }" :href="item.route">
                    <i :class="item.icon_prefix" class="mt-2px" v-if="item.icon_prefix"></i>
                    <span class="menu-title flex-1 pl-1">
                      {{ item.name }}
                    </span>
                    <!-- <span v-if="currentRoutePath == item.route && menu_item.name != 'Procese'"> -->
                    <!--   <i class="fa-solid fa-circle fa-xs text-sky-800"></i> -->
                    <!-- </span> -->
                  </a>
                  <a class="ml-2" v-if="menu_item.key == 'processes'" @click.prevent="setPage(item.route + 'list')" :href="item.route + 'list'" 
                    :title="item.name + ' Lista Documentave'"
                  >
                    <span>
                      {{item.item.total_open_documents}} <i class="fa-solid fa-list-ul"></i>
                    </span>

                  </a>
                  <router-link v-if="menu_item.name == 'Procese'" :to="item.route + 'list'" class="is-pulled-right">
                  </router-link>
                </div>
                <div v-else>

                  <span class="row-name is-uppercase">{{ item.name }}</span>
                  <div class="is-flex is-flex-direction-row is-justify-content-space-between">
                    <div v-for="itema in item.items" :key="itema.name"
                      >
                      <a @click.prevent="setPage(itema.route)" 
                        class="is-flex is-flex-direction-column is-align-items-center" 
                        :class="{ 'active': currentRoutePath == itema.route }"
                        :href="itema.route">
                        <i :class="itema.icon_prefix" v-if="itema.icon_prefix"></i>
                        <span style="font-family: 'Varela', sans-serif;">
                          {{ itema.name }}
                        </span>
                      </a>
                    </div>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </li>
      </ul>
      <a @click.prevent="toggle_menu" 
        v-if="!keep_open"
        class="menu_collapse_toggle_opened is-flex is-justify-content-center is-align-items-center" href="">
        <i class="fas " :class="[is_collapsed ? 'fa-chevron-right' : 'fa-chevron-left']"></i>
      </a>
    </div>
    <div @click="toggle_menu"
       class="is-fullheight has-text-white left_menu_scrollbar left-menu-wrap is-clickable is-flex "
       :class="[keep_open ? 'height-auto' : '']"
      style="position:relative; z-index: 9;">
      <span v-if="!keep_open"
        class="menu_collapse_toggle bg-sky-800 is-flex is-justify-content-center is-align-items-center " 
        style="margin-top:auto; position:sticky;">
        <i class="fas " :class="[is_collapsed ? 'fa-chevron-right' : 'fa-chevron-left']"></i>
      </span>
      <span v-if="!keep_open" class="menu_vertical_text">
        {{ labelOf }}
      </span>
    </div>
  </div>
</template>

<script>
import { storeToRefs } from 'pinia'
import { useMenuStore } from '@/stores/menu'
import { useConfigurationsStore } from '@/stores/configurations'
import { useCategoryStore } from '@/stores/category'
import ProcessServices from "@/services/Processes";
import { useProcessStore } from '@/stores/process'
import { useDiaryStore } from '@/stores/diary'

// import processService from '@/services/Processes'
import permissionMixin from '@/mixins/permissions-mixin.js'
import krudh from '@/components/krudh/'

import socket from '@/socket.js';

export default {
  components: [krudh],
  mixins: [permissionMixin],
  data() {
    return {
      divider: ' • ',
      menu: {
        katalog: true,
        kliente: true,
        shitje: true
      },
      keep_open: localStorage.getItem('keep_open') == 'true' ? true : false,
      submenu: '',
      menu_store: storeToRefs(useMenuStore()),
      category_store: storeToRefs(useCategoryStore()),
      process_store: storeToRefs(useProcessStore()),
      diary_store: storeToRefs(useDiaryStore()),
      is_collapsed: localStorage.getItem('keep_open') == 'true'  ? false : true,
      processes_list: [],
      menu_items: [
        { name: 'Katalog', key: 'catalog', collapsed: true, items: [{ is_row: true, items: [], name: 'Kategori' },] },
        { name: 'CRM', key: 'crm', collapsed: true, items: [{ is_row: true, items: [], name: 'Kategori' }] },
        { name: 'Furnitore', key: 'supplier', collapsed: true, items: [{ is_row: true, items: [], name: 'Kategori' }] },
        { name: 'Stock', key: 'transactional', collapsed: true, items: [{ is_row: true, items: [], name: 'Magazina' }] },
        { name: 'Ditare', key: 'diary', collapsed: true, items: [{ is_row: true, items: [], name: '' }] },
        { name: 'Procese', key: 'processes', collapsed: true, items: [] },
        { name: 'Fatura', key: 'invoices', collapsed: true, items: [{ is_row: true, items: [], name: '' }] },
        { name: 'Flete pune', key: 'jobs', collapsed: true, items: [{ is_row: true, items: [], name: '' }] },
        { name: 'Rezervime', key: 'reservations', collapsed: true, items: [{ is_row: true, items: [], name: '' }] },
        { name: 'Konfigurime', key: 'configurations', collapsed: true, items: [{ is_row: true, items: [], name: 'Perdorues' }] },
      ],
      configs: storeToRefs(useConfigurationsStore())
    };
  },
  watch: {
    'labelOf': function (val) {
      this.$document.title = val
    }
  },
  methods: {
    toggleKeepOpen(){
      this.keep_open = !this.keep_open
      if(this.keep_open){
        this.is_collapsed = false
        localStorage.setItem('keep_open', true)
      }
      else{
        localStorage.removeItem('keep_open')
      }
    },
    handleClickOutside(){
      if(this.keep_open){
        return
      }
      if(!this.is_collapsed){
        this.is_collapsed = true
      }

    },
    setPage(page) {
      this.submenu = page;
      this.menu_store.submenu = page;
      this.$router.push({path: '/loading...'})
      // console.log('setPage', page)

      if(page.includes('categories') || page.includes('attributes')){
        this.category_store.list = [] 
        this.category_store.flat_list = []
        this.category_store.attributes = [] 
        this.categories_attributes = []
        this.categories_accessories = []
        this.categories_service_types = []
        this.attribute_types = []
      }
      else if(page.includes("process/")){
        // window.location.href = page
        this.process_store.process = {} 
        this.process_store.current_step = {} 
        this.process_store.process_steps = []
        this.process_store.product_list = []
        this.process_store.attributes = {origin: [], destinantion: []}
      }
      else if(page.includes('diary')){
        this.diary_store.diary = JSON.parse(JSON.stringify({}))
        this.diary_store.countdown = ""
        this.diary_store.diaries = []
        this.diary_store.products = []
        this.diary_store.customers = []
        this.diary_store.comments = [] 

      }
      setTimeout(() => {
        // window.location.href = page
        // console.log('setPage open', page)
        this.$router.replace({path: page})
        this.toggle_menu()
      }, 200)
      
    },
    toggle_menu() {
      if(this.keep_open){
        return
      }
      this.is_collapsed = !this.is_collapsed
    },

    check_permissions() {
      if (localStorage.user != undefined) {
        this.menu_items.map(i => {
          if (i.key == "catalog" && this.am_allowed('menu_roles.can_see_catalog')) {
            i.items.push({ icon_prefix: 'fa fa-cubes-stacked', name: 'Produkte dhe Aksesore', route: '/products', })
            i.items[0].items.push({ icon_prefix: 'fa fa-sitemap', name: 'Produkti', route: '/categories/products', })
            i.items[0].items.push({ icon_prefix: 'fa fa-sitemap', name: 'Aksesori', route: '/categories/accessories', })
            i.items[0].items.push({ icon_prefix: 'fa fa-diagram-project', name: 'Atribute', route: '/attributes/products', })
            i.items.push({ icon_prefix: 'fa fa-shapes', name: 'Materiale', route: '/materials', })
            i.items.push({ icon_prefix: 'fa fa-cog', name: 'Sherbime', route: '/services', })
          }
          else if (i.key == "crm" && this.am_allowed('menu_roles.can_see_crm')) {
            i.items[0].items.push({ icon_prefix: 'fa fa-sitemap', name: 'Kategori', route: '/categories/customers', })
            i.items.push({ icon_prefix: 'fa fa-users', name: 'Kliente', route: '/customers', })
            i.items[0].items.push({ icon_prefix: 'fa fa-diagram-project', name: 'Atribute', route: '/attributes/customers', })
            i.items.push({ icon_prefix: 'fa fa-user-friends', name: 'Agjente shitje', route: '/sales_agents', })
            i.items.push({ icon_prefix: 'fa fa-solid fa-list-ul', name: 'Kerkese produktesh', route: '/free/line/products', })
          }
          else if (i.key == "supplier") {
            i.items[0].items.push({ icon_prefix: 'fa fa-sitemap', name: 'Kategori', route: '/categories/suppliers', })
            i.items.push({ icon_prefix: 'fa fa-truck-field', name: 'Furnitore', route: '/suppliers', })
            i.items[0].items.push({ icon_prefix: 'fa fa-diagram-project', name: 'Atribute', route: '/attributes/suppliers', })

          }
          else if (i.key == "transactional") {
            i.items.push({ icon_prefix: 'fa fa-cubes', name: 'Gjendja', route: '/stock', })
            i.items[0].items.push({ icon_prefix: 'fa fa-warehouse', name: 'Magazina', route: '/warehouses', })
            i.items[0].items.push({ icon_prefix: 'fa fa-sitemap', name: 'Kategori', route: '/categories/warehouses', })
            i.items.push({ icon_prefix: 'fa fa-store', name: 'Pika shitje', route: '/pos/list', })
            i.items.push({ icon_prefix: 'fa fa-boxes-stacked', name: 'Rezervime', route: '/manage_reservations', })
          }
          else if(i.key == 'invoices') {
            i.items[0].items.push({ icon_prefix: 'fa fa-file-invoice', name: 'Shitje', route: '/invoices/sale', })
            i.items[0].items.push({ icon_prefix: 'fa fa-file-invoice-dollar', name: 'Blerje', route: '/invoices/purchase', })
            i.items.push({ icon_prefix: 'fa fa-file-lines', name: 'Mandate', route: '/creditnotes', })
            // <i class="fa-regular fa-file-lines"></i>
          }
          else if (i.key == "configurations") {
            i.items[0].items.push({ icon_prefix: 'fa fa-user', name: 'Perdorues', route: '/user-permissions/users', })
            i.items[0].items.push({ icon_prefix: 'fa fa-user-gear', name: 'Role', route: '/user-permissions/roles', })
            i.items.push({ icon_prefix: 'fa fa-cog', name: 'Procese', route: '/processes', })
            i.items.push({ icon_prefix: 'fa fa-file-lines', name: 'Printime', route: '/process/print', })
						i.items.push({ icon_prefix: 'fa fa-upload', name: 'Importe', route: '/importer', })
            i.items.push({ icon_prefix: 'fa fa-building', name: 'Sistemi', route: '/company', })
            // i.items.push({ icon_prefix: 'fa-solid fa-clipboard-user', name: 'Burime njerezore', route: '/hr_home', })
            
          }
          else if (i.key == "diary") {
            if(this.am_allowed('diary_roles.can_create_diary')){
              i.items[0].items.push({ icon_prefix: 'fa fa-plus', name: 'Krijo', route: '/diary/create', })
            }
            if(this.am_allowed('diary_roles.can_see_diary_list')){
              i.items[0].items.push({ icon_prefix: 'fa fa-calendar-alt', name: 'Hapur', route: '/diary/list', })
            }
            if(this.am_allowed('diary_roles.can_see_diary_history')){
              i.items[0].items.push({ icon_prefix: 'fa fa-calendar-check', name: 'Historik', route: '/diary/list/closed', })
            }
          }
          else if (i.key == "processes") {
            // remove if there are no processes allowed to be shown in menu
            if(this.processes_list.length == 0){
              this.menu_items.splice(this.menu_items.findIndex(x => x.key == 'processes'), 1)
            }
            else 
              this.processes_list.map(p => {
                if(p.show_in_menu){
                  i.items.unshift({
                    name: p.name,
                    item: p,
                    route: '/process/' + p.guid + '/',
                    icon_prefix: p.icon
                  })
                }
              })
          }
          else if (i.key == "jobs") {
            i.items[0].items.push({ icon_prefix: 'fa fa-sitemap', name: 'Menaxho', route: '/process/activities', })
            i.items[0].items.push({ icon_prefix: 'fa fa-clipboard-list', name: 'Lista', route: '/job/list', })
          }
          else if (i.key == "reservations") {
            i.items[0].items.push({ icon_prefix: 'fa fa-clipboard-list', name: 'Lista', route: '/reservation_temp/list', })
            // i.items[0].items.push({ icon_prefix: 'fa fa-sitemap', name: 'Magazina', route: '/process/activities', })
          }
        })
      }
    }
  },
  async created() {
    this.processes_list = await ProcessServices.getProcesses()
    useProcessStore().processes_list = this.processes_list
    this.check_permissions()
  },
  computed: {
    socket_connected() {
      return socket?.connected
    },
    currentRoutePath() {
      return this.$route.path;
    },
    currentRouteName() {
      return this.$route.name;
    },
    labelOf() {
      var route = this.currentRoutePath;
      var label = [];
      this.menu_items.forEach(item => {
        item.items.forEach(subitem => {
          if (subitem.route == route) {
            if(!label.includes(item.name.toUpperCase()))
              label.push(item.name.toUpperCase())
            if(!label.includes(subitem.name.toUpperCase()))
              label.push(subitem.name.toUpperCase())
          }
          else if(subitem.route + 'new' == route || subitem.route + 'list' == route){
            if(subitem.route + 'new' == route){
              if(!label.includes(item.name.toUpperCase()))
                label.push(item.name.toUpperCase())
              if(!label.includes(subitem.name.toUpperCase()))
                label.push(subitem.name.toUpperCase())
              if(!label.includes('KRIJO'))
                label.push('KRIJO')
            }
            else if(subitem.route + 'list' == route){
              if(!label.includes(item.name.toUpperCase()))
                label.push(item.name.toUpperCase())
              if(!label.includes(subitem.name.toUpperCase()))
                label.push(subitem.name.toUpperCase())
              if(!label.includes('LISTA'))
                label.push('LISTA')
            }
          }
          else if(subitem.items?.length > 0){
            subitem.items.forEach(subsubitem => {
              if (subsubitem.route == route) {
                if(!label.includes(item.name.toUpperCase()))
                  label.push(item.name.toUpperCase())
                if(!label.includes(subitem.name.toUpperCase()))
                  label.push(subitem.name.toUpperCase())
                if(!label.includes(subsubitem.name.toUpperCase()))
                  label.push(subsubitem.name.toUpperCase())
              }
            })
          }
        });
      });
      if(label.length == 0 && route.includes('process/')){
          label.push(this.process_store?.process?.name)
          // label.push(this.process_store?.current_step?.name )
          label.push(this.process_store?.process?.document_data?.process_number)
      } 
      return label.filter(l => l != '').join(this.divider)
    },

  }

}
</script>

<style scoped>
.list:not(.fa) .menu-title  {
  font-family: 'Manrope', sans-serif;
}
.mt-2px {
  margin-top: 2px; 
}
.list-item {
  padding: 4px !important;
  border-bottom: 1px solid #4c4c4c;

  font-size: 11px !important;
  text-transform: uppercase;

}

.is-subtitle {
  /* company name in the menu */
  text-transform: uppercase;
  border-bottom: 1px solid #628fd7;
  padding: 5px 0px 5px 8px;
  margin-bottom: 15px;
}

.menu-sub-items-list i.fa {
  color: #628fd7;
}

.list-item .menu-item-name {
  float: right;
}

.list-item>ul {
  background: #1a22416e;
}

.list-item>ul>li {
  /* margin-left: 10px !important; */
  border: dotted;
  border-width: 0px 0px 1px 0px;
  padding: 5px 0px 5px 8px;
  border-color: #446f70;
}

.list-item>ul>li:last-child {
  border: none;

}
.prevent-select {
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}
.list-item>ul>li:hover {
  color: #a4b4ff !important;
}

.list-item a:hover {
  color: yellow !important;
}

.active {
  font-weight: 600;
  color: #93c5fd !important;
  position: relative;
}

.active::after {
  content: '';
  position: absolute;
  right: -8px;
  top: 50%;
  transform: translateY(-50%);
  width: 3px;
  height: 90%;
  background: #3b82f6;
  border-radius: 2px;
}

.left-menu-wrap {
  background: #01070ad9;
  border-right: 1px solid grey;
  border-bottom: 1px solid grey;
  height: 100%;
  position: relative;
}

/* Scrollbar styles */
/* ===== Scrollbar CSS ===== */
/* Firefox */
.left_menu_scrollbar {
  scrollbar-width: auto;
  scrollbar-color: #dedede #ffffff;
}

/* Chrome, Edge, and Safari */
.left_menu_scrollbar::-webkit-scrollbar {
  width: 10px;
}

.left_menu_scrollbar::-webkit-scrollbar-track {
  background: #ffffff;
}

.left_menu_scrollbar::-webkit-scrollbar-thumb {
  background-color: #dedede;
  border-radius: 10px;
  border: 3px solid #ffffff;
}

/* End Scrollbar styles */

/* Collapsed Meny styles */
.collapsed_menu {
  max-width: fit-content;
  position: relative;
  min-width: 40px;
  width: fit-content;
}

.menu_collapse_toggle {
  position: absolute;
  bottom: 0px !important;
  left: 0px;
  color: #fff;
  text-align: center;
  width: inherit;
  min-width: 35px;
  z-index: 999;
  width: 100%;
  min-height: 40px;
}

.menu_collapse_toggle_opened {
  color: #fff;
  text-align: center;
  width: inherit;
  min-width: 35px;
  z-index: 999;
  width: 100%;
  min-height: 35px;
}

.menu_vertical_text {
  width: fit-content;
  white-space: pre;
  text-transform: capitalize;
  letter-spacing: 4px;

  position: absolute;
  top: calc(100vh - 150px);
  left: 5px;
  transform-origin: 0 0;
  transform: rotate(270deg);
}

.opened-menu {
  position: fixed;
  left: 0px;
  top: 0px;
  z-index: 99999;
  height: 100vh;
  width: 15%;
  padding-top: ;
}

.menu-sub-items-list {}
.parent_item {
  transition: all 0.2s ease;
}
.parent-item-collapsed {
  background: rgba(255, 136, 0, 0.15);
  border-left: 2px solid #f59e0b;
}

.row-name {
  font-weight: bold;
  color: #fff;
  position: relative;
  top: -6px;
  font-size: 10px;
}

.height-auto {
  height: auto;
}

/* MEDIA QUERY START ****************************************************/


@media all and (min-width: 1024px) and (max-width: 1280px) {}

@media all and (min-width: 768px) and (max-width: 1024px) {
  .opened-menu {
    width: 25%;
  }
}

@media all and (min-width: 480px) and (max-width: 768px) {
  .opened-menu {
    width: 45%;
  }
}

@media all and (max-width: 480px) {
  .opened-menu {
    width: 100%;
  }
}
</style>

