<template>
  <div class="max-w-4xl mx-auto p-6 space-y-6">
    <!-- Header -->
    <div class="text-center mb-8">
      <h1 class="text-2xl font-bold text-gray-900">Importuesi i të Dhënave</h1>
      <p class="mt-2 text-gray-600">Ndiqni hapat e mëposhtëm për të importuar të dhënat tuaja</p>
    </div>

    <!-- Step 1: Table Selection -->
    <div class="bg-white rounded-xl shadow-sm border border-gray-200 overflow-hidden">
      <div class="border-b border-gray-200 bg-gray-50 px-6 py-4">
        <div class="flex items-center">
          <span class="flex items-center justify-center w-8 h-8 rounded-full bg-blue-100 text-blue-600 font-semibold mr-3">1</span>
          <h2 class="text-lg font-semibold text-gray-900">Zgjidhni Tabelën</h2>
        </div>
      </div>
      
      <div class="p-6">
        <div class="flex gap-4 items-center">
          <select 
            v-model="selectedTable" 
            @change="loadTableColumns"
            class="flex-1 rounded-lg border-gray-300 shadow-sm focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
          >
            <option value="">Zgjidhni një tabelë për të importuar të dhënat</option>
            <option v-for="table in tables" :key="table" :value="table">
              {{ table }}
            </option>
          </select>

          <button 
            @click="exportTable"
            :disabled="!selectedTable"
            class="px-4 py-2 bg-gray-100 text-gray-700 rounded-lg border border-gray-300 hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-gray-500 disabled:opacity-50 disabled:cursor-not-allowed"
          >
            Eksporto Tabelën
          </button>
        </div>
      </div>
    </div>

    <!-- Step 2: File Upload -->
    <div v-if="selectedTable" class="bg-white rounded-xl shadow-sm border border-gray-200 overflow-hidden">
      <div class="border-b border-gray-200 bg-gray-50 px-6 py-4">
        <div class="flex items-center">
          <span class="flex items-center justify-center w-8 h-8 rounded-full bg-blue-100 text-blue-600 font-semibold mr-3">2</span>
          <h2 class="text-lg font-semibold text-gray-900">Ngarko Skedarin</h2>
        </div>
      </div>
      
      <div class="p-6">
        <div class="flex items-center justify-center w-full">
          <label class="flex flex-col items-center justify-center w-full h-32 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 hover:bg-gray-100">
            <div class="flex flex-col items-center justify-center pt-5 pb-6">
              <svg class="w-8 h-8 mb-4 text-gray-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12" />
              </svg>
              <p class="mb-2 text-sm text-gray-500"><span class="font-semibold">Kliko për të ngarkuar</span> ose tërhiq dhe lësho</p>
              <p class="text-xs text-gray-500">Skedarë CSV</p>
            </div>
            <input 
              type="file" 
              ref="fileInput"
              @change="handleFileChange"
              accept=".csv,.xlsx,.xls"
              class="hidden"
            />
          </label>
        </div>
      </div>
    </div>

    <!-- Step 3: Column Mapping -->
    <div v-if="fileColumns.length && tableColumns.length" class="bg-white rounded-xl shadow-sm border border-gray-200 overflow-hidden">
      <div class="border-b border-gray-200 bg-gray-50 px-6 py-4">
        <div class="flex items-center">
          <span class="flex items-center justify-center w-8 h-8 rounded-full bg-blue-100 text-blue-600 font-semibold mr-3">3</span>
          <h2 class="text-lg font-semibold text-gray-900">Përputhja e Kolonave</h2>
        </div>
      </div>
      
      <div class="p-6 space-y-6">
        <!-- Import Mode Selection -->
        <div class="grid grid-cols-1 md:grid-cols-2 gap-6">
          <div>
            <label class="block text-sm font-medium text-gray-700 mb-2">Mënyra e Importimit</label>
            <select 
              v-model="importMode"
              class="w-full rounded-lg border-gray-300 shadow-sm focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
            >
              <option value="insert">Shto Rekorde të Reja</option>
              <option value="update">Përditëso Rekordet Ekzistuese</option>
              <option value="upsert">Shto ose Përditëso</option>
            </select>
          </div>

          <div v-if="importMode !== 'insert'">
            <label class="block text-sm font-medium text-gray-700 mb-2">Kolona Kyçe për Përditësime</label>
            <select 
              v-model="keyColumn"
              class="w-full rounded-lg border-gray-300 shadow-sm focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
            >
              <option v-for="column in tableColumns" :key="column.name" :value="column.name">
                {{ column.name }}
              </option>
            </select>
          </div>
        </div>

        <!-- Column Mapping -->
        <div class="space-y-4">
          <div v-for="fileCol in fileColumns" :key="fileCol" 
            class="flex items-center p-4 rounded-lg bg-gray-50 hover:bg-gray-100 transition-colors duration-150"
          >
            <div class="w-1/3">
              <span class="text-sm font-medium text-gray-700">{{ fileCol }}</span>
            </div>
            <div class="w-2/3">
              <select 
                v-model="columnMapping[fileCol]"
                class="w-full rounded-lg border-gray-300 shadow-sm focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
              >
                <option value="">Mos përfshij këtë kolonë</option>
                <option v-for="column in tableColumns" :key="column.name" :value="column.name">
                  {{ column.name }} ({{ column.type }})
                </option>
              </select>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Step 4: Preview -->
    <div v-if="preview.length" class="bg-white rounded-xl shadow-sm border border-gray-200 overflow-hidden">
      <div class="border-b border-gray-200 bg-gray-50 px-6 py-4">
        <div class="flex items-center">
          <span class="flex items-center justify-center w-8 h-8 rounded-full bg-blue-100 text-blue-600 font-semibold mr-3">4</span>
          <h2 class="text-lg font-semibold text-gray-900">Parapamja e Importimit</h2>
        </div>
      </div>
      
      <div class="p-6">
        <div class="overflow-x-auto">
          <table class="min-w-full divide-y divide-gray-200">
            <thead class="bg-gray-50">
              <tr>
                <th v-for="header in preview[0]" :key="header" 
                  class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  {{ header }}
                </th>
              </tr>
            </thead>
            <tbody class="bg-white divide-y divide-gray-200">
              <tr v-for="(row, index) in preview.slice(1, 6)" :key="index">
                <td v-for="(cell, cellIndex) in row" :key="cellIndex" 
                  class="px-6 py-4 whitespace-nowrap text-sm text-gray-500"
                >
                  {{ cell }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>

    <!-- Import Button and Status -->
    <div class="bg-white rounded-xl shadow-sm border border-gray-200 overflow-hidden">
      <div class="p-6">
        <button 
          @click="importData"
          :disabled="!canImport || importing"
          class="w-full py-3 px-4 border border-transparent rounded-lg shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 disabled:opacity-50 disabled:cursor-not-allowed transition-colors duration-150"
        >
          <span v-if="importing" class="flex items-center justify-center">
            <svg class="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
              <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
              <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
            </svg>
            Duke importuar...
          </span>
          <span v-else>Fillo Importimin</span>
        </button>

        <!-- Status Messages -->
        <div v-if="importStatus" :class="[
          'mt-4 p-4 rounded-lg',
          importStatus.type === 'success' ? 'bg-green-50 text-green-700 border border-green-200' : 'bg-red-50 text-red-700 border border-red-200'
        ]">
          <div class="flex">
            <div class="flex-shrink-0">
              <svg v-if="importStatus.type === 'success'" class="h-5 w-5 text-green-400" viewBox="0 0 20 20" fill="currentColor">
                <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clip-rule="evenodd" />
              </svg>
              <svg v-else class="h-5 w-5 text-red-400" viewBox="0 0 20 20" fill="currentColor">
                <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z" clip-rule="evenodd" />
              </svg>
            </div>
            <div class="ml-3">
              <p class="text-sm font-medium">{{ importStatus.message }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, computed } from 'vue'
import Api from "@/services/Api";

const fileInput = ref(null)
const selectedFile = ref(null)
const selectedTable = ref('')
const tables = ref([])
const tableColumns = ref([])
const fileColumns = ref([])
const columnMapping = ref({})
const preview = ref([])
const importing = ref(false)
const importStatus = ref(null)
const importMode = ref('insert')
const keyColumn = ref('')

const loadTables = async () => {
  try {
    const response = await Api(true).get('tables/get/list')
    tables.value = response.data
  } catch (error) {
    console.error('Dështoi ngarkimi i tabelave:', error)
  }
}

const loadTableColumns = async () => {
  if (!selectedTable.value) return

  try {
    const response = await Api(true).post('tables/get/columns', {
      table: selectedTable.value
    })
    tableColumns.value = response.data
    columnMapping.value = {}
  } catch (error) {
    console.error('Dështoi ngarkimi i kolonave:', error)
  }
}

const handleFileChange = async (event) => {
  const file = event.target.files[0]
  if (!file) return

  selectedFile.value = file
  importStatus.value = null

  const formData = new FormData()
  formData.append('file', file)

  try {
    const response = await Api(true).post('import/preview/file', formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
    preview.value = response.data.preview
    fileColumns.value = response.data.columns
    columnMapping.value = {}
  } catch (error) {
    importStatus.value = {
      type: 'error',
      message: 'Dështoi gjenerimi i parapamjes'
    }
  }
}

const canImport = computed(() => {
  return selectedTable.value && 
    selectedFile.value && 
    Object.keys(columnMapping.value).length > 0 &&
    (importMode.value === 'insert' || keyColumn.value)
})

const importData = async () => {
  if (!canImport.value) return;

  importing.value = true;
  importStatus.value = null;

  try {
    const formData = new FormData();
    formData.append('file', selectedFile.value);
    formData.append('table', selectedTable.value);
    formData.append('mapping', JSON.stringify(columnMapping.value));
    formData.append('mode', importMode.value);

    if (importMode.value !== 'insert') {
      formData.append('keyColumn', keyColumn.value);
    }

    const response = await Api(true).post('import/process/data', formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });

    importStatus.value = {
      type: 'success',
      message: `U ${importMode.value === 'insert' ? 'shtuan' : importMode.value === 'update' ? 'përditësuan' : 'shtuan/përditësuan'} me sukses ${response.data.rows} rreshta`
    };
  } catch (error) {
    importStatus.value = {
      type: 'error',
      message: error.response?.data?.message || 'Importimi dështoi. Ju lutemi provoni përsëri.'
    };
    console.error('Gabim gjatë importimit:', error.response?.data);
  } finally {
    importing.value = false;
  }
};

const exportTable = async () => {
  if (!selectedTable.value) return;

  try {
    const response = await Api(true).get(`tables/export/${selectedTable.value}`, {
      responseType: 'blob'
    });

    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `${selectedTable.value}_eksport.csv`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    window.URL.revokeObjectURL(url);

  } catch (error) {
    console.error('Eksportimi dështoi:', error);
  }
};

// Ngarko tabelat kur komponenti montohet
loadTables()
</script>
