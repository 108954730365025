import { createRouter, createWebHistory } from 'vue-router'
import auth from '@/middleware/auth'
import { useProcessStore } from './stores/process'
import { usePrintStore } from './stores/print'
import { useJobStore } from './stores/job'
import { useCreditnoteStore } from './stores/creditnote'
import { useReservationStore } from './stores/reservation'

const routes = [
	{ path: '/', component: () => import('./pages/LandingPage.vue') },
	{ path: '/help', component: () => import('./pages/Help.vue') },
	{
		name: 'login', path: '/login',
		component: () => import('./pages/Login.vue')
	},
	{
		name: 'register', path: '/register',
		component: () => import('./pages/Register.vue')
	},
	{
		name: 'home', path: '/home',
		component: () => import('./pages/Home.vue'),
		beforeEnter: () => auth(),
	},
	{
		name: 'Kliente', path: '/customers',
		component: () => import('./pages/customers/index.vue'),
		beforeEnter: () => auth(),
	},
	{
		name: 'Furnitore', path: '/suppliers',
		component: () => import('./pages/suppliers/index.vue'),
		beforeEnter: () => auth(),
	},
	{
		name: 'Produkte', path: '/products',
		component: () => import('./pages/products/index.vue'),
		beforeEnter: () => auth(),

	},
	{
		name: 'loading', path: '/loading...',
		component: () => import('./pages/LoadingPage.vue'),
		beforeEnter: () => auth(),
	},
	{
		name: 'Kategori', path: '/categories/:related_entity',
		component: () => import('./pages/categories/index.vue'),
		beforeEnter: () => auth(),
	},
	{
		name: 'Aksesoret', path: '/accessories/:related_entity',
		component: () => import('./pages/accessories/index.vue'),
		beforeEnter: () => auth(),
	},
	{
		name: 'Atribute', path: '/attributes/:related_entity',
		component: () => import('./pages/attributes/index.vue'),
		beforeEnter: () => auth(),
	},
	{
		name: 'Magazina', path: '/warehouses',
		component: () => import('./pages/warehouses/index.vue'),
		beforeEnter: () => auth(),
	},

	{
		name: 'Evente', path: '/events',
		component: () => import('./pages/events/index.vue'),
		beforeEnter: () => auth(),
	},

	{
		name: 'Pika Shitje', path: '/pos/list',
		component: () => import('./pages/point_of_sales/index.vue'),
		beforeEnter: () => auth(),
	},
	{
		name: 'Draft', path: '/sale/:document/:guid',
		component: () => import('./pages/sales/index.vue'),
		beforeEnter: () => auth(),
	},
	{
		name: 'Document Details', path: '/document_details/:document/:guid',
		component: () => import('./pages/document_details/index.vue'),
		beforeEnter: () => auth(),
	},
	{
		name: 'Document Details order is head', path: '/document_details/:document/:guid/:is_order_head',
		component: () => import('./pages/document_details/index.vue'),
		beforeEnter: () => auth(),
	},
	// { 
	// 	name: 'Draft', path: '/sale/:document/:guid',
	// 	component: () => import('./pages/sales/index.vue'),
	// 	beforeEnter: () => auth(),
	// },
	{
		name: 'Materiale', path: '/materials',
		component: () => import('./pages/materials/index.vue'),
		beforeEnter: () => auth(),

	},
	{
		name: 'Sherbime', path: '/services',
		component: () => import('./pages/services/index.vue'),
		beforeEnter: () => auth(),
	},
	{
		name: 'Perdorues', path: '/user-permissions/:module',
		component: () => import('./pages/user_permissions/index.vue'),
		beforeEnter: () => auth(),
	},
	// { 
	// 	name: 'Vendodhja', path: '/locations/:event_guid',
	// 	component: () => import('./pages/locations/index.vue'),
	// 	beforeEnter: () => auth(),
	// },
	// { 
	// 	name: 'Aktivitete', path: '/activities/:location_guid',
	// 	component: () => import('./pages/activities/index.vue'),
	// 	beforeEnter: () => auth(),
	// },
	{
		name: 'Departamente', path: '/departments/:vendor_id',
		component: () => import('./pages/departments/index.vue'),
		beforeEnter: () => auth(),
	},
	{
		name: 'Gjera Gjenerale', path: '/general',
		component: () => import('./pages/general-stuff/index.vue'),
		beforeEnter: () => auth(),
	},
	{
		name: 'Profili', path: '/profile',
		component: () => import('./pages/user-profile/index.vue'),
		beforeEnter: () => auth(),
	},
	{
		name: 'Gjendja', path: '/stock',
		component: () => import('./pages/stock/index.vue'),
		beforeEnter: () => auth(),
	},
	{
		name: 'Agjent Shitjesh', path: '/sales_agents',
		component: () => import('./pages/sales_agents/index.vue'),
		beforeEnter: () => auth(),
	},
	{
		name: 'Konfigurime', path: '/company',
		component: () => import('./pages/company/index.vue'),
		beforeEnter: () => auth(),
	},
	{
		name: 'Processes', path: '/processes',
		component: () => import('./pages/processes/index.vue'),
		beforeEnter: () => auth(),
	},
	// {
	// 	name: 'Proces 1', path: '/process/:process_id',
	// 	component: () => import('./pages/process/index.vue'),
	// 	beforeEnter:[
	// 		() => auth(),
	// 		(to) => useProcessStore().get_process_steps(to.params.process_id, 0)
	// 	] 
	// 	
	// },
	{
		name: 'Proces List', path: '/process/:process_id/list',
		component: () => import('./pages/process/index.vue'),
		beforeEnter: [
			() => auth(),
			(to) => useProcessStore().get_process_documents(to.params.process_id)
		]
	},
	{
		name: 'New Process', path: '/process/:process_id/new',
		component: () => import('./pages/process_new/index.vue'),
		beforeEnter: [
			() => auth(),
			(to) => useProcessStore().get_process_steps(to.params.process_id, 0)
		]
	},
	{
		name: 'Proces document', path: '/process/document/:process_id/:document_id',
		component: () => import('./pages/process_new/index.vue'),
		beforeEnter: [
			() => auth(),
			(to) => useProcessStore().get_process_steps(to.params.process_id, to.params.document_id)
		]
	},
	{
		name: 'Aktivitete Procesi', path: '/process/activities',
		component: () => import('./pages/process/components/process_activities/index.vue'),
		beforeEnter: [
			() => auth(),
			() => useJobStore().getServiceTypes()
		]
	},
	{
		name: 'Flete pune list', path: '/job/list',
		component: () => import('./pages/process/components/jobs_list/index.vue'),
		beforeEnter: [
			() => auth(),
			() => useJobStore().get_job_list_data()
		]
	},
	{
		name: 'Print Process', path: '/process/print',
		component: () => import('./pages/processes/components/print-template.vue'),
		beforeEnter: [
			() => auth(),
			() => usePrintStore().get_print_documents()
		]
	},
	{
		name: 'Free line products', path: '/free/line/products',
		component: () => import('./pages/diary/free-line-products.vue'),
		beforeEnter: [
			() => auth(),
			// () => useDiaryStore().get_free_products()
		]
	},
	{
		name: 'Mandate arketimi', path: '/creditnotes',
		component: () => import('./pages/creditnotes/index.vue'),
		beforeEnter: [
			() => auth(),
			() => useCreditnoteStore().get_creditnotes()
		]
	},
	{
		name: 'Rezervime list', path: '/reservation_temp/list',
		component: () => import('./pages/reservation_temp/index.vue'),
		beforeEnter: [
			() => auth(),
		]
	},

	{
		name: "Importer", path: '/importer',
		component: () => import('./pages/importer/index.vue'),
		beforeEnter: () => auth()
	},

	// BLOCK: DIARY
	{ name: 'Ditar Shiko', path: '/diary/get/:id', component: () => import('./pages/diary/index.vue') },
	{ name: 'Ditar', path: '/diary/create', component: () => import('./pages/diary/index.vue') },

	{
		name: 'Ditar create dearch', path: '/diary/create/search/:product_id/:pos_id/:warehouse_id',
		component: () => import('./pages/diary/index.vue')
	},

	//	{ name: 'Ditar te Hapur', path: '/diary/list', component: () => import('./pages/diary/open.vue') },
	{
		name: 'Ditar',
		path: '/diary/list/:status?',
		component: () => import('./pages/diary/open.vue'),
		props: route => ({
			isClosed: route.params.status === 'closed'
		})
	},

	//	{ name: 'Ditar te Perfunduar', path: '/diary/list/closed', component: () => import('./pages/diary/history.vue') },

	{ name: 'Konfirmo zbritje', path: '/diary/discount/:id', component: () => import('./pages/diary/discount_list.vue') },


	{ name: 'Konfirmo zbritje proces', path: '/diary/discount/:id/:is_process', component: () => import('./pages/diary/discount_list.vue') },

	{
		name: 'HR Home', path: '/hr_home',
		component: () => import('./pages/hr_home/index.vue'),
		beforeEnter: [
			() => auth(),
			() => usePrintStore().get_print_documents()
		]
	},

	// Search page
	{
		name: 'Kerko produkte', path: '/search_page',
		component: () => import('./pages/main_search/index.vue'),
		beforeEnter: () => auth(),
	},
	{
		name: 'Rezervime', path: '/manage_reservations',
		component: () => import('./pages/reservations/index.vue'),
		beforeEnter: [
			() => auth(),
			() => useReservationStore().get_reservations()
		]
	},

	{
		name: 'Fatura', path: '/invoice/:invoice_id',
		component: () => import('./components/invoice/index.vue'),
		beforeEnter: [
			() => auth(),

			async (to) => {
				await useProcessStore().get_invoice_data(to.params.invoice_id)


			}
		]

	},

	{ name: 'Fatura', path: '/invoices/:type', component: () => import('./pages/invoices/index.vue') },

	// {
	// 	name: 'Proces', path: '/process/:process_id/:step_id/:document_id',
	// 	component: () => import('./pages/process/index.vue'),
	// 	beforeEnter:[
	// 		() => auth(),
	// 		// Get document list of process ...
	// 		(to) => useProcessStore().get_process_documents(
	// 			to.params.process_id,
	// 			to.params.step_id,
	// 			to.params.document_id
	// 		)
	// 	] 
	// }
]


const router = createRouter({
	history: createWebHistory(),
	routes,
	// permissionMixin
})

export default router
