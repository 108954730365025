<template>
  <div class="min-h-screen bg-gray-50 py-8">
    <div v-if="user.user_id" class="max-w-2xl mx-auto">
      <!-- Profile Card -->
      <div class="bg-white rounded-lg shadow-lg overflow-hidden">
        <!-- Profile Header -->
        <div class="bg-gradient-to-r from-blue-500 to-blue-600 px-6 py-4">
          <h2 class="text-xl font-semibold text-white">Profili Juaj</h2>
        </div>

        <!-- Profile Info -->
        <div class="p-6 space-y-4">
          <!-- User Details -->
          <div class="space-y-3">
            <div class="flex items-center py-2 border-b border-gray-200">
              <span class="text-gray-600 font-medium">Emri:</span>
              <span class="ml-auto text-gray-900">{{user.user_name}}</span>
            </div>
            <div class="flex items-center py-2 border-b border-gray-200">
              <span class="text-gray-600 font-medium">Email:</span>
              <span class="ml-auto text-gray-900">{{user.user_email}}</span>
            </div>
            <div class="flex items-center py-2 border-b border-gray-200">
              <span class="text-gray-600 font-medium">Roli:</span>
              <span class="ml-auto text-gray-900">{{user.role_name}}</span>
            </div>
          </div>

          <!-- Points of Sale -->
          <div class="bg-gray-50 rounded-lg p-4">
            <div class="flex">
              <span class="text-gray-600 font-medium">Pika shitjesh:</span>
              <div class="ml-auto">
                <span v-for="(pos, index) in pos_list" 
                      :key="index"
                      class="block text-right text-gray-900">
                  {{ pos.name }}
                </span>
              </div>
            </div>
          </div>

          <!-- Change Password Section -->
          <div class="mt-6">
            <!-- Change Password Button -->
            <div v-if="!show_chane_password" class="text-center">
              <button @click.prevent="start_chakne_password" 
                      class="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 transition-colors">
                <i class="fa-solid fa-key mr-2"></i>
                Ndrysho fjalëkalimin
              </button>
            </div>

            <!-- Change Password Form -->
            <div v-else class="bg-gray-50 rounded-lg p-6 relative">
              <!-- Close Button -->
              <button @click.prevent="cancel_change_password()" 
                      class="absolute top-4 right-4 text-gray-400 hover:text-gray-600 focus:outline-none">
                <i class="fa-solid fa-times"></i>
              </button>

              <div class="space-y-4">
                <!-- Current Password -->
                <div>
                  <label for="current_password" class="block text-sm font-medium text-gray-700">
                    Fjalëkalimi i vjetër
                  </label>
                  <div class="mt-1 relative rounded-md shadow-sm">
                    <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                      <i class="fa-solid fa-lock text-gray-400"></i>
                    </div>
                    <input id="current_password"
                           v-model="current_password"
                           type="password"
                           readonly
                           onfocus="this.removeAttribute('readonly');"
                           class="block w-full pl-10 pr-3 py-2 border border-gray-300 rounded-md text-sm focus:ring-blue-500 focus:border-blue-500"
                           placeholder="Shkruani fjalëkalimin aktual" />
                  </div>
                </div>

                <!-- New Password -->
                <div>
                  <label for="passwordinput-0" class="block text-sm font-medium text-gray-700">
                    Fjalëkalimi i ri
                  </label>
                  <div class="mt-1 relative rounded-md shadow-sm">
                    <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                      <i class="fa-solid fa-key text-gray-400"></i>
                    </div>
                    <input id="passwordinput-0"
                           v-model="new_password"
                           type="password"
                           readonly
                           onfocus="this.removeAttribute('readonly');"
                           class="block w-full pl-10 pr-3 py-2 border border-gray-300 rounded-md text-sm focus:ring-blue-500 focus:border-blue-500"
                           placeholder="Shkruani fjalëkalimin e ri" />
                  </div>
                </div>

                <!-- Confirm New Password -->
                <div>
                  <label for="passwordinput-1" class="block text-sm font-medium text-gray-700">
                    Përsërit fjalëkalimin e ri
                  </label>
                  <div class="mt-1 relative rounded-md shadow-sm">
                    <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                      <i class="fa-solid fa-key text-gray-400"></i>
                    </div>
                    <input id="passwordinput-1"
                           v-model="new_confirm_password"
                           type="password"
                           readonly
                           onfocus="this.removeAttribute('readonly');"
                           class="block w-full pl-10 pr-3 py-2 border border-gray-300 rounded-md text-sm focus:ring-blue-500 focus:border-blue-500"
                           placeholder="Konfirmoni fjalëkalimin e ri" />
                  </div>
                  <p v-if="!can_change_password && (this.new_password != '' && this.new_confirm_password != '')" 
                     class="mt-2 text-sm text-red-600">
                    Vendos të njëjtin fjalëkalim në të dy fushat
                  </p>
                </div>

                <!-- Submit Button -->
                <div class="mt-6">
                  <button v-if="can_change_password"
                          @click.prevent="change_password()"
                          class="w-full inline-flex justify-center items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 transition-colors">
                    <i class="fa-solid fa-check mr-2"></i>
                    Ndrysho
                  </button>
                  <button v-else
                          disabled
                          class="w-full inline-flex justify-center items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-gray-400 cursor-not-allowed">
                    <i class="fa-solid fa-check mr-2"></i>
                    Ndrysho
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Api from '../../services/Api'
export default {
    data() {
        return {
            user: {},
            show_chane_password: false,
            current_password: '',
            new_password: '',
            new_confirm_password: '',
            pos_list: []

        }
    },
    created(){
        this.get_profile_data()
    },
    methods: {
        get_profile_data(){
            Api(true).post('/profile/get/data')
            .then(r => {
                this.user = r.data.user[0]
                this.pos_list = r.data.pos_list
            })
        },
        start_chakne_password(){
            this.show_chane_password = true
            this.current_password = ''
            this.new_password = '',
            this.new_confirm_password = ''
        },
        cancel_change_password(){
            this.show_chane_password = false
            this.new_password = '',
            this.new_confirm_password = ''
        },
        change_password(){
            Api(true).post('/profile/change/password', {
                current_password: this.current_password,
                new_password: this.new_password, 
                new_confirm_password: this.new_confirm_password
            }).then(res => {
                if(res.data.changed == 1){
                    this.$toast.success('Password-i u ndryshua me sukses.')
                    this.cancel_change_password()
                }
            })
        },
    },
    computed: {
        can_change_password(){
            var a = false
            if(this.new_password == this.new_confirm_password && (this.new_password != '' || this.new_confirm_password != '' )) a = true
            return a
        }
    }

}
</script>
<style scoped>

@keyframes slideDown {
  from { opacity: 0; transform: translateY(-10px); }
  to { opacity: 1; transform: translateY(0); }
}

.bg-gray-50 {
  animation: slideDown 0.3s ease-out;
}
</style>
