<template>
  <div class="min-h-screen flex items-center justify-center bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
    <div class="max-w-md w-full space-y-8 bg-white p-8 rounded-xl shadow-lg">
      <!-- Header -->
      <div class="text-center">
        <h2 class="text-3xl font-bold text-gray-900">
          Regjistrohu
        </h2>
        <p class="mt-2 text-sm text-gray-600">
          Plotëso të dhënat për të krijuar llogarinë tënde
        </p>
      </div>

      <!-- Form -->
      <form class="mt-8 space-y-6" autocomplete="off">
        <!-- Name Field -->
        <div>
          <label for="textinput-n" class="block text-sm font-medium text-gray-700">
            Emri
          </label>
          <div class="mt-1 relative rounded-md shadow-sm">
            <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
              <i class="fa-regular fa-user text-gray-400"></i>
            </div>
            <input
              id="textinput-n"
              v-model="new_user.name"
              type="text"
              required
              class="block w-full pl-10 pr-3 py-2 border border-gray-300 rounded-md leading-5 bg-white placeholder-gray-500 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent transition-colors"
              placeholder="Shkruani emrin tuaj"
            />
          </div>
        </div>

        <!-- Email Field -->
        <div>
          <label for="textinput-0" class="block text-sm font-medium text-gray-700">
            Email
          </label>
          <div class="mt-1 relative rounded-md shadow-sm">
            <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
              <i class="fa-regular fa-envelope text-gray-400"></i>
            </div>
            <input
              id="textinput-0"
              v-model="new_user.email"
              type="email"
              required
              autocomplete="new-password"
              class="block w-full pl-10 pr-3 py-2 border border-gray-300 rounded-md leading-5 bg-white placeholder-gray-500 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent transition-colors"
              placeholder="Shkruani emailin tuaj"
            />
          </div>
        </div>

        <!-- Password Field -->
        <div>
          <label for="passwordinput-0" class="block text-sm font-medium text-gray-700">
            Fjalëkalimi
          </label>
          <div class="mt-1 relative rounded-md shadow-sm">
            <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
              <i class="fa-solid fa-lock text-gray-400"></i>
            </div>
            <input
              id="passwordinput-0"
              v-model="new_user.password"
              type="password"
              required
              readonly
              onfocus="this.removeAttribute('readonly');"
              class="block w-full pl-10 pr-3 py-2 border border-gray-300 rounded-md leading-5 bg-white placeholder-gray-500 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent transition-colors"
              placeholder="Zgjidhni një fjalëkalim"
            />
          </div>
        </div>

        <!-- Password Confirmation Field -->
        <div>
          <label for="passwordinput-1" class="block text-sm font-medium text-gray-700">
            Përsërit fjalëkalimin
          </label>
          <div class="mt-1 relative rounded-md shadow-sm">
            <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
              <i class="fa-solid fa-lock text-gray-400"></i>
            </div>
            <input
              id="passwordinput-1"
              v-model="new_user.password_confirmation"
              type="password"
              required
              readonly
              onfocus="this.removeAttribute('readonly');"
              class="block w-full pl-10 pr-3 py-2 border border-gray-300 rounded-md leading-5 bg-white placeholder-gray-500 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent transition-colors"
              placeholder="Shkruani përsëri fjalëkalimin"
            />
          </div>
        </div>

        <!-- Action Buttons -->
        <div class="flex items-center justify-between pt-6 border-t border-gray-200">
          <!-- Login Link -->
          <router-link 
            to="/login"
            class="group flex items-center text-sm font-medium text-gray-600 hover:text-gray-900 transition-colors"
          >
            <i class="fa-solid fa-chevron-left mr-2 text-gray-400 group-hover:text-gray-600 transition-colors"></i>
            Kthehu te hyrja
          </router-link>

          <!-- Register Button -->
          <button
            @click.prevent="register"
            class="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 transition-colors"
          >
            Regjistrohu
            <i class="fa-solid fa-arrow-right ml-2"></i>
          </button>
        </div>
      </form>
    </div>
  </div>
</template>
<script charset="utf-8">
import Api from '@/services/Api.js'
import { storeToRefs } from 'pinia'
import { useUserStore } from '@/stores/user'
var CryptoJS = require("crypto-js");
export default {
	data(){
		return {
			new_user: {
				name: '',
				firstname: '-',
				lastname: '-',
				email: '',
				password: '',
				password_confirmation: '',
			},
			user_store: storeToRefs(useUserStore())
		}
	}, 
	created(){
		this.$document.title = "Register"
	},
	methods: {
		register(){
			Api().post('user/register', this.new_user)
				.then(r => {
					localStorage.user = JSON.stringify(r.data.user)
					this.user_store.user = r.data.user
					localStorage.token = r.data.token

					// Save permissions to localstorage
					var a = CryptoJS.AES.encrypt(JSON.stringify(r.data.user_permissions), localStorage.token).toString();
					localStorage.permissions = a

					this.$router.push({ path: '/home'})
					this.user_store.is_loggedin = true
				})
				// .catch(( error ) => {
				// 	if(error.response){
				// 		var err = error.response.data
				// 		this.$toast.warning(err.message)
				// 		Object.keys(err['errors']).map((e, i) => {
				// 			this.$toast.error((i+1) + '. ' + err.errors[e])
				// 		})
				// 	}
				// })
		}
	}
}	
</script>
<style> 
@keyframes fadeIn {
  from { opacity: 0; transform: translateY(-20px); }
  to { opacity: 1; transform: translateY(0); }
}

.max-w-md {
  animation: fadeIn 0.5s ease-out;
}
</style>
