<template>
  <div>
    <div class="py-2 px-0" style="">
      <div v-for="product in useProcessStore().product_list" :key="product.guid"
        :class="{'bg-slate-100': product.validated == 0}">
        <article class="media border-b-4 border-dotted border-slate-300 pb-2 pt-1 mb-2 flex items-center px-1"
          :class="{ 'ml-6': product.reference_id }">
          <!-- BLOCK: AM ALLOWED TO EDIT MATERIAL -->
          <figure class="media-left" v-if="useProcessStore().current_step.creates_invoices == 0">
            <span v-if="product.am_allowed">
              <i class="fa-solid fa-circle text-green-400" v-if="!product.log_id"></i>
              <span class="fa-stack" v-else>
                <i class="fa-solid fa-circle text-red-700 fa-stack-2x"></i>
                <i class="fa-solid fa-check text-white fa-stack-1x fa-inverse"></i>
              </span>
            </span>
            <!-- stacked icons of a circle and a lock over it -->
            <span class="fa-stack" v-else>
              <i class="fa-solid fa-circle text-red-700 fa-stack-2x"></i>
              <i class="fa-solid fa-lock text-white fa-stack-1x fa-inverse"></i>
            </span>
          </figure>
          <!-- invoice step: all products are green if allowed to the user -->
          <figure v-else-if="useProcessStore().current_step.creates_invoices == 1">
            <span class="fa-stack">
              <i class="fa-solid fa-circle text-green-700 fa-stack-2x"></i>
              <i class="fa-solid fa-money-bill text-white fa-stack-1x fa-inverse"></i>
            </span>
          </figure>
          <div class="media-content">
            <div class="content">
              <div>
                <div class="text-gray-400 text-xs">{{ product.type_id_name }}</div>
                <strong class=" mr-2">{{ product.name }}</strong>
                <div class="is-italic" v-if="product.name != product.description">
                  {{ product.description }}
                </div>
              </div>
              <!-- <div class="is-flex"> </div> -->
              <div class="flex items-center my-1">
                <div class="text-slate-400 mr-2 text-sm">Serial</div>
                <div>
                  <input 
                    @keyup.enter="start_save_serial(product)"
                    :ref="'serial_input_' + product.detail_id"
                    :id="'serial_input_' + product.detail_id"
                    v-model="product.serial_number"
                    type="text" class="input is-small py-0 px-1 h-6 serial_input">
                </div>
              </div>
              <div class="is-flex is-align-items-center">
                <div class="">
                  <div class="is-flex">
                    <!-- <a v-if="useProcessStore().current_step.adds_services == 1"
                      @click.prevent="product.show_services = !product.show_services" href="">
                      <i class="fa-solid fa-chevron-down mx-2"></i>
                    </a> -->
                    <a v-if="useProcessStore().current_step.adds_services == 1"
                      @click.prevent="useProcessStore().toggle_process_services(product)" href="">
                      <i class="fa-solid fa-chevron-down mx-2"></i>
                    </a>
                    <div v-if="product.extra_services_types">
                      <item-service-group :product="product"></item-service-group>
                    </div>
                    <span v-if="useProcessStore().current_step.adds_services" class="mr-3 text-slate-400">
                      <small v-if="!product.extra_services" class="border rounded px-2 py-1">
                        Sherbime ...
                      </small>
                    </span>
                  </div>
                  <items-services-manager v-if="product.show_services" :product="product"></items-services-manager>
                </div>
                <div v-if="useProcessStore().current_step.adds_accessories" class="">
                  <div class="relative">
                    <span @click="toggle_accessories_dropdown(product)"
                      class="tag is-success is-light is-clickable">Aksesore</span>
                    <product-accessories v-if="product.show_accessories_dropdown" :product="product"
                      @toggle_accessories_dropdown_1="toggle_accessories_dropdown"></product-accessories>
                  </div>
                </div>
                <div v-if="product.warranty_airbag_value && useProcessStore().current_step.gives_warranty"
                  @click.prevent="useProcessStore().save_warranty_airbag_amount(product)" class="text-12px ml-2 border rounded px-2 cursor-pointer">
                  <div class="flex items-center">
                    <div class="flex flex-col">
                      <span> Garanci airbag </span>
                      <span>
                        {{product.warranty_airbag}} muaj - {{product.warranty_airbag_value}} Leke
                      </span>
                    </div>
                    <div class="ml-2">
                      <i v-if="product.warranty_airbag_amount > 0" class="fa-regular fa-square-check"></i>
                      <i v-else class="fa-regular fa-square"></i>
                    </div>
                  </div>
                </div>
                <div class="ml-2 border-l pl-2">
                  <div class=" relative"
                    v-if="(product.has_exclusive_accessories > 0 && useProcessStore().current_step.adds_accessories && product.reference_id == null)">
                    <!--&& useProcessStore().product_exclusive_accessories.length == 0"  -->
                    <div @click="toggle_dropdown('accessory', product)" class="tags has-addons is-clickable mb-0">
                      <span class="tag is-primary mb-0">Aksesore ekskluzive</span>
                      <span class="tag mb-0">{{ product.has_exclusive_accessories }}</span>
                    </div>
                    <!-- Exclusive dropdown -->
                    <exclusive-dropdown v-if="product.show_exclusive_dropdown"
                      :is_visible="product.show_exclusive_dropdown" :entity="exclusive_dropdown_entity"
                      :selected_product="selected_product" :location="'product_list'"
                      @close_exclusive_dropdown="close_exclusive_dropdown"></exclusive-dropdown>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-if="product.from_warehouse_name" class="media-right is-align-self-center w-56">
            <small class="text-gray-400">
              <p class="text-xs">
                Magazina
              </p>
              <a href="" class="text-blue-500 uppercase" v-if="useProcessStore().process?.document_data?.completed != 1"
                @click.prevent="product.show_from_warehouses = true; getOtherWarehousesOf(product);"
                title="Ndrysho magazinen...">
                <i class="fa-solid fa-warehouse fa-xs"></i>
                {{ product.from_warehouse_name }}
              </a>
              <span v-else>
                <i class="fa-solid fa-warehouse fa-xs"></i>
                <span class="mx-2 text-gray-800">
                  {{ product.from_warehouse_name }}
                </span>
                <i class="fa-solid fa-check fa-xs text-green-500"></i>
              </span>

              <div class="flex items-center justify-between gap-2" 
                v-if="product.show_from_warehouses">
                <a v-if="product.loaded_other_warehouse" href=""
                  @click.prevent="product.show_from_warehouses = false; product.other_warehouses = []" title="Mbyll"
                  class="flex text-center">
                  <i class="fa-solid fa-times-circle  fa-xs"></i>
                </a>
                <span v-else>
                  <i class="fa-solid fa-spinner fa-spin fa-xs"></i>
                </span>
                <!-- BLOCK: other warehouse -->
                <select v-if="product.other_warehouse.length > 0"
                  @change="useProcessStore().save_warehouse_change(product)" class="select is-small"
                  v-model="product.from_other_warehouse_id">
                  <option value="">Ndrysho magazinen...</option>
                  <option v-for="warehouse in product.other_warehouse" :key="warehouse.warehouse_id"
                    :value="warehouse.warehouse_id">
                    {{ warehouse.name }} x{{ warehouse.amount }}
                  </option>
                </select>
                <span v-if="product.loaded_other_warehouse && product.other_warehouse.length == 0"
                  class="text-xs text-red-500">
                  Nuk ka magazina te tjera
                </span>
              </div>
            </small>
          </div>
          <div v-if="useProcessStore().current_step.can_see_amount == 1" class="media-right is-align-self-center text-center w-24">
            <p class="text-xs">Sasia</p>
            <input 
              v-if="useProcessStore().process?.document_data?.completed != 1 && product.document_item_discount == 0"
              v-model="product.amount" 
              @keyup.enter="save_process_item_click(product)"
              class="input is-small" 
              type="text" style="width:80px;">
            <span v-else>
              {{ useProcessStore().amount_filter(product.amount) }}
            </span>
          </div>
          <div v-else-if="useProcessStore().current_step.can_see_amount == 0" class="media-right is-align-self-center w-24">
            <p class="text-xs">Sasia</p>
            <div class="text-center">{{ useProcessStore().amount_filter(product.amount) }}</div>
          </div>
          <div v-if="useProcessStore().current_step.can_see_price" 
            class="media-right is-flex w-32 flex-col justify-end">
            <div :class="{ 'text-slate-400': useProcessStore().extras_total(product) > 0 }" 
              class="is-flex flex-col text-right ">
                <span class="text-xs">
                  Cmimi i produktit
                </span>
                <div class="text-right flex flex-col" :class="{ 
                    'font-semibold': !useProcessStore().extras_total(product) > 0
                   }">
                   <span :class="{'line-through text-slate-400' : product.discounted_price}">
                    {{ useProcessStore().price_filter(useProcessStore().product_total(product)) }}
                   </span>
                   <span v-if="product.discounted_price">
                    {{ useProcessStore().price_filter(useProcessStore().product_total_discounted(product)) }}
                   </span>
                </div>
              </div>
              <div v-if="useProcessStore().extras_total(product) > 0"
                class="is-flex flex-col is-justify-content-space-between text-slate-400 text-right">
                <div>
                  <span class="text-xs ">Totali i sherbimeve</span>
                </div>
                <div>
                  {{ useProcessStore().price_filter(useProcessStore().extras_total(product)) }}
                </div>
              </div>
              <div v-if="useProcessStore().extras_total(product) > 0" class="is-flex is-justify-content-space-between flex-col text-right">
                <div>
                  <span class="text-xs ">Totali i produktit</span>
                </div>
                <div class="font-semibold">
                  {{ useProcessStore().price_filter(useProcessStore().item_overall_total(product)) }}
                </div>
              </div>
          </div>
          <div v-if="useProcessStore().current_step.delete_document_item && useProcessStore().process?.document_data?.completed != 1" @click.prevent="delete_item(product)"
            class="ml-4 border-red-200 rounded-full border px-2 py-1 hover:bg-red-600 is-clickable">
            <i  class="fa-solid fa-trash-can text-gray-300"></i>
          </div>
        </article>
      </div>
    </div>


    <!-- Services modal -->
    <div class="h-screen w-screen fixed top-0 left-0 bg-black bg-opacity-50 flex items-start justify-center"
      style="z-index:9;"
      v-if="useProcessStore().show_services_modal" @click.self="useProcessStore().show_services_modal = false">
      <div class="p-2 bg-white mt-20 flex-col products-search" style="min-width:500px; max-height:60vh; overflow:auto;" >
        <!-- Search input -->
        <div class="flex items-top relative">

          <div class="w-full flex flex-col">
            <!-- Add service -->
            <span class="text-10px"> Sherbime </span>
            <input type="text" placeholder="Kerko sherbim..." class="border border-gray-300 p-1  text-xs"
              @keydown="useDiaryStore().search_service_diary('all')" 
              v-model="useDiaryStore().service_search_val">
            <span class="absolute bottom-3 right-4  flex items-center">
              <i class="fa fa-search fa-xs"></i>
            </span>
          </div>
          
        </div>
        <!-- Product Search results -->

        <!-- Service search results -->
        <div v-if="useDiaryStore().service_search_results != null"
          class="mt-2"
          style="max-height:80vh; overflow-y:suto;">
          <div v-for="(key, idx) in Object.keys(useDiaryStore().service_search_results)" :key="idx"
            class="flex flex-col text-14px">
            <div class="bg-amber-100 text-10px px-1">{{key}}</div>
            <div v-for="(service, index) in useDiaryStore().service_search_results[key]" :key="index"
              :class="{'bg-slate-100': service.expanded}"
              class="flex flex-col px-2 py-1 border-b border-slate-200">
              <div class="flex">
                <div class="flex flex-col">
                  <span>{{service.name}}</span>
                  <span class="text-10px">{{service.description}}</span>
                </div>
                <div class="ml-auto flex flex-col">
                  <span class="text-right">
                    <span>
                      {{useDiaryStore().price_value_filter(service.price)}} 
                      <span class="text-slate-400 text-10px">Leke</span>
                    </span>
                  </span>
                  <span @click="start_add_item_service(service)"
                    class="w-full bg-green-400 border rounded text-white px-2 text-14px
                      text-center border-green-600 hover:bg-green-500 cursor-pointer ml-auto"
                    style="width:fit-content;">
                    <i class="fa-solid fa-plus"></i>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>

  var Emitter = require('tiny-emitter');
  var emitter = new Emitter();

  export default {
    mounted() {
      emitter.on('put_focus', function (product) {
        console.log('here?', product)
        setTimeout(() => {
            this.$refs['serial_input_' + product.detail_id][0].focus()
              // 'serial_input_' + product.detail_id
          }, 500)
        //
      });
      
    },
    created(){
          // Bring focus to serial input
          var serial_found = false;
          setTimeout(() => {
              console.log(useProcessStore().product_list)
              for(var i = 0; i < useProcessStore().product_list.length; i++) {
                console.log(useProcessStore().product_list[i].serial_number)
                if(!useProcessStore().product_list[i].serial_number){
                  this.$refs['serial_input_' + useProcessStore().product_list[i].detail_id][0].focus()
                  serial_found = true;
                }
              }
              // 'serial_input_' + product.detail_id
              if(!serial_found && useProcessStore().process?.document_data?.completed != 1) { 
                useSearchProcess().show_search_input = true
                setTimeout(() => {
                  document.getElementById("main_search").focus()
                }, 500)
              }
          }, 2000)
          console.log(serial_found)

    },
    methods: {
      async start_save_serial(product){
        var a = await useProcessStore().save_pdi_serial(product)
        if(a.message == 'success'){
            this.$toast.success('Numri serial u ruajt me sukses!')

            // put focus in search
            setTimeout(() => {
              useSearchProcess().show_search_input = true
              setTimeout(() => {
                document.getElementById("main_search").focus()
              }, 500)
            }, 500)

        } else {
            this.$toast.error('Numri serial nuk u ruajt!')
        }
      }
    },
  }
</script>

<script setup>
import { useProcessStore } from '@/stores/process'
import { useDiaryStore } from '@/stores/diary'
import { useSearchProcess } from '@/stores/search_process'
import itemsServicesManager from './items_services_manager.vue';
import itemServiceGroup from './item_service_group.vue';
import productAccessories from './product_accessories.vue';
import { inject, ref, onMounted } from "vue";
import exclusiveDropdown from '@/components/exclusive_dropdown.vue';
import ConfigurationService from "@/services/Configuration.js"

const toast = inject("toast");

var exclusive_dropdown_entity = ref('')
var selected_product = ref({})

function save_process_item_click(product) {
  useProcessStore().save_process_item(product).then(res => {
    if (res.data.message == 'Ndryshimi u ruajt me sukses!') {
      toast.show(res.data.message, { ...{ type: 'success', duration: 4000 } })
      // <i class="fa-brands fa-uniregistry"></i>
    }
    else if (res.data.message.includes('Sasia e vendosur eshte me e madhe')) {
      toast.show(res.data.message, { ...{ type: 'error', duration: 4000 } })
    }
  })
}

function getOtherWarehousesOf(product) {
  useProcessStore().getOtherWarehousesOf(product)
}

function toggle_dropdown(entity, data) {
  // exclusive_dropdown_visible.value = !exclusive_dropdown_visible.value
  useProcessStore().product_list = useProcessStore().product_list.map(sr => {
    if (sr.detail_id == data.detail_id)
      sr.show_exclusive_dropdown = !sr.show_exclusive_dropdown
    else
      sr.show_exclusive_dropdown = false
    return sr
  })
  exclusive_dropdown_entity.value = entity
  selected_product.value = data
}

function toggle_accessories_dropdown(data) {
  // exclusive_dropdown_visible.value = !exclusive_dropdown_visible.value
  useProcessStore().product_list = useProcessStore().product_list.map(sr => {
    if (sr.detail_id == data.detail_id)
      sr.show_accessories_dropdown = !sr.show_accessories_dropdown
    else
      sr.show_accessories_dropdown = false
    return sr
  })
}

function close_exclusive_dropdown(data) {
  toggle_dropdown('accessory', data.product)
}

function delete_item(product) {
  useProcessStore().delete_document_item(product)
    .then(res => {
      if (res) {
        if (res.data.document_deleted) {
          // If is last item, delete document and go to list
          window.location.href = '/process/' + useProcessStore().process.guid + '/list'
        }
        else if (res.data.delete_childs) {
          // Remove parent and childs
          useProcessStore().product_list = [...useProcessStore().product_list.filter(i => {
            if (i.detail_id == res.data.item_deleted.detail_id || i.reference_id == res.data.item_deleted.detail_id) {
              return false
            }
            else return true
          })]
          toast.show(res.data.message, { ...{ type: 'success', duration: 5000 } });
        }
        else if (res.data.keep_accessories) {
          // Delete parent - edit childrens
          useProcessStore().product_list = [...useProcessStore().product_list.filter(i => {
            if (i.detail_id == res.data.item_deleted.detail_id) {
              return false
            }
            else return true
          })]
          useProcessStore().product_list = [...useProcessStore().product_list.map(p => {
            if (p.reference_id == res.data.item_deleted.detail_id) {
              p.reference_id = null
            }
            return p
          })]
        }
        else {
          // Delete single item
          useProcessStore().product_list = [...useProcessStore().product_list.filter(i => {
            if (i.detail_id == res.data.item_deleted.detail_id || i.reference_id == res.data.item_deleted.detail_id) {
              return false
            }
            else return true
          })]
          toast.show(res.data.message, { ...{ type: 'success', duration: 5000 } });
        }
      }
    })
}

async function start_add_item_service(product) {
  var a = await useProcessStore().add_item_service(product)
  // console.log('a', a)
  if(a.message == 'success'){
    // toast.success('Sherbimi u shtua me sukses.')
    toast.show('Sherbimi u shtua me sukses.', { ...{ type: 'success', duration: 4000 } });
    // Close service modal
    useProcessStore().show_services_modal = false
  }
}

onMounted(async () => {
  // load payment details if creates invoices 
  if (useProcessStore().current_step.creates_invoices == 1) {
    var payments_data = await ConfigurationService.getPaymentsConfiguration()
    useProcessStore().payment_methods = payments_data.payment_methods
    useProcessStore().payment_terms = payments_data.payment_terms
  }


})

</script>


<style scoped> 
.media-right { 
  border-left: 1px solid #e2e8f0;
  padding-left: 1rem;
}
.serial_input:focus {
  box-shadow: 0 0 0 .3em rgba(31, 185, 0, 0.459)!important;
  border-color: rgb(151, 236, 118)!important;
}
</style>
