<template>
  <div class="space-y-4">
    <template v-for="group in groups" :key="group.key">
      <div 
        class=""
        :style="{ marginLeft: `${group.depth * 16}px` }"
      >

        <!-- Group Header -->
        <div class="flex items-center py-2 gap-2">

          <div class="flex">
            <button 
              @click="toggleGroup(group)"
              class="text-sm text-gray-500 hover:text-gray-700"
            >
              <i :class="[
                'fas',
                expandedGroups.has(getGroupKey(group)) ? 'fa-chevron-down' : 'fa-chevron-right'
              ]"></i>
            </button>
          </div>
          <div class="font-medium text-gray-700">
            <span class="text-gray-900">{{ group.label }}</span>
            <span class="ml-2 text-sm text-gray-500">
							({{ group.items.length }} <span class="font-light">nën elemente,
								total:</span> {{ numeral(group.totalAmount).format('0,0.00') }})
            </span>
          </div>

        </div>

        <!-- Group Content -->
        <div 
          v-if="expandedGroups.has(getGroupKey(group))"
          class="mt-2"
        >
          <!-- Leaf nodes (actual invoices) -->
          <template v-if="!group.items[0]?.depth">
            <div class="overflow-x-auto">
              <table class="min-w-full divide-y divide-gray-200">
                <thead class="bg-gray-50">
                  <tr>
                    <th class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider w-20">#</th>
                    <th class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Data</th>
                    <th class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Operatori</th>
                    <th class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      {{ type === 'Shitje' ? 'POS' : 'Furnitori' }}
                    </th>
                    <th class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      {{ type === 'Shitje' ? 'Klienti' : 'Furnitori' }}
                    </th>
                    <th class="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">Vlera</th>
                    <th class="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">
                      <i class="fa fa-cog"></i>
                    </th>
                  </tr>
                </thead>
                <tbody class="bg-white divide-y divide-gray-200">
                  <tr 
                    v-for="invoice in group.items" 
                    :key="invoice.invoice_id"
                    class="hover:bg-gray-50 transition-colors duration-200"
                  >
                    <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-400">
                      {{ invoice.process_number }}
                    </td>
                    <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                      {{ formatDate(invoice.completed_at) }}
											<span class="font-mono text-xs text-gray-500">{{ formatHour(invoice.completed_at) }}</span>
                    </td>
                    <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                      {{ invoice.created_by }}
                    </td>
                    <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                      {{ type === 'Shitje' ? invoice.pos_name : invoice.supplier_name }}
                    </td>
                    <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                      {{ type === 'Shitje' ? invoice.customer_name : invoice.supplier_name }}
                    </td>
                    <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-900 text-right">
                      {{ numeral(invoice.document_price).format('0,0.00') }}
                    </td>
                    <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500 text-center">
                      <div class="flex justify-center space-x-3">
                        <button class="text-indigo-600 hover:text-indigo-900 transition-colors duration-200">
                          <i class="fas fa-eye"></i>
                        </button>
                        <button class="text-indigo-600 hover:text-indigo-900 transition-colors duration-200">
                          <i class="fa fa-print"></i>
                        </button>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </template>
          <!-- Nested groups -->
          <template v-else>
            <GroupedInvoices
              :groups="group.items"
              :type="type"
              class="mt-4"
            />
          </template>
        </div>
      </div>
    </template>
  </div>
</template>

<script setup>
import { ref, onMounted, defineProps } from 'vue'
const numeral = require('numeral')

const props = defineProps({
  groups: {
    type: Array,
    required: true
  },
  type: {
    type: String,
    required: true
  }
})

// Track expanded/collapsed state of groups
const expandedGroups = ref(new Set())

// Helper function to format dates
const formatDate = (dateStr) => {
  if (!dateStr) return ''
  const date = new Date(dateStr)
  return date.toLocaleDateString('sq-AL', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit'
  })
}

const formatHour = (dateStr) => {
	if (!dateStr) return ''
	const date = new Date(dateStr)
	return date.toLocaleTimeString('sq-AL', {
		hour: '2-digit',
		minute: '2-digit'
	})
}
// Generate unique key for each group
const getGroupKey = (group) => {
  return `${group.column}-${group.key}-${group.depth}`
}

// Toggle group expansion
const toggleGroup = (group) => {
  const key = getGroupKey(group)
  if (expandedGroups.value.has(key)) {
    expandedGroups.value.delete(key)
  } else {
    expandedGroups.value.add(key)
  }
}

// Expand first level groups by default
const initializeExpandedGroups = () => {
  if (props.groups) {
    props.groups.forEach(group => {
      if (group.depth === 0) {
        expandedGroups.value.add(getGroupKey(group))
      }
    })
  }
}

// Initialize expanded groups on mount
onMounted(() => {
  initializeExpandedGroups()
})
</script>

<style scoped>
.group-transition-enter-active,
.group-transition-leave-active {
  transition: all 0.3s ease-in-out;
}

.group-transition-enter-from,
.group-transition-leave-to {
  opacity: 0;
  transform: translateY(-10px);
}
</style>
