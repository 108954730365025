<template>
  <div class="topmost">
    <nav class="bg-gray-800 shadow-lg">
      <div class="max-w-7xl mx-auto px-4">
        <div class="flex justify-between h-14">
          <!-- Left side - Logo/Company Name -->
          <div class="flex items-center">
            <router-link to="/" class="flex items-center">
              <span class="font-bold hover:text-sky-200 " style="color: #ffa500" >
                {{configs.company_name}}
              </span>
            </router-link>
          </div>

          <!-- Mobile menu button -->
          <div class="flex items-center md:hidden">
            <button @click.prevent="show_menu_mobile = !show_menu_mobile"
                    class="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                    aria-expanded="false">
              <span class="sr-only">Open main menu</span>
              <svg class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h16M4 18h16" />
              </svg>
            </button>
          </div>

          <!-- Desktop Navigation -->
          <div class="hidden md:flex md:items-center md:space-x-4" 
               :class="{'mobile-nav': show_menu_mobile}"
               v-if="user_store.session_check == 1">
            <!-- Search Button -->
            <button v-if="user_store.is_loggedin && am_allowed('menu_roles.can_see_menu_search')"
                    @click.prevent="start_open_search_page()"
                    class="inline-flex items-center px-3 py-2 rounded-md text-sm font-medium text-white bg-red-600 hover:bg-red-700 transition-colors duration-200">
              <i class="fa-solid fa-magnifying-glass"></i>
            </button>

            <!-- Notifications -->
            <div v-if="user_store.is_loggedin" class="relative">
              <button @click.prevent="open_notifications_list()"
                      class="inline-flex items-center px-3 py-2 rounded-md text-sm font-medium text-white bg-sky-600 hover:bg-sky-700 transition-colors duration-200">
                <i class="fa-solid fa-bell mr-2"></i>
                <span class="bg-red-500 text-white text-xs rounded-full px-2 py-0.5">{{menu_store.notification_count}}</span>
              </button>

              <!-- Notifications Dropdown -->
              <div v-if="menu_store.show_menu_notifications"
                   v-click-outside="click_outside_notifications()"
                   class="absolute right-0 mt-2 w-80 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none topmost">
                <div class="py-1" v-if="menu_store.visible_notifications.length > 0">
                  <div v-for="notification, index in menu_store.visible_notifications" :key="index"
                       @click="open_notification(notification)"
                       class="px-4 py-3 hover:bg-gray-50 cursor-pointer border-b border-gray-100 last:border-0">
                    <div class="flex justify-between items-center mb-2">
                      <span class="text-sky-600 text-sm font-medium">
                        {{notification.type == 'discount_request' ? 'Kerkese per zbritje' : ''}}
                      </span>
                      <span class="text-gray-500 text-xs">{{format_date(notification.requested_at)}}</span>
                    </div>
                    <div class="space-y-1">
                      <div class="flex items-center text-sm text-gray-600">
                        <i class="fa-solid fa-user w-4 text-gray-400"></i>
                        <span class="ml-2">{{notification.requested_by_name}}</span>
                      </div>
                      <div class="flex items-center text-sm text-gray-600">
                        <i class="fa-regular fa-circle-user w-4 text-gray-400"></i>
                        <span class="ml-2">{{notification.customer_name}}</span>
                      </div>
                      <div class="flex items-center text-sm text-gray-600">
                        <i class="fa fa-store w-4 text-gray-400"></i>
                        <span class="ml-2">{{notification.pos_name}}</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div v-else class="py-3">
                  <p class="text-center text-sm text-gray-500">
                    Nuk ka njoftime te reja
                  </p>
                </div>
              </div>
            </div>

            <!-- User Profile & Help -->
            <router-link v-if="user_store.is_loggedin" to="/profile" 
                        class="text-gray-300 hover:text-white px-3 py-2 rounded-md text-sm font-medium transition-colors duration-200">
              <i class="fa-solid fa-user mr-2"></i>
              <span>{{user_store.user.name}}</span>
            </router-link>

            <router-link to="/help"
                        class="text-gray-300 hover:text-white px-3 py-2 rounded-md text-sm font-medium transition-colors duration-200">
              Help
            </router-link>

            <!-- Login/Logout -->
            <router-link v-if="!user_store.is_loggedin" to="/login"
                        class="text-gray-300 hover:text-white px-3 py-2 rounded-md text-sm font-medium transition-colors duration-200">
              Login
            </router-link>

            <button v-if="user_store.is_loggedin" @click.prevent="logout"
                    class="text-gray-300 hover:text-white px-3 py-2 rounded-md text-sm font-medium transition-colors duration-200">
              Logout
            </button>
          </div>

          <!-- Loading State -->
          <div v-if="user_store.session_check == -1" class="flex items-center">
            <div class="text-sky-400 px-3 py-2">
              <i class="fa-solid fa-spinner fa-spin text-lg"></i>
            </div>
          </div>
        </div>
      </div>

      <!-- Mobile Navigation Menu -->
      <div v-if="show_menu_mobile" class="md:hidden">
        <div class="px-2 pt-2 pb-3 space-y-1 sm:px-3">
          <!-- Mobile menu items -->
          <router-link v-if="user_store.is_loggedin" to="/profile" 
                      class="text-gray-300 hover:text-white block px-3 py-2 rounded-md text-base font-medium">
            <i class="fa-solid fa-user mr-2"></i>{{user_store.user.name}}
          </router-link>

          <router-link to="/help"
                      class="text-gray-300 hover:text-white block px-3 py-2 rounded-md text-base font-medium">
            Help
          </router-link>

          <button v-if="user_store.is_loggedin" @click.prevent="logout"
                  class="text-gray-300 hover:text-white block w-full text-left px-3 py-2 rounded-md text-base font-medium">
            Logout
          </button>
        </div>
      </div>
    </nav>

    <Cookies v-if="!has_cookies" />
  </div>
</template>

<script>
import Api from '@/services/Api'
import { storeToRefs } from 'pinia'
import { useUserStore } from '@/stores/user'
import { useMenuStore } from '@/stores/menu'
import { defineAsyncComponent } from 'vue'
import { useConfigurationsStore } from '@/stores/configurations'
import permissionMixin from '@/mixins/permissions-mixin.js'
// import { useRoute } from 'vue-router'
import moment from "moment";

	export default{
		mixins: [permissionMixin],
		components: {
			Cookies: defineAsyncComponent( () => import('@/components/Cookies.vue') )
		},
		data(){
			return {
				user_store: storeToRefs(useUserStore()),
				menu_store: storeToRefs(useMenuStore()),
				
				show_menu_mobile: false, 
				configs: storeToRefs(useConfigurationsStore())
			}
		},
		computed: {
			has_cookies() {
				return localStorage.cookies;
			},
		},
		created(){
		},
		methods: {
			clearLocalStorage(){
				localStorage.removeItem("user")
				localStorage.removeItem("token")
				localStorage.removeItem("user_roles")
				localStorage.removeItem("permissions")
				// localStorage.removeItem("hiden_columns")
			},
			logout(){
				Api(true).post("user/logout", {})
					.finally(() => {
						this.clearLocalStorage()
						this.$router.push({path: "/login"})
						this.user_store.is_loggedin = false
						this.user_store.user = {}
					})
			},
			open_notifications_list(){
				useMenuStore().open_notifications_list()
			},
			start_open_search_page(){
				this.$router.push({name: 'Kerko produkte', path: "/search_page"})
			},
			format_date(date) {
				return moment(date, "YYYY-MM-DD HH:mm:ss").format("DD.MM.YYYY HH:ss");
			},
			open_notification(notification){
				if(notification.type == 'discount_request'){
					this.$router.push({ name: 'Konfirmo zbritje', params: { id: notification.discount_request_id } })
				}
			},
			click_outside_notifications(){
				if(this.menu_store.show_menu_notifications){
					// this.menu_store.show_menu_notifications = false
				}
			}
		}
	}
</script>

<style scoped>

.mobile-menu{
	display: block!important;
	color:#fff!important;
	background: #646f9b;
}
#navbarBasicExample .navbar-end .navbar-item{
	color:#ffffff;
}

.notification-list-wrap{
	position: absolute; 
	top: 35px; 
	left: 0; 
	z-index:99; 
	box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px; 
	overflow-y: auto; 
	max-height: 400px;
}
.topmost{
	z-index: 9999;
}
</style>

