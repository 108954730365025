<template>
	<div style="width: 486.88px; margin: 0px auto; border: 0px solid #000; padding: 0px; font-size: 11px; font-family: 'Arial Narrow', Arial, sans-serif;" id="thermal-invoice">
		<div style="text-align: center;">
			<img src="/logobbw.png" style="margin: auto; height: 45px; margin-top: 20px;">
		</div>
		<div style="text-align: center; font-weight: bold; margin-bottom: 10px; border-top: 1px solid black; width: 100%">
			<h1 style="margin: 0; margin-top: 10px; font-size: 16px;">FATURË TATIMORE SHITJE</h1>
		</div>

		<table style="width: 100%; margin-bottom: 20px; margin: 4px">
			<tr>
				<td style="padding: 2px;"><b>{{ JSON.parse(useProcessStore().invoice_data.fis_response).response.FISPAYMENTLINK?.split(';')[1] }}: Qendra Globe, Rr. e Kavajes</b></td>
			</tr>
			<tr>
				<td style="padding: 2px;">NIPT: <b>{{ JSON.parse(useProcessStore().invoice_data.fis_response).response.FISPAYMENTLINK?.split(';')[0] }}</b></td>
			</tr>
			<tr>
				<td style="padding: 2px;">Kodi i vendit të ushtrimit të veprimtarisë: <b>{{ JSON.parse(useProcessStore().invoice_data.fis_response).response.FISBUSINESSUNIT }}</b></td>
			</tr>
			<tr>
				<td style="padding: 2px;">Kodi i operatorit: <b>{{ JSON.parse(useProcessStore().invoice_data.fis_response).response.FISKODOPERATOR }}</b></td>
			</tr>
			<tr>
				<td style="padding: 2px;">Nr. Fiskalizimi: <b>42064/2024/150742050</b></td>
			</tr>
			<tr>
				<td style="padding: 2px;">Nr. Faturës: <b>{{ JSON.parse(useProcessStore().invoice_data.fis_response).response.NRRENDOR }}</b></td>
			</tr>
			<tr>
				<td style="padding: 2px;">Data dhe ora e lëshimit te faturës: <b>{{ JSON.parse(useProcessStore().invoice_data.fis_response).response.FISPAYMENTLINK?.split(';')[3] }}</b></td>
				<td></td>
			</tr>
		</table>

		<table style="border-collapse: collapse; margin-bottom: 20px; margin: 4px">
			<tr style="">
				<th style="padding: 2px; text-align: left; vertical-align: bottom; border-bottom: 1px solid black; width: 210px">
					Nr. Serial<br>
					Artikull
				</th>
				<th style="padding: 2px; text-align: right; vertical-align: bottom; border-bottom: 1px solid black;">Njësi</th>
				<th style="padding: 2px; text-align: right; vertical-align: bottom; border-bottom: 1px solid black;">Sasia</th>
				<th style="padding: 2px; text-align: right; vertical-align: bottom; border-bottom: 1px solid black; width: 70px;">Cmimi i shitjes me TVSH</th>
				<th style="padding: 2px; text-align: right; vertical-align: bottom; border-bottom: 1px solid black;">Zbritje <br>në %</th>
				<th style="padding: 2px; text-align: right; vertical-align: bottom; border-bottom: 1px solid black; width: 70px">Vlera me TVSH</th>
				<th style="padding: 2px; text-align: right; vertical-align: bottom; border-bottom: 1px solid black; width: 50px">Muaj Garanci</th>
			</tr>
			<tr v-for="prod in useProcessStore().invoice_data.items" :key="prod.product_id">
				<td style="padding: 2px;">{{prod.product_name}}</td>
				<td style="padding: 2px; text-align: right;">{{prod.unit == 1 ? 'COP' : 'Kg/Lt'}}</td>
				<td style="padding: 2px; text-align: right;">{{prod.amount}}</td>
				<td style="padding: 2px; text-align: right;">{{prod.price}}</td>
				<td style="padding: 2px; text-align: right;">
					<!-- prod.price and prod.discounted_price calculates discount percent --> 
					{{ 100 - (((prod.price - prod.discounted_price) / prod.price) * 100) }}
				</td>
				<td style="padding: 2px; text-align: right;">{{prod.price}}</td>
				<td style="padding: 2px; text-align: right;">24 muaj</td>
			</tr>
		</table>

		<table style="border-collapse: collapse; margin-top: 20px;">
			<tr>
				<td style="width:243.44px">
					<table style="width: 100%; margin-bottom: 2px;">
						<tr>
							<td style="padding: 2px; text-align: right; vertical-align: middle;">Iga të cilat: Furnizime të tatueshme</td>
							<td style="padding: 2px; border-bottom: 1px solid black; text-align: right; width: 30%; vertical-align: middle;">1,158.33</td>
						</tr>
						<tr>
							<td style="padding: 2px; text-align: right; vertical-align: middle;">Furnizime të pa tatueshme</td>
							<td style="padding: 2px; border-bottom: 1px solid black; text-align: right; width: 30%; vertical-align: middle;">0</td>
						</tr>
						<tr>
							<td style="padding: 2px; text-align: right; vertical-align: middle;">Monedha</td>
							<td style="padding: 2px; border-bottom: 1px solid black; text-align: right; width: 30%; vertical-align: middle;">ALL</td>
						</tr>
						<tr>
							<td style="padding: 2px; text-align: right; vertical-align: middle;">Kursi i këmbimit</td>
							<td style="padding: 2px; border-bottom: 1px solid black; text-align: right; width: 30%; vertical-align: middle;">1.00</td>
						</tr>
						<tr>
							<td style="padding: 2px; text-align: right; vertical-align: middle;">Mënyra e pagesës</td>
							<td style="border-bottom: 1px solid black; text-align: right; width: 30%; vertical-align: middle;">CASH</td>
						</tr>
					</table>
				</td>
				<td style="width: 243.44px; padding-right: 4px;">
					<table style="width: 100%; margin-bottom: 2px;">
							<tr>
									<td style="padding: 2px; text-align: right; vertical-align: middle;">Total pa zbritje</td>
									<td style="padding: 2px; border-bottom: 1px solid black; text-align: right; vertical-align: middle;">1,158.33</td>
							</tr>
							<tr>
									<td style="padding: 2px; text-align: right; vertical-align: middle;">Zbritje</td>
									<td style="padding: 2px; border-bottom: 1px solid black; text-align: right; vertical-align: middle;">0</td>
							</tr>
							<tr>
									<td style="padding: 2px; text-align: right; vertical-align: middle;">Total pa TVSH</td>
									<td style="padding: 2px; border-bottom: 1px solid black; text-align: right; vertical-align: middle;">1,158.33</td>
							</tr>
							<tr>
									<td style="padding: 2px; text-align: right; vertical-align: middle;">TVSH (20%)</td>
									<td style="padding: 2px; border-bottom: 1px solid black; text-align: right;vertical-align: middle;">231.67</td>
							</tr>
							<tr>
									<td style="padding: 2px; text-align: right; vertical-align: middle;">Total me TVSH</td>
									<td style="padding: 2px; border-bottom: 1px solid black; text-align: right; vertical-align: middle;">1,390</td>
							</tr>
					</table>

				</td>
			</tr>
		</table>

		<div style="text-align: center;margin: 20px 0;">
			<div style="margin: auto; width: 100px; height: 100px;" id="qrcode"></div>
		</div>

		<table style="text-align: center; font-size: 11px; width: 100%; font-weight: 600; border-collapse: collapse; margin-top: 10px">
			<tr>
				<td style="width: 30%; border-top: 1px solid black; border-bottom: 1px solid black;">www.globe.al</td>
				<td style="width: 50%; border-top: 1px solid black; border-bottom: 1px solid black;">Sherbimi per klientin: +355 4 48 06 061</td>
				<td style="border-top: 1px solid black; border-bottom: 1px solid black;">info@globe.al</td>
			</tr>
		</table>
		<br>
	</div>
</template>

<script setup>
import { onMounted } from 'vue';
import { useProcessStore } from '@/stores/process';

onMounted(() => {

	console.log("ON MOUNTED ", useProcessStore().invoice_data)

	const thermalInvoice = document.getElementById('thermal-invoice');
	const thermalInvoiceContent = thermalInvoice.innerHTML;

	const printWindow = window.open('', '_blank', 'width=600,height=600');
	
	if (!printWindow || printWindow.closed || typeof printWindow.closed === 'undefined') {
			// Create an element to display the message to the user
			const errorDiv = document.createElement('div');
			errorDiv.style.backgroundColor = '#f8d7da';
			errorDiv.style.color = '#721c24';
			errorDiv.style.padding = '20px';
			errorDiv.style.margin = '20px';
			errorDiv.style.border = '1px solid #f5c6cb';
			errorDiv.style.borderRadius = '5px';
			errorDiv.style.fontFamily = 'Arial, sans-serif';

			// Set the message content
			errorDiv.innerHTML = `
					<h3>Pop-up Blocked!</h3>
					<p>It looks like your browser blocked the pop-up window. Please disable the pop-up blocker for this website in your browser settings to print the invoice.</p>
					<h4>How to disable pop-up blocking for this site:</h4>
					<ul>
							<li><strong>In Chrome:</strong> Click the pop-up blocker icon in the address bar and select "Always allow pop-ups and redirects from <strong>[sistemi.globe.com.al]</strong>".</li>
							<li><strong>In Firefox:</strong> Click the pop-up icon and choose "Allow pop-ups for <strong>[sistemi.globe.com.al]</strong>".</li>
							<li><strong>In Safari:</strong> Go to Preferences &gt; Websites &gt; Pop-up Windows, and set the pop-up setting for this site to "Allow".</li>
					</ul>
			`;

			// Append the message to the body of the document or a specific container
			document.body.appendChild(errorDiv);

			return;
	}
	printWindow.document.open();
	printWindow.document.write(`
		<!DOCTYPE html>
		<html>
			<head>
				<title>Fatura Tatimore Shitje</title>
				<style>
					body {
						font-family: 'Arial Narrow', Arial, sans-serif;
						font-size: 11px;
					}
					@media print {
							#qrcode {
									margin: 0px;
									text-align: center;
							}
							body {
								width: 100mm; /* Set to your roll width */
								height: 100%; /* Allow the height to adjust based on content */
								page-break-after: avoid;
							}
						* {
								page-break-inside: avoid !important; /* Avoid page breaks inside elements */
								page-break-before: auto;
								page-break-after: auto;
								overflow: visible; /* Ensure content is not cut off */
							}

							@page {
								size: 100mm auto; /* 'auto' lets the height be determined by content */
								margin: 0; /* Remove margins */
							}

							/* Remove white space or gaps by ensuring no automatic page breaks */
								html, body {
									height: auto; /* Ensure full height based on content */
									overflow: visible; /* Make sure all content is visible */
								}

								/* Avoid splitting elements */
								div, p, img {
									page-break-inside: avoid;
									break-inside: avoid-column;
								}
					}
				</style>
			</head>
			<body>
				${thermalInvoiceContent}
			</body>
		</html>
	`);
	printWindow.document.close();

	// Check for manual closure of the window
	let closeCheckInterval = setInterval(() => {
			if (printWindow.closed) {
					clearInterval(closeCheckInterval); // Stop checking once the window is closed
			}
	}, 100);  // Check every 500ms if the window is closed manually
	
	var qr = JSON.parse(useProcessStore().invoice_data.fis_response).response.FISQRCODELINK
	console.log("qr", JSON.parse(useProcessStore().invoice_data.fis_response).response.FISQRCODELINK)
	printWindow.onload = function() {
			// Inject the QRCode.js script
			let script = printWindow.document.createElement('script');
			script.src = 'https://cdnjs.cloudflare.com/ajax/libs/qrcodejs/1.0.0/qrcode.min.js';
			script.onload = function() {
					// Generate the QR code after QRCode.js is loaded
					new printWindow.QRCode(printWindow.document.getElementById("qrcode"), {
							correctLevel : printWindow.QRCode.CorrectLevel.L,
							text: qr,  // Replace with the actual content you want to encode
							width: 100,  // Set the size of the QR code
							height: 100
					});
						
					printWindow.print();
					// Use setTimeout to close the window after printing
					setTimeout(() => {
							printWindow.close();  // Close the window after the print dialog
					}, 500);  // Adjust the timeout as needed based on print dialog timing
			};
			printWindow.document.body.appendChild(script);
	};
});
</script>
