<!-- HelpComponent.vue -->
<template>
  <div class="min-h-screen bg-gray-50 py-8">
    <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
      <!-- Search Bar -->
      <div class="relative mb-8">
        <input 
          v-model="searchQuery"
          type="text"
          class="w-full rounded-lg border-gray-300 pl-10 pr-4 py-2 focus:ring-indigo-500 focus:border-indigo-500"
          placeholder="Kërko ndihmë..."
        />
        <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
          <svg class="h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
            <path fill-rule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clip-rule="evenodd" />
          </svg>
        </div>
      </div>

      <div class="bg-white rounded-lg shadow">
        <div class="p-4 border-b border-gray-200">
          <h1 class="text-2xl font-bold text-gray-900">Qendra e Ndihmës</h1>
        </div>

        <div class="grid grid-cols-12 min-h-[600px]">
          <!-- Sidebar -->
          <div class="col-span-3 border-r border-gray-200">
            <nav class="py-4">
              <div 
                v-for="category in filteredCategories" 
                :key="category.id"
                class="px-4"
              >
                <button
                  @click="selectCategory(category.id)"
                  class="w-full text-left px-3 py-2 rounded-md text-sm font-medium mb-1"
                  :class="{
                    'bg-indigo-50 text-indigo-700': selectedCategory === category.id,
                    'text-gray-900 hover:bg-gray-50': selectedCategory !== category.id
                  }"
                >
                  {{ category.title }}
                </button>
              </div>
            </nav>
          </div>

          <!-- Content Area -->
          <div class="col-span-9 p-6">
            <div v-if="selectedArticle" class="space-y-6">
              <div class="flex items-center justify-between mb-4">
                <button 
                  @click="goBack" 
                  class="inline-flex items-center text-sm text-gray-500 hover:text-gray-700"
                >
                  <svg class="w-5 h-5 mr-1" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 19l-7-7 7-7" />
                  </svg>
                  Kthehu
                </button>
              </div>
              <h2 class="text-xl font-semibold text-gray-900">{{ selectedArticle.title }}</h2>
              <div class="prose max-w-none" v-html="selectedArticle.content"></div>
              
              <!-- Related Articles -->
              <div v-if="selectedArticle.related?.length" class="mt-8 pt-6 border-t border-gray-200">
                <h3 class="text-lg font-medium text-gray-900 mb-4">Artikuj të ngjashëm</h3>
                <ul class="space-y-3">
                  <li v-for="article in selectedArticle.related" :key="article.id">
                    <a 
                      @click="selectArticle(article)"
                      class="text-indigo-600 hover:text-indigo-500 cursor-pointer"
                    >
                      {{ article.title }}
                    </a>
                  </li>
                </ul>
              </div>
            </div>

            <div v-else-if="selectedCategory" class="space-y-6">
              <div 
                v-for="article in currentCategoryArticles" 
                :key="article.id"
                class="p-4 border border-gray-200 rounded-lg hover:border-gray-300 cursor-pointer"
                @click="selectArticle(article)"
              >
                <h3 class="text-lg font-medium text-gray-900 mb-2">{{ article.title }}</h3>
                <p class="text-gray-600">{{ article.excerpt }}</p>
              </div>
            </div>

            <div v-else class="text-center py-12">
              <svg class="mx-auto h-12 w-12 text-gray-400" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M8.228 9c.549-1.165 2.03-2 3.772-2 2.21 0 4 1.343 4 3 0 1.4-1.278 2.575-3.006 2.907-.542.104-.994.54-.994 1.093m0 3h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
              </svg>
              <h3 class="mt-4 text-lg font-medium text-gray-900">Zgjidhni një kategori</h3>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HelpComponent',
  
  data() {
    return {
      searchQuery: '',
      selectedCategory: null,
      selectedArticleId: null,
      helpData: {
        categories: [
          {
            id: 'shitje',
            title: 'Shitjet',
            articles: [
              {
                id: 'porosi-e-re',
                title: 'Si të krijoni një porosi të re',
                excerpt: 'Udhëzues hap pas hapi për krijimin e porosive të reja në sistem',
                content: `
                  <h3>Krijimi i një porosie të re</h3>
                  <p>Për të krijuar një porosi të re, ndiqni këto hapa:</p>
                  <ol>
                    <li>Shkoni tek "Porositë e Reja" në menunë kryesore</li>
                    <li>Zgjidhni klientin ose krijoni një të ri</li>
                    <li>Shtoni produktet në porosi duke përdorur butonin "Shto Produkt"</li>
                    <li>Për çdo produkt, specifikoni:
                      <ul>
                        <li>Sasinë e kërkuar</li>
                        <li>Çmimin (nëse keni të drejta për ta ndryshuar)</li>
                        <li>Zbritjen (nëse aplikohet)</li>
                      </ul>
                    </li>
                    <li>Kontrolloni disponueshmërinë në magazinë</li>
                    <li>Shtoni shërbime shtesë nëse nevojiten (transport, montim)</li>
                    <li>Konfirmoni porosinë</li>
                  </ol>
                `
              },
              {
                id: 'zbritje',
                title: 'Si të aplikoni zbritje',
                excerpt: 'Mësoni si të aplikoni zbritje për produkte dhe porosi',
                content: `
                  <h3>Aplikimi i zbritjeve</h3>
                  <p>Sistemi mundëson aplikimin e zbritjeve në disa nivele:</p>
                  <ul>
                    <li>Zbritje për produkte individuale</li>
                    <li>Zbritje për të gjithë porosinë</li>
                    <li>Zbritje për klientë VIP</li>
                  </ul>
                  <p>Për të aplikuar një zbritje:</p>
                  <ol>
                    <li>Zgjidhni produktin në porosi</li>
                    <li>Klikoni butonin "Apliko Zbritje"</li>
                    <li>Vendosni vlerën e zbritjes (në % ose vlerë fikse)</li>
                    <li>Specifikoni arsyen e zbritjes</li>
                    <li>Kërkoni aprovim nëse zbritja është mbi limitin tuaj</li>
                  </ol>
                `
              }
            ]
          },
          {
            id: 'inventar',
            title: 'Inventari',
            articles: [
              {
                id: 'produkt-i-ri',
                title: 'Si të shtoni një produkt të ri',
                excerpt: 'Udhëzues për shtimin e produkteve të reja në sistem',
                content: `
                  <h3>Shtimi i një produkti të ri</h3>
                  <p>Për të shtuar një produkt të ri në sistem:</p>
                  <ol>
                    <li>Shkoni tek menuja "Produkte"</li>
                    <li>Klikoni "Shto Produkt të Ri"</li>
                    <li>Plotësoni të dhënat e kërkuara:
                      <ul>
                        <li>Kodi i produktit</li>
                        <li>Emërtimi</li>
                        <li>Kategoria</li>
                        <li>Çmimi bazë</li>
                        <li>TVSH-ja</li>
                        <li>Përshkrimi</li>
                      </ul>
                    </li>
                    <li>Zgjidhni magazinën</li>
                    <li>Vendosni sasinë fillestare</li>
                    <li>Ruani produktin</li>
                  </ol>
                `
              },
              {
                id: 'levizje-magazine',
                title: 'Si të bëni lëvizje në magazinë',
                excerpt: 'Udhëzues për transferimin e produkteve midis magazinave',
                content: `
                  <h3>Lëvizjet në magazinë</h3>
                  <p>Për të transferuar produkte midis magazinave:</p>
                  <ol>
                    <li>Shkoni tek "Lëvizje Magazine"</li>
                    <li>Zgjidhni magazinën burimore</li>
                    <li>Zgjidhni magazinën destinacion</li>
                    <li>Shtoni produktet për transferim</li>
                    <li>Specifikoni sasitë</li>
                    <li>Konfirmoni transferimin</li>
                  </ol>
                  <p>Shënim: Sigurohuni që magazina burimore ka sasi të mjaftueshme përpara se të bëni transferimin.</p>
                `
              }
            ]
          },
          {
            id: 'klientet',
            title: 'Klientët',
            articles: [
              {
                id: 'klient-i-ri',
                title: 'Si të regjistroni një klient të ri',
                excerpt: 'Procesi i regjistrimit të klientëve të rinj në sistem',
                content: `
                  <h3>Regjistrimi i klientëve të rinj</h3>
                  <p>Për të regjistruar një klient të ri:</p>
                  <ol>
                    <li>Shkoni tek "Klientët" > "Klient i Ri"</li>
                    <li>Zgjidhni tipin e klientit (Individual/Biznes)</li>
                    <li>Plotësoni të dhënat e kërkuara:
                      <ul>
                        <li>Emër/Mbiemër ose Emër Biznesi</li>
                        <li>NIPT/ID</li>
                        <li>Informacionin e kontaktit</li>
                        <li>Adresën</li>
                      </ul>
                    </li>
                    <li>Shtoni limit kredie nëse nevojitet</li>
                    <li>Ruani të dhënat</li>
                  </ol>
                `
              }
            ]
          },
          {
            id: 'procese',
            title: 'Proceset',
            articles: [
              {
                id: 'proces-pune',
                title: 'Si të ndiqni një proces pune',
                excerpt: 'Udhëzues për ndjekjen e proceseve të punës në sistem',
                content: `
                  <h3>Ndjekja e proceseve të punës</h3>
                  <p>Sistemi përdor procese pune për të menaxhuar fluksin e punës. Për të ndjekur një proces:</p>
                  <ol>
                    <li>Identifikoni procesin e duhur</li>
                    <li>Ndiqni hapat e procesit:
                      <ul>
                        <li>Kontrolloni dokumentacionin e kërkuar</li>
                        <li>Plotësoni të dhënat në çdo hap</li>
                        <li>Merrni aprovimet e nevojshme</li>
                      </ul>
                    </li>
                    <li>Përdorni shënimet për të dokumentuar vendime të rëndësishme</li>
                    <li>Kaloni në hapin tjetër vetëm pasi hapi aktual të jetë kompletuar</li>
                  </ol>
                `
              }
            ]
          }
        ]
      }
    }
  },

  computed: {
    filteredCategories() {
      if (!this.searchQuery) return this.helpData.categories
      
      const query = this.searchQuery.toLowerCase()
      return this.helpData.categories.filter(category => {
        const matchesCategory = category.title.toLowerCase().includes(query)
        const matchesArticles = category.articles.some(article => 
          article.title.toLowerCase().includes(query) ||
          article.excerpt.toLowerCase().includes(query)
        )
        return matchesCategory || matchesArticles
      })
    },

    currentCategoryArticles() {
      if (!this.selectedCategory) return []
      
      const category = this.helpData.categories.find(c => c.id === this.selectedCategory)
      if (!category) return []
      
      if (!this.searchQuery) return category.articles
      
      const query = this.searchQuery.toLowerCase()
      return category.articles.filter(article =>
        article.title.toLowerCase().includes(query) ||
        article.excerpt.toLowerCase().includes(query)
      )
    },

    selectedArticle() {
      if (!this.selectedArticleId) return null
      
      for (const category of this.helpData.categories) {
        const article = category.articles.find(a => a.id === this.selectedArticleId)
        if (article) return article
      }
      return null
    }
  },

  methods: {
    selectCategory(categoryId) {
      this.selectedCategory = categoryId
      this.selectedArticleId = null // Reset article selection when changing category
    },

    selectArticle(article) {
      this.selectedArticleId = article.id
    },

    resetSelection() {
      this.selectedCategory = null
      this.selectedArticleId = null
    },

    searchArticles(query) {
      this.searchQuery = query
      // Only reset article selection when searching
      this.selectedArticleId = null
    },

    goBack() {
      if (this.selectedArticleId) {
        this.selectedArticleId = null // Go back to category view
      } else {
        this.selectedCategory = null // Go back to main view
      }
    }
  }
}
</script>
