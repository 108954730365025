<template>
  <div class="p-2">
    <!-- Search Input -->
    <div v-if="items.length > 3 || first_node" class="mb-4">
      <div class="relative">
        <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
          <i class="fa fa-search text-gray-400"></i>
        </div>
        <input 
          type="text" 
          v-model="search"
          placeholder="Kërko..." 
          class="block w-full pl-10 pr-3 py-2 border border-gray-300 rounded-md text-sm leading-4 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 transition-colors"
        />
      </div>
    </div>

    <!-- Tree Structure -->
    <ul class="list-none p-0 m-0 space-y-1 max-h-[calc(100vh-200px)] overflow-y-auto">
      <li v-for="i of items.filter(item => JSON.stringify(item).toLowerCase().includes(search.toLowerCase()))" 
          :key="i.guid"
          class="relative tree-item">
        <div class="group hover:bg-gray-50 rounded-md transition-colors duration-150 border-b border-gray-100 last:border-b-0">
          <!-- Collapse/Expand Button -->
          <div class="flex items-center py-1.5 px-2">
            <button 
              v-if="Array.isArray(i.children) && i.children.length > 0"
              @click.prevent="collapse(i)"
              class="w-5 h-5 flex items-center justify-center text-gray-500 hover:text-gray-700 focus:outline-none">
              <i class="fa" :class="[i.collapsed ? 'fa-square-plus' : 'fa-square-minus']"></i>
            </button>
						<i v-else class="far fa-circle w-5 h-4 text-gray-400"></i>

            <!-- Item Content -->
            <div v-if="categories" 
                 class="flex-1 flex items-center ml-2 min-h-[28px]"
                 :class="{ 'bg-blue-50 rounded-md': i.guid == categories.current.guid }">
              <!-- Name and Badges -->
              <div class="flex items-center justify-between flex-1">
                <div class="text-xs font-medium text-gray-900 uppercase">{{ i.name }}</div>
                
                <!-- Subcategories Badge -->
                <div v-if="Array.isArray(i.children) && i.children.length > 0" 
                      class="inline-flex items-center px-2 py-0.5 rounded text-xs font-medium bg-green-100 text-green-800"
                      title="Nënkategori">
                  {{i.children?.length}}
                </div>

                <!-- Total Badge -->
                <div v-if="i.total > 0" 
                      class="inline-flex items-center px-2 py-0.5 rounded text-xs font-medium bg-blue-100 text-blue-800"
                      :title="module.title + ' të lidhura'">
                  {{i.total}}
                </div>
              </div>

              <!-- Image -->
              <img v-if="i.image"
                   :src="i.image" 
                   :alt="i.name"
                   class="w-12 h-12 object-cover rounded ml-2" />

              <!-- Action Buttons -->
              <div class="opacity-0 group-hover:opacity-100 transition-opacity duration-200 flex items-center space-x-2 ml-4">
                <button v-if="(levels == -1 || (levels > 1 && (node_has_children && node_has_children.prop && node_has_children.value && i[node_has_children.prop] == node_has_children.value)))"
                        @click.prevent="setCurrent(i, 'add')"
                        class="p-1 text-gray-400 hover:text-green-600 focus:outline-none">
                  <i class="fa fa-plus"></i>
                </button>
                <button @click.prevent="setCurrent(i, 'edit')"
                        class="p-1 text-gray-400 hover:text-blue-600 focus:outline-none">
                  <i class="fa fa-pencil"></i>
                </button>
              </div>
            </div>
          </div>
        </div>

        <!-- Nested Tree -->
        <Transition name="slide-fade">
          <Tree v-if="Array.isArray(i.children) && i.children.length > 0 && !i.collapsed"
                :items="i.children" 
                :flat="false" 
                :levels="levels" 
                :module="module" 
                :first_node="false"
                class="ml-6 mt-1" />
        </Transition>
      </li>
    </ul>
  </div>
</template>

<script>
import { storeToRefs } from 'pinia'
import { useCategoryStore } from '@/stores/category'
import { useAccessoryStore } from '@/stores/accessory'
import CategoriesServices from "@/services/Category";
import AccessoryServices from "@/services/Accessory";
export default {
  name: "Tree",
  props: {
    items : Array,
    flat  : Boolean,
    levels: {
      type     : Number,
      required : false,
      default  : -1
    },
    node_has_children: {
      type     : Object,
      required : false,
      default: () => {
        return null
      }
    },
    module: {
      type     : Object,
      required : false,
      default  : null
    },
    first_node: {
      type: Boolean, 
      required: false,
      default: false
    }
  },
  data() {
    return {
      categories  : storeToRefs(useCategoryStore()),
      accessories : storeToRefs(useAccessoryStore()),
      collapsed   : false,
      search      : ""
    };
  },
  methods: {
    drag(ev, i) {
      ev.dataTransfer.setData("text", JSON.stringify(i));
    },
    allowDrop(ev) {
      ev.preventDefault();
    },
    async drop(ev, i) {
      ev.preventDefault();
      var data = ev.dataTransfer.getData("text");
      data = JSON.parse(data)
      if (data.guid != i.guid) {
        console.log(data, i)
      }
    },
    async collapse(i) {
      this.categories.flat_list = [...this.categories.flat_list.map(e => {
        if (e.guid == i.guid)
          e.collapsed = !e.collapsed
        return e
      })]
      this.categories.list = await CategoriesServices.getTreeCategories(this.categories.flat_list, "id")

      this.accessories.flat_list = [...this.accessories.flat_list.map(aa => {
        if (aa.guid == i.guid)
          aa.collapsed = !aa.collapsed
        return aa
      })]
      this.accessories.list = await AccessoryServices.getTreeAccessories(this.accessories.flat_list, "id")
    },
    setCurrent(c, mode) {
      this.categories.mode = mode
      this.accessories.mode = mode
      this.categories.current = { ...c }
      this.accessories.current = { ...c }
    },
    // creates tree struct
    list_to_tree(list) {
      var map = {}, node, roots = [], i;
      for (i = 0; i < list.length; i += 1) {
        map[list[i].guid] = i; // initialize the map
        list[i].children = []; // initialize the children
      }
      for (i = 0; i < list.length; i += 1) {
        node = list[i];
        if (node.parent_id !== null) {
          // if you have dangling branches check that map[node.parentId] exists
          if (map[node.parent_id])
            list[map[node.parent_id]].children.push(node);
        } else {
          roots.push(node);
        }
      }
      return roots;
    },
    toTree(dataset) {
      const hashTable = Object.create(null);
      dataset.forEach(aData => hashTable[aData.guid] = { ...aData, children: [] });
      const dataTree = [];
      dataset.forEach(aData => {
        if (aData.parent_id) hashTable[aData.parent_id].children.push(hashTable[aData.guid])
        else dataTree.push(hashTable[aData.guid])
      });
      return dataTree;
    },
  },
  computed: {
    list_items() {
      if (this.items) {
        if (this.flat) {
          return this.toTree(this.items, null)
        }
        else
          return this.items
      }
      else return []
    }
  }
};
</script>
<style scoped>
/* Tree Line Styles */
.tree-item {
  position: relative;
}

.tree-item::before {
  content: '';
  position: absolute;
  width: 1px;
  height: 100%;
  background-color: rgb(229 231 235);
  left: -12px;
  top: 0;
}

.tree-item:last-child::before {
  height: 16px;
}

.tree-item::after {
  content: '';
  position: absolute;
  width: 12px;
  height: 1px;
  background-color: rgb(229 231 235);
  left: -12px;
  top: 16px;
}

/* Animations */
.slide-fade-enter-active {
  transition: all 0.2s ease-out;
}

.slide-fade-leave-active {
  transition: all 0.2s ease-in;
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateY(-8px);
  opacity: 0;
}

/* Custom scrollbar styles */
ul::-webkit-scrollbar {
  width: 6px;
}

ul::-webkit-scrollbar-track {
  background: transparent;
}

ul::-webkit-scrollbar-thumb {
  background-color: rgb(209 213 219);
  border-radius: 9999px;
}

ul::-webkit-scrollbar-thumb:hover {
  background-color: rgb(156 163 175);
}

/* Firefox */
ul {
  scrollbar-width: thin;
  scrollbar-color: rgb(209 213 219) transparent;
}</style>
