# CreditNoteManager.vue
<template>
  <div class="bg-gray-50 min-h-screen p-6">
    <!-- Header Section -->
    <div class="bg-white rounded-lg shadow-sm p-4 mb-6">
      <div class="flex justify-between items-center">
        <div class="flex items-center space-x-4">
          <h2 class="text-xl font-bold text-gray-800">Mandate arketimi</h2>
          <div class="relative">
            <input
              v-model="useCreditnoteStore().search_creditnote_val"
              @input="handleSearchInput"
              type="text"
              placeholder="Kerko..."
              class="pl-10 pr-4 py-2 border border-gray-200 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent outline-none w-72 text-sm"
            />
            <span class="absolute left-3 top-2.5 text-gray-400">
              <i class="fa-solid fa-search"></i>
            </span>
          </div>
        </div>
        <button
          @click="useCreditnoteStore().toggle_create_new()"
          class="bg-emerald-500 hover:bg-emerald-600 text-white px-4 py-2 rounded-lg transition-colors duration-200 flex items-center space-x-2"
        >
          <i class="fa-solid fa-plus"></i>
          <span>Krijo mandat</span>
        </button>
      </div>
    </div>

    <!-- Table Section -->
    <div class="bg-white rounded-lg shadow-sm overflow-auto">
      <table class="min-w-full divide-y divide-gray-200">
        <thead class="bg-gray-50">
          <tr>
            <th class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              Tipi i mandatit
            </th>
            <th class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              Klienti
            </th>
            <th class="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
              Vlera
            </th>
            <th class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              Krijuar nga
            </th>
            <th class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              Krijuar me
            </th>
            <th class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              Koment
            </th>
            <th class="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
              Veprime
            </th>
          </tr>
        </thead>
        <tbody class="bg-white divide-y divide-gray-200">
          <tr v-for="(creditnote, index) in slicedCreditnotes" 
              :key="index"
              class="hover:bg-gray-50 transition-colors duration-150">
            <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
              {{ creditnote.type_name }}
            </td>
            <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
              {{ creditnote.customer_name }}
            </td>
            <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-900 text-right">
              {{ useDiaryStore().price_value_filter(creditnote.amount) }}
            </td>
            <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
              {{ creditnote.created_by_name }}
            </td>
            <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
              {{ useDiaryStore().formatDate(creditnote.created_at) }}
            </td>
            <td class="px-6 py-4 text-sm text-gray-900">
              {{ creditnote.comment }}
            </td>
            <td class="px-6 py-4 whitespace-nowrap text-right text-sm">
              <button class="text-blue-600 hover:text-blue-800">
                <i class="fa-solid fa-ellipsis-vertical"></i>
              </button>
            </td>
          </tr>
        </tbody>
      </table>

      <!-- Pagination -->
      <div class="bg-white px-4 py-3 flex items-center justify-between border-t border-gray-200 sm:px-6">
        <div class="flex justify-between items-center w-full">
          <div class="text-sm text-gray-700">
            Faqe {{ currentPage }} nga {{ totalPages }}
          </div>
          <div class="flex space-x-2">
            <button
              @click="previousPage"
              :disabled="currentPage === 1"
              class="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 disabled:opacity-50 disabled:cursor-not-allowed"
            >
              <i class="fa-solid fa-chevron-left mr-2"></i>
              E meparshme
            </button>
            <button
              @click="nextPage"
              :disabled="currentPage === totalPages"
              class="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 disabled:opacity-50 disabled:cursor-not-allowed"
            >
              Tjetra
              <i class="fa-solid fa-chevron-right ml-2"></i>
            </button>
          </div>
        </div>
      </div>
    </div>

    <!-- Create New Modal -->
    <div v-if="useCreditnoteStore().show_create_creditnote"
         class="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div class="bg-white rounded-lg shadow-xl w-2/3 max-h-[80vh] overflow-hidden">
        <!-- Modal Header -->
        <div class="px-6 py-4 border-b border-gray-200">
          <h3 class="text-lg font-semibold text-gray-900">Krijo mandat te ri</h3>
        </div>

        <!-- Modal Body -->
        <div class="px-6 py-4 max-h-[60vh] overflow-y-auto">
          <div class="grid grid-cols-3 gap-6 mb-6">
            <!-- Customer Selection -->
            <div class="relative">
              <label class="block text-sm font-medium text-gray-700 mb-2">Klienti</label>
              <div v-if="!useCreditnoteStore().new_creditnote.customer?.guid"
                   class="relative">
                <input
                  v-model="useCreditnoteStore().search_customer_val"
                  class="w-full px-3 py-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                  type="text"
                  placeholder="Kerko klient"
                />
                <div v-if="useCreditnoteStore().search_customer_val.length > 0"
                     class="absolute z-10 mt-1 w-full bg-white rounded-md shadow-lg max-h-60 overflow-auto">
                  <div v-for="(customer, index) in useCreditnoteStore().filtered_customers"
                       :key="index"
                       @click="useCreditnoteStore().select_customer(customer)"
                       class="px-4 py-2 hover:bg-gray-100 cursor-pointer">
                    {{ customer.name }}
                  </div>
                </div>
              </div>
              <div v-else
                   class="flex justify-between items-center px-3 py-2 bg-gray-50 rounded-md">
                <span class="flex items-center">
                  <i class="fa-solid fa-user mr-2"></i>
                  {{ useCreditnoteStore().new_creditnote.customer?.name }}
                </span>
                <button @click="useCreditnoteStore().new_creditnote.customer = {}"
                        class="text-red-500 hover:text-red-700">
                  <i class="fa-solid fa-xmark"></i>
                </button>
              </div>
            </div>

            <!-- Amount Input -->
            <div>
              <label class="block text-sm font-medium text-gray-700 mb-2">Vlera e mandatit</label>
              <input
                v-model="useCreditnoteStore().new_creditnote.amount"
                type="number"
                placeholder="0.00"
                class="w-full px-3 py-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-500 focus:border-transparent text-right"
              />
            </div>

            <!-- Type Selection -->
            <div>
              <label class="block text-sm font-medium text-gray-700 mb-2">Tipi i mandatit</label>
              <select
                v-model="useCreditnoteStore().new_creditnote.creditnote_type"
                class="w-full px-3 py-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-500 focus:border-transparent"
              >
                <option v-for="(type, index) in useCreditnoteStore().creditnote_types"
                        :key="index"
                        :value="type.id">
                  {{ type.name }}
                </option>
              </select>
            </div>
          </div>

          <!-- Comment Section -->
          <div class="mb-6">
            <label class="block text-sm font-medium text-gray-700 mb-2">Koment / shenime</label>
            <textarea
              v-model="useCreditnoteStore().new_creditnote.comment"
              rows="3"
              class="w-full px-3 py-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-500 focus:border-transparent"
            ></textarea>
          </div>
        </div>

        <!-- Modal Footer -->
        <div class="px-6 py-4 bg-gray-50 border-t border-gray-200 flex justify-end space-x-4">
          <button
            @click="useCreditnoteStore().show_create_creditnote = false"
            class="px-4 py-2 border border-gray-300 rounded-md text-gray-700 hover:bg-gray-50"
          >
            Anulo
          </button>
          <button
            v-if="useCreditnoteStore().can_create_creditnote"
            @click="start_create_new_creditnote"
            class="px-4 py-2 bg-emerald-500 text-white rounded-md hover:bg-emerald-600"
          >
            Krijo
          </button>
          <button
            v-else
            class="px-4 py-2 bg-emerald-500 text-white rounded-md opacity-50 cursor-not-allowed"
          >
            Krijo
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { useCreditnoteStore } from "@/stores/creditnote";
import { useDiaryStore } from "@/stores/diary";
</script>

<script>
export default {
  data() {
    return {
      currentPage: 1,
      itemsPerPage: 10,
    };
  },
  methods: {
    handleSearchInput() {
      this.currentPage = 1;
    },
    previousPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
      }
    },
    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
      }
    },
    async start_create_new_creditnote() {
      const response = await useCreditnoteStore().create_new_creditnote();
      if (response.message === 'success') {
        this.$toast.success('Mandati u krijua me sukses!');
        useCreditnoteStore().show_create_creditnote = false;
      }
    }
  },
  computed: {
    totalPages() {
      return Math.ceil(
        useCreditnoteStore().filtered_creditnotes.length / this.itemsPerPage
      );
    },
    slicedCreditnotes() {
      const start = (this.currentPage - 1) * this.itemsPerPage;
      const end = start + this.itemsPerPage;
      const filteredCreditnotes = useCreditnoteStore().filtered_creditnotes || [];
      return filteredCreditnotes.slice(start, end);
    },
  },
  watch: {
    "useCreditnoteStore().filtered_creditnotes": "handleSearchInput",
  },
};
</script>
