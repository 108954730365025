import { defineStore } from 'pinia'
import Api from "@/services/Api";
import { useProcessStore } from '@/stores/process'
  var Emitter = require('tiny-emitter/instance');
//   /var emitter = new Emitter();/ 

export const useSearchProcess = defineStore({
    id: 'search_process',
    state: () => ({
        show_search_input: false,
        show_search_results: false,
        search_val: '',
        search_results: [],
        search_loading: false,
        no_search_results: false,
        
        translation: {
            'products': 'Produkte',
            'accessories': 'Aksesore'
        },
    }),
    getters: {
        grouped_search_results(){
            let list = {
                products: [],
                accessories: []
            }

            this.search_results.map(s => {
                if(s.is_accessory == 0) list['products'].push(s)
                else if(s.is_accessory == 1) list['accessories'].push(s)
            })
            return list
        },
        alowed_to_search() {
            var a = {value: true}
            if (useProcessStore().process.origin_type == 1 && (!useProcessStore().process.origin)) 
                a = {
                    value: false,
                    message: 'Zgjidh nje POS perpara se te kerkosh nje produkt.'
                }
            else if(useProcessStore().process.destination_type == 2 && !useProcessStore().process.destination)
                a = {
                    value: false,
                    message: 'Zgjidh nje Magazinen perpara se te kerkosh nje produkt.'
                }
            else 
                a = {value: true}
            
            return a
        }
    }, 
    actions: {
        toggle_search_input(){
            this.show_search_input = !this.show_search_input
        },
        async search_product() {
            if (!this.search_loading) {
                
                try {
                    // handle success here
                    await Api(true).post("/processes/search/product", {
                        pos_list: useProcessStore().assigned_pos_list, 
                        search: this.search_val,
                        process: useProcessStore().process,
                        step: useProcessStore().current_step
                    }).then(res => {
                        if (res.data.length >= 1) {
                            for (var i = 0; i < res.data.length; i++) {
                                if (res.data[i].barcode == this.search_val) {
                                    // Add product from barcode
                                    useProcessStore().add_product_to_list({ ...res.data[i], amount: 1 })
                                        .then(r => {
                                            // Backup for refresh - Working
                                            if (useProcessStore().is_new)
                                                window.location.href = '/process/document/' + useProcessStore().process.guid + '/' + r.data.document
                                            else {
                                                useProcessStore().current_product = r.data.new_item
                                                //close search results
                                                this.show_search_results = false
                                                this.search_val = ''
                                                // Get all document items
                                                useProcessStore().get_process_document(r.data.document)
                                                    .then(() => {
                                                        Emitter.emit('put_focus', 'AAA')// r.data.new_item);
                                                    })

                                                    setTimeout(() => {
                                                        console.log(useProcessStore().product_list)
                                                        for(var i = 0; i < useProcessStore().product_list.length; i++) {
                                                            console.log(useProcessStore().product_list[i].serial_number)
                                                            if(!useProcessStore().product_list[i].serial_number){
                                                            document.getElementById('serial_input_' + useProcessStore().product_list[i].detail_id).focus()
                                                            console.log("set focus "+ 'serial_input_' + useProcessStore().product_list[i].detail_id)
                                                            }
                                                        }
                                                        // 'serial_input_' + product.detail_id
                                                        }, 500)
                                                    // Toaster
                                                    // toast.show('Produkti u shtua me sukses.', { ...{ type: 'success', duration: 4000 } });
                                                }
                                            })
                                        return
                                    }
                                }
                            }
                            this.show_search_results = true
                            this.search_loading = true
                        this.no_search_results = false
                        this.search_results = []
                        if(res.data.length > 0){
                            res.data.map(sr => {
                                
                                sr.expanded = false
                                sr.expanded_loading = false
                                sr.extra_services = []
                                sr.extra_accessories = []
                                sr.extra_exclusive_accessories = []
                                sr.amount = 1
                                sr.show_exclusive_dropdown = false
        
                                this.search_results.push(sr)
                            })
                        }
                        else if (res.data.length == 0) {
                            this.no_search_results = true
                        }
                    })
                } catch (error) {
                    // handle error here
                    console.log(error);
                    this.search_loading = false
                }
                
                this.search_loading = false
                
            }
        },
        async search_product_diary(process) {
            if (!this.search_loading) {
                try {
                    // handle success here
                    await Api(true).post("/processes/search/product", {
                        search: this.search_val,
                        process: process,
                    }).then(res => {
                        this.search_results = []
                        if(res.data.length > 0){
                            res.data.map(sr => {
                                sr.is_active = true
                                // if(sr.accesories?.length > 0){
                                //     sr.accesories = sr.accesories.map(aa => {
                                //         aa.is_active = true
                                //         return aa
                                //     })
                                // }
                                this.search_results.push(sr)
                            })
                        }
                        else if (res.data.length == 0) {
                            this.no_search_results = true
                        }
                    })
                } catch (error) {
                    // handle error here
                    console.log(error);
                    this.search_loading = false
                }
                
                this.search_loading = false
                
            }
        },

        toggle_search_results(){
            this.show_search_results = false
        },
        async toggle_expand_result(r) {
            var is_expanded = false
            var got_extras = false

            // Turn on expanded loading
            this.search_results = this.search_results.map(sr => { 
                if (sr.guid == r.guid) { 
                    sr.expanded = !sr.expanded
                    sr.expanded_loading = true
                    is_expanded = sr.expanded
                    sr.show_exclusive_dropdown = false
                    if(sr.got_extras) got_extras = true
                }
                return sr
            })

            // Get expanded data
            if ( is_expanded && !got_extras) {
                await Api(true).post("/search/expanded/data", {
                    product: r
                }).then(res => {
                    // console.log('..', res.data.guid)
                    this.search_results = [...this.search_results.map(sr => {
                        if(sr.guid == res.data.guid){
                            sr.accessories = res.data.accessories
                            sr.categories = res.data.categories
                            sr.attributes = res.data.attributes
                            sr.services = res.data.services
                            sr.got_extras = true
                        }
                        return sr
                    })]
                })
            }

            // Turn off expanded loading
            this.search_results = this.search_results.map(sr => { 
                if (sr.guid == r.guid) { 
                    sr.expanded_loading = false
                }
                return sr
            })
            
        },
        add_extra(result, data, entity) {
            this.search_results = [...this.search_results.map(sr => {
                // console.log('sr', sr)
                if(sr.guid == result.guid) {
                    if (entity == 'services') {
                        var service_found = false
                        var ss = {}
                        sr.extra_services.map(es => {
                            if (es.guid == data.guid) {
                                service_found = true
                                ss = es
                                sr.services = sr.services.map(a => {
                                    if (a.guid == es.guid) {
                                        a.is_checked = 0
                                    }
                                    return a
                                })
                            }
                        })
                        sr.extra_services = sr.extra_services.filter(a => a.guid != ss.guid)
                        if (!service_found) {
                            sr.extra_services.push(data)
                        }
                        sr.services = sr.services.map(a => {
                            sr.extra_services.map(b => {
                                if (a.guid == b.guid) {
                                    a.is_checked = 1
                                }
                            })
                            return a
                        })
                    }
                    else if (entity == 'accessories') {
                        var acc_found = false
                        var aa = {} 
                        // if found - remove
                        sr.extra_accessories.map(ea => {
                            if (ea.guid == data.guid) {
                                acc_found = true
                                aa = ea
                                sr.accessories = sr.accessories.map(a => {
                                    if (a.guid == ea.guid) {
                                        a.is_checked = 0
                                    }
                                    return a
                                })
                            }
                        })
                        sr.extra_accessories = sr.extra_accessories.filter(a => a.guid != aa.guid)

                        // If not found - add to 
                        if (!acc_found) {
                            sr.extra_accessories.push(data)
                        }
                        sr.accessories = sr.accessories.map(a => {
                            sr.extra_accessories.map(b => {
                                if (a.guid == b.guid) {
                                    a.is_checked = 1
                                }
                            })
                            return a
                        })
                    }
                    else if(entity == "exclusive_accessories") {
                        var exc_acc_found = false
                        var eea = {}

                        sr.extra_exclusive_accessories.map(ea => {
                            if(ea.guid == data.guid) {
                                exc_acc_found = true
                                eea = ea
                                useProcessStore().product_exclusive_accessories = useProcessStore().product_exclusive_accessories.map(pea => {
                                    if(pea.guid == ea.guid) {
                                        pea.is_checked = 0
                                    }
                                    return pea
                                })
                            }
                        })
                        sr.extra_exclusive_accessories = 
                        sr.extra_exclusive_accessories.filter(a => a.guid != eea.guid)

                        if(!exc_acc_found) {
                            sr.extra_exclusive_accessories.push(data)
                        }
                        useProcessStore().product_exclusive_accessories = 
                        useProcessStore().product_exclusive_accessories.map(a => {
                            sr.extra_exclusive_accessories.map(b => {
                                if(a.guid == b.guid) {
                                    a.is_checked = 1
                                }
                            })
                            return a
                        })
                    }
                }
                return sr
            })]
        },
    }
})
