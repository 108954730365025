import helpers from "@/helpers.js";
import CategoriesServices from "@/services/Category";
// import AccessoriesServices from "@/services/Accessory";
import ProductsServices from "@/services/Product";
import ServiceServices from "@/services/Service";
import ConfigurationService from "@/services/Configuration";
import PointsOfSaleServices from "@/services/PointOfSale";

export default async function createProduct() {
  var product_categories = await CategoriesServices.getCategories("products");
  var product_accessories = await CategoriesServices.getCategories(
    "accessories"
  );
  var details_types = helpers.toTree(product_categories, "id");
  var accessories = helpers.toTree(product_accessories, "id");
  var unit = await ProductsServices.getUnits();
  var brand = await ProductsServices.getBrands();
  var warranties = await ProductsServices.getWarranties();
  var vat = await ProductsServices.getVat();
  var services = await ServiceServices.getServices();
  // var services_categories = await CategoriesServices.getServicesCategories();

  // var services_list = []
  // services_categories.map(c => {
  //   services.map(s => {

  //   })
  // })

  // Managers
  var managers = await ProductsServices.getManagers()

  // Energy_efficiency
  var energy_efficiency = await ConfigurationService.getEnergyEfficiency()
  energy_efficiency = energy_efficiency.map(r => {
    r.label = r.name
    r.id = r.guid
    return r
  })

  // Country list
  var country_list = [
    { id: 'CA', label: 'Kanada' },
    { id: 'CN', label: 'Kina' },
    { id: 'DE', label: 'Gjermani' },
    { id: 'FR', label: 'Francë' },
    { id: 'IN', label: 'Indi' },
    { id: 'IT', label: 'Itali' },
    { id: 'JP', label: 'Japoni' },
    { id: 'KR', label: 'Kore' },
    { id: 'MX', label: 'Meksikë' },
    { id: 'TR', label: 'Turqi' },
    { id: 'US', label: 'Shtetet e Bashkuara' },
    { id: 'VN', label: 'Vietnam' }
  ]

  // Product - Pos
  var pos_list = await PointsOfSaleServices.getPointsOfSale()
  pos_list = [...helpers.toTree(pos_list, "id")]

  services.unshift(
    { label: "Asnje sherbim", id: null, type: 1 },
    { label: "Asnje sherbim", id: null, type: 2 }
  );

  return {
    name: "Krijo Produkt",
    submit: {
      label: "Krijo",
      action: "submitProduct",
    },
    fields: [
      [
        {
          value: "",
          name: "is_recipe",
          label: "I perbere",
          style: "min-width: 2%; display:none;",
          type: "checkbox-field",
        },
        {
          value: "",
          name: "is_accessory",
          label: "Aksesor",
          type: "checkbox-field",
          style: "min-width: 2%",
        },
        {
          value: "",
          name: "stock_limit",
          label: "Limiti i stokut",
          type: "number-field",
          style: "min-width: 15%",
        },
      ],
      [
        {
          value: "",
          name: "code",
          label: "Kodi",
          type: "text-field",
          style: "min-width: 24%",
          required: true,
          required_text: "Kjo fushe eshte e detyrueshme",
          unique: {
            model: "product",
            field: "code",
            where: [],
          },
          left_icon: "fa-solid fa-hashtag",
        },
        {
          value: "",
          name: "name",
          label: "Emri",
          type: "text-field",
          style: "min-width: 45%",
          required: true,
          required_text: "Kjo fushe eshte e detyrueshme",
        },
        {
          value: "",
          name: "model",
          label: "Modeli",
          type: "text-field",
          style: "min-width: 29%",
        },
      ],
      [
        {
          name: "type_id",
          label: "Kategoria",
          type: "tree-select",
          style: "min-width: 100%",
          options: details_types,
          selected_options: [],
          mode: "single",
          vif: [{ field: "is_accessory", value_not: 1 }],
        },
      ],
      [
        {
          name: "accessory_id",
          label: "Kategoria",
          type: "tree-select",
          style: "min-width: 100%",
          options: accessories,
          selected_options: [],
          mode: "single",
          vif: [{ field: "is_accessory", value_not: 0 }],
        },
      ],
      [
        {
          name: "unit",
          label: "Njesia",
          type: "select-field",
          style: "min-width: 24%",
          options: unit,
          selected_options: [],
          required: true,
        },
        {
          value: "",
          name: "description",
          label: "Pershkrimi",
          type: "text-field",
          style: "min-width: 45%",
          required: true,
          required_text: "Kjo fushe eshte e detyrueshme",
        },
        {
          value: "",
          name: "warranty_id",
          label: "Garancia",
          type: "select-field",
          options: warranties,
          style: "min-width: 29%",
          required: true,
          required_text: "Kjo fushe eshte e detyrueshme",
        },
      ],
      [
        {
          name: "brand",
          label: "Marka",
          type: "select-field",
          style: "min-width: 24%",
          options: brand,
          selected_options: [],
          required: true,
        },
        {
          name: "price",
          label: "Cmimi",
          type: "number-field",
          style: "min-width: 45%",
          required: true,
        },
        {
          name: "vat",
          label: "TVSH(%)",
          type: "select-field",
          style: "min-width: 29%",
          options: vat,
        },
      ],
      [
        {
          name: "default_price",
          type: "number-field",
          label: "Cmimi baze",
          style: "min-width: 100%",
          required: true,
        },
      ],

      [
        {
          name: "known_as",
          label: "Emri I njohur universal",
          type: "text-field",
          style: "min-width: 24%",
          required: false,
        },
        {
          name: "barcode",
          label: "Barkodi",
          type: "text-field",
          style: "min-width: 45%",
          required: false,
        },
        {
          name: "origin",
          type: "select-field",
          label: "Origjina e produktit",
          style: "min-width: 29%",
          options: country_list,
          required: false,
        }
      ],
      [
        {
          name: "status",
          label: "Statusi",
          type: "select-field",
          style: "min-width: 24%",
          options: [
            { id: 1, label: 'Aktiv' },
            { id: 0, label: 'Jo Aktiv' }
          ],
          required: false,
        },
        {
          name: "manager",
          label: "Menaxher",
          type: "select-field",
          style: "min-width: 45%",
          options: managers,
          required: false,
        },
        // {
        //   name: "suplier_category",
        //   label: "Kategoria e furnitorit",
        //   type: "select-field",
        //   style: "min-width: 24%",
        //   options: [], // Suplier category list
        //   required: false,
        // },
        {
          name: "energy_efficiency",
          label: "Klasi i energjise",
          type: "select-field",
          style: "min-width: 29%",
          options: energy_efficiency, // Category eficency
          required: false,
        }
      ],
      [
        {
          name: "packaging",
          label: "Paketimi",
          type: "select-field",
          style: "min-width: 24%",
          options: [
            {id: 0, label: 'Paketim i thjeshte'},
            {id: 1, label: 'Kuti me 10 cope'},
            {id: 2, label: 'Kuti me 20 cope'}
          ], // Category eficency
          required: false,
        },
        {
          name: "has_airbag",
          label: "Ka garanci Airbag",
          style: "min-width: 2%;  margin-top:5px;",
          type: "checkbox-field",
        },
        {
          name: "in_web",
          label: "Shitet ne web",
          style: "min-width: 2%;  margin-top:5px;",
          type: "checkbox-field",
        },
      ],
      [
        {
          name: "warranty_airbag",
          label: "Garanci Airbag",
          style: "min-width: 24%",
          type: "select-field",
          options: [
            { id: 0, label: 'Nuk ka garanci' },
            { id: 3, label: '3 muaj' },
            { id: 6, label: '6 muaj' },
            { id: 12, label: '12 muaj' },
            { id: 24, label: '24 muaj' },
          ], // Category eficency
        },
        {
          name: "warranty_airbag_value",
          type: "number-field",
          label: "Cmimi i airbag",
          style: "min-width: 45%",
          // vif: [{ field: "warranty_airbag" }],
          vif: [{ field: "warranty_airbag", value_not: 0 }],
        }
      ],
      [
        {
            name: 'products_pos', 
            label: 'Poset ku mund te tregtohet ky produkt', 
            type: 'tree-select', 
            style: 'width: 100%',
            options: pos_list,
            selected_options: [],
            mode: 'multiple',
            required: false
        },
      ],
      [
        {
          data: [],
          name: "attributes",
          label: "Attribute",
          type: "attributes",
          options: [],
          style: "min-width: 100%",
          depends_on: "type_id",
          api_call: "category/attributes",

          vif: [{ field: "is_accessory", value_not: 1 }],
        },
        {
          data: [],
          name: "attributes_accessories",
          label: "Attribute",
          type: "attributes",
          options: [],
          style: "min-width: 100%",
          depends_on: "accessory_id",
          api_call: "category/attributes",

          vif: [{ field: "is_accessory", value_not: 0 }],
        },
      ],
      [
        {
          data: [],
          name: "services",
          label: "Sherbime",
          type: "attributes",
          options: [],
          style: "min-width: 100%",
          depends_on: "type_id",
          api_call: "category/services",

          // vif: [
          //   { field: "is_accessory", value_not: 0 }
          // ]
        },
      ],
    ],
  };
}
