<template>
  <div>
    <div class="is-flex is-justify-content-space-between activities-page-wrap">
      <div class="mt-3 w-1/2 mr-3 h-full ">
        <div class=" is-flex mb-2">
          <span class="subtitle mb-0"> Fletet e punes </span>
          <div class="ml-auto is-flex">
            <a @click.prevent="useJobStore().create_empty_job()" href="" class="button is-small">
              <i class="fa-solid fa-plus"></i>
            </a>
            <div v-if="show_jobs_search" class="is-flex ml-2">
              <div class="field has-addons">
                <div class="control">
                  <input v-model="jobs_search_val" class="input is-small" type="text" placeholder="Kerko flete pune">
                </div>
                <div class="control">
                  <a class="button is-small">
                    <i class="fa-solid fa-magnifying-glass"></i>
                  </a>
                </div>
              </div>
              <!-- <button class="delete is-small ml-1"></button> -->
            </div>

            <a @click.prevent="toggle_jobs_search(show_jobs_search)" href="" class="button is-small ml-2 mr-1">
              <i v-if="show_jobs_search" class="fa-solid fa-xmark"></i>
              <i v-else class="fa-solid fa-magnifying-glass"></i>
            </a>

            <a @click.prevent="toggle_jobs_calendar_search(show_jobs_calendar_search)" href="" class="button is-small ml-2 mr-1">
              <i class="fa-regular fa-calendar-days"></i>
            </a>
          </div>
        </div>
        <div v-if="show_jobs_calendar_search" class="is-flex bg-sky-50 border border-sky-200 p-2 mb-2">
          <div class="ml-auto">
            <p class="text-xs">Krijuar/modifikuar nga</p>
            <!-- <input class="input is-small" type="text" placeholder="Text input"> -->
            <div class="control">
              <div class="select is-small">
                <select v-model="job_user_search">
                  <option :value="null">Te gjithe</option>
                  <option v-for="user in useJobStore().users_list" :key="user" 
                    :value="user.id">{{user.name}}</option>
                </select>
              </div>
            </div>
          </div>
          <div class="ml-2">
            <p class="text-xs"> Kerko datat </p>
                <!-- v-model="doc_serv.new_requested_for" -->
                <!-- :format="'dd/MM/yyyy HH:mm'" -->
                <Datepicker 
                  v-model="search_job_daterange" 
                  range 
                  @update:modelValue="update_job_date_range(search_job_daterange)"
                  :start-time="startTime"
                  :format="'dd/MM/yyyy HH:mm'"
                  class="job-datepicket-search-wrap" />
          </div>
        </div>
        <div class="h-full job-list-wrap px-1 ">

          <!-- <div class="w-full border mb-1 is-flex"> Header...  </div> -->

          <div class="is-flex w-full bg-gradient-to-r from-slate-50 to-slate-200 mb-2">
            <div style="width:38px;"></div>
            <div class="is-flex is-flex-grow-1">
              <div class="w-1/3 pl-2"></div>
              <div class="w-1/3 pl-2">Krijuar</div>
              <div class="w-1/3 pl-2">Modifikuar</div>
            </div>
            <div class="w-1/12"></div>
          </div>

  <div v-for="job in filtered_jobs" :key="job.guid" 
       class="w-full mb-4 rounded-lg shadow-sm border border-gray-200 overflow-hidden"
       :class="[
         job.collapsed && useJobStore().selected_job.guid == job.guid ? 'bg-blue-50' :
         job.collapsed ? 'bg-white' :
         useJobStore().selected_job.guid == job.guid ? 'bg-blue-50' : ''
       ]">
    
    <!-- Header Section -->
    <div class="flex">
      <!-- Selection Checkbox -->
      <div @click="useJobStore().start_select_multiple(job)"
           class="flex items-center px-4 cursor-pointer hover:bg-gray-50 border-r"
           :class="[job.guid == useJobStore().selected_job.guid ? 'bg-blue-50' : 'bg-white']">
        <div class="text-blue-500">
          <i v-if="job.guid == useJobStore().selected_job.guid" 
             class="fa-regular fa-square-check text-lg"></i>
          <i v-else class="fa-regular fa-square text-lg"></i>
        </div>
      </div>

      <!-- Main Content -->
      <div class="flex-grow">
        <!-- Top Row -->
        <div class="grid grid-cols-3 border-b">
          <div class="p-3 border-r">
            <div class="flex items-center space-x-2">
              <i class="fa-solid fa-xs fa-circle"
                 :class="[
                   job.status_id == 1 ? 'text-green-500' :
                   job.status_id == 2 ? 'text-amber-500' :
                   job.status_id == 3 ? 'text-red-500' : ''
                 ]"></i>
              <span class="font-medium">{{job.id}}</span>
            </div>
          </div>
          <div class="p-3 border-r">
            <div class="flex items-center space-x-2">
              <i class="fa-solid fa-xs fa-user text-gray-500"></i>
              <span>{{job.user_name.toUpperCase()}}</span>
            </div>
          </div>
          <div class="p-3">
            <div v-if="job.updated_by_name" class="flex items-center space-x-2">
              <i class="fa-solid fa-xs fa-user text-gray-500"></i>
              <span>{{job.updated_by_name}}</span>
            </div>
          </div>
        </div>

        <!-- Bottom Row -->
        <div class="grid grid-cols-3 text-sm text-gray-600">
          <div class="p-3 border-r">
            <div class="flex items-center space-x-2">
              <i class="fa-solid fa-calendar-days"></i>
              <span>{{job.min_date}} - {{job.max_date}}</span>
            </div>
          </div>
          <div class="p-3 border-r">
            <div class="flex items-center space-x-2">
              <i class="fa-solid fa-calendar-days"></i>
              <span>{{job.created_at}}</span>
            </div>
          </div>
          <div v-if="job.updated_at" class="p-3">
            <div class="flex items-center space-x-2">
              <i class="fa-solid fa-calendar-days"></i>
              <span>{{job.updated_at}}</span>
            </div>
          </div>
        </div>
      </div>

      <!-- Action Buttons -->
      <div class="flex items-center px-4 space-x-4 border-l bg-gray-50">
        <button @click="open_print_job_modal_start(job)" 
                class="text-blue-500 hover:text-blue-600">
          <i class="fa-solid fa-print"></i>
        </button>
        <button class="text-orange-500">
          <i class="fa-solid fa-clipboard-check"></i>
        </button>
        <button @click="useJobStore().collapse_job(job)"
                class="text-blue-500 hover:text-blue-600">
          <i :class="[job.collapsed ? 'fa-solid fa-chevron-up' : 'fa-solid fa-chevron-down']"></i>
        </button>
      </div>
    </div>

    <!-- Expanded Content -->
    <div v-if="job.collapsed" class="p-4 bg-white border-t">
      <!-- Controls Section -->
      <div class="flex items-start space-x-4 p-4 bg-gray-50 rounded-lg mb-4">
        <div class="flex-grow">
          <label class="block text-sm font-medium text-gray-700 mb-1">Pergjegjesi</label>
          <select v-model="job.assigned_to" 
                  @change="start_assign_job_user(job)"
                  class="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm rounded-md">
            <option :value="0">Zgjidh Pergjegjes</option>
            <option v-for="user in useJobStore().users_list" 
                    :key="user" 
                    :value="user.id">{{user.name}}</option>
          </select>
        </div>

        <div v-if="job.document_services && job.document_services.length > 0" 
             class="flex space-x-4">
          <div>
            <label class="block text-sm font-medium text-gray-700 mb-1">Tipi</label>
            <select class="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm rounded-md">
              <option>Kerko tipin e sherbimeve</option>
            </select>
          </div>
          <div>
            <label class="block text-sm font-medium text-gray-700 mb-1">Kerko</label>
            <input type="text" 
                   placeholder="Kerko sherbim"
                   class="mt-1 focus:ring-blue-500 focus:border-blue-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md">
          </div>
        </div>
      </div>

      <!-- Loading State -->
      <div v-if="job.collapse_loading" class="text-center py-4">
        <i class="fa fa-spinner fa-spin text-gray-400 text-2xl"></i>
      </div>

      <!-- Services Table -->
      <table v-else-if="job.document_services && job.document_services.length > 0" 
             class="min-w-full divide-y divide-gray-200">
        <thead class="bg-gray-50">
          <tr>
            <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">#</th>
            <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Sherbimi</th>
            <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Krijuar</th>
            <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Kerkuar per date</th>
            <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Nga</th>
            <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Tek</th>
            <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Shenime</th>
            <th scope="col" class="relative px-6 py-3">
              <span class="sr-only">Actions</span>
            </th>
          </tr>
        </thead>
        <tbody class="bg-white divide-y divide-gray-200">
          <tr v-for="doc_serv in job.document_services" :key="doc_serv.guid">
            <td class="px-6 py-4 whitespace-nowrap text-sm">
              {{ doc_serv.process_document_name }}
              <br>
              <span class="font-bold">#{{ doc_serv.id }}</span>
            </td>
            <td class="px-6 py-4 whitespace-nowrap">
              <div class="flex items-center justify-between">
                <span>{{doc_serv.service_name}}</span>
                <i :class="doc_serv.service_icon"></i>
              </div>
              <div class="text-sm text-gray-500">{{doc_serv.product_name}}</div>
            </td>
            <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
              {{ doc_serv.created_at }}
            </td>
            <td class="px-6 py-4 whitespace-nowrap">
              <div class="flex items-center justify-between">
                <div v-if="doc_serv.edit_requested_for && job.status_id == 1">
                  <Datepicker
                    v-model="doc_serv.new_requested_for"
                    :format="'dd/MM/yyyy HH:mm'"
                    @update:modelValue="save_service_requested_for(job, doc_serv)"
                    class="datepicker-wrap-aa" />
                </div>
                <span v-else>{{doc_serv.requested_for}}</span>
                <button v-if="job.status_id == 1"
                        @click="() => {doc_serv.edit_requested_for = !doc_serv.edit_requested_for}"
                        class="text-gray-400 hover:text-gray-600">
                  <i v-if="doc_serv.edit_requested_for" class="fa-solid fa-times"></i>
                  <i v-else class="fa-solid fa-pencil"></i>
                </button>
              </div>
            </td>
            <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
              {{doc_serv.origin_name}}
            </td>
            <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
              {{doc_serv.destination_name}}
            </td>
            <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
              {{doc_serv.note}}
            </td>
            <td class="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
              <div class="flex space-x-2">
                <button @click="remove_from_job(0, doc_serv, job)" 
                        class="text-red-600 hover:text-red-900">
                  <i class="fa-solid fa-trash"></i>
                </button>
                <button class="text-green-600 hover:text-green-900">
                  <i class="fa-solid fa-check"></i>
                </button>
              </div>
            </td>
          </tr>
        </tbody>
      </table>

      <!-- Footer Actions -->
      <div class="flex justify-end mt-4">
        <button v-if="job.status_id == 1" 
                @click="start_close_job(job)"
                class="inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500">
          Perfundo planifikimin
        </button>
      </div>
    </div>
  </div>
        </div>
      </div>
      <div class="mt-3 w-1/2 h-full ">
        <div class="is-size-6 mb-2 is-flex">
          <span class="subtitle mb-0"> Sherbimet e dokumentave </span>
          <div class="ml-auto border-r pr-2 mr-2">
            <!-- <p class="mr-3 my-auto text-xs">Tipi i sherbimit</p> -->
            <div v-if="useJobStore().service_types.length > 0" class="select is-small">
              <!-- @change="useJobStore().getServiceTypes(type, false)" v-model="useJobStore().selected" -->
              <select v-model="useJobStore().service_type_filter">
                <option :value="'show_all'">Te gjithe tipet</option>
                <option v-for="service in useJobStore().service_types" :key="service.guid" :value="service.guid">
                  {{ service.name }}
                </option>
              </select>
            </div>
          </div>
          <div>
            <div class="field">
              <p class="control  has-icons-right">
                <input v-model="search_services_val" class="input is-small has-text-right" type="text" placeholder="Kerko sherbim">
                <span class="icon is-small is-right">
                  <i class="fa-solid fa-magnifying-glass"></i>
                </span>
              </p>
            </div>
          </div>
        </div>
        <div v-if="useJobStore().multiple_select_mode && useJobStore().selected_job" class="w-full border rounded bg-green-50 border-green-300 mb-2 is-flex">
          <div class="is-flex-grow-1 p-3 w-4/5 is-flex is-flex-direction-column">
            <div>
              Zgjidhni sherbimet qe doni te shtoni fletes se punes <strong>{{useJobStore().selected_job.id}}</strong>
            </div>
            <div class="mt-2 is-flex">
                <div v-for="sel in useJobStore().selected_services" :key=sel.guid class="control mr-2">
                  <div class="tags has-addons ">
                    <a class="tag is-success is-light border border-green-400">#{{sel.id}}</a>
                    <a @click.prevent="useJobStore().add_remove_service_to_list(sel)" class="tag is-delete is-success is-light border border-green-400 border-l-0"></a>
                  </div>
                </div>
                <!-- <pre> {{useJobStore().selected_services}} </pre> -->
            </div>
          </div>
          <div class="p-3 w-1/5 border-l">
            <!-- <a @click.prevent="useJobStore().add_multiple_services()" -->
            <a v-if="useJobStore().selected_services.length > 0" @click.prevent="start_add_multiple()"
              class="button is-small is-block bg-green-100 border-green-500" href="">Konfirmo</a>
          </div>
        </div>
        <div class="h-full job-list-wrap">
          <table class="table is-fullwidth is-hoverable is-narrow is-bordered">
            <thead style="background: #f0f0f0">
              <tr class="text-sm">
                <th>#</th>
                <th>Sherbimi</th>
                <!-- <th>Produkti</th> -->
                <th>Krijuar</th>
                <th>Kerkuar per date</th>
                <th>Nga</th>
                <th>Tek</th>
                <th width="180px">Shenime</th>
                <th width="80px">Flete pune</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="doc_serv in services_list" :key="doc_serv.guid"
                :class="{'selected-job-row' : doc_serv.checked, 'bg-green-100' : doc_serv.job_id}"
                class="text-xs"
              >
                <td>
                  <span>
                    {{ doc_serv.process_document_name }}
                  </span>
                  <br>
                  <b>
                    #{{ doc_serv.process_number }}
                  </b>
                </td>
                <td>
                  <p>
                    <span>{{doc_serv.service_name}}</span>
                    <i class="is-pulled-right" :class="doc_serv.service_icon"></i>
                  </p>
                  <p>
                    <span class="text-slate-400">{{doc_serv.product_name}}</span>
                  </p>
                </td>
                <!-- <td>{{ doc_serv.product_name }}</td> -->
                <td>{{ doc_serv.created_at }}</td>
                <!-- <td > -->
                <td>
                  {{doc_serv.requested_for}}
                </td>
                <td>
                  {{doc_serv.origin_name}}
                </td>
                <td>
                  {{doc_serv.destination_name}}
                </td>
                <td>
                  {{doc_serv.note}}
                </td>
                <td :class="{'bg-green-100' : doc_serv.job_id}">
                  <div v-if="doc_serv.job_id">
                    <span class="text-xs">
                      FP-{{doc_serv.job_id}}
                    </span>
                    <span @click.prevent="remove_from_job(0, doc_serv, {guid: doc_serv.job_guid}) " v-if="doc_serv.job_id" class="is-clickable text-xs text-red-500 is-pulled-right">
                      <i class="fa-solid fa-trash"></i>
                    </span>
                  </div>
                  <div class="is-flex is-justify-content-right ">
                    <a v-if="!doc_serv.job_id && !useJobStore().multiple_select_mode"
                      @click.prevent="add_job(doc_serv)"
                      class="button is-small">
                      <i class="fa fa-plus"></i>
                    </a>
                    <a v-if="useJobStore().multiple_select_mode && !doc_serv.job_id" @click.prevent="useJobStore().add_remove_service_to_list(doc_serv)" class="button is-small bg-green-50">
                      <span class="is-clickable">
                        <i v-if="!doc_serv.checked" class="fa-regular fa-square"></i>
                        <i v-else class="fa-regular fa-square-check"></i>
                      </span>
                    </a>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <!-- <p class="mt-4 has-text-centered" v-else>Nuk ka te dhena per te shfaqur.</p> -->
      
    </div>

  </div>
</template>

<script setup>
// import { useJobStore } from '@/stores/job'
const toast = inject("toast");
import Datepicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";
// import { $vfm } from 'vue-final-modal'


function add_job(doc_serv) {
  if(confirm("Doni te krijoni nje 'Flete pune' te re me sherbimin e zgjedhur? ")){
    useJobStore().add_as_job(doc_serv)
    .then((res) => {
      if(useJobStore().is_new == true){
        useJobStore().process_jobs.push(res.data[0])
        toast.show('Puna u shtua me sukses.', { ...{ type: 'success', duration: 4000 } });
        useJobStore().process_document_service = [...useJobStore().process_document_service.map(pds => {
          if(pds.guid == doc_serv.guid){
            pds.job_id = res.data[0].id
          }
          return pds
        })]
      }
    })
  }
}

function start_add_multiple(){
  if (confirm("Doni te sherbimet e zgjedhura tek Fleta e punes Nr." + useJobStore().selected_job.id + "?") == true) {
    useJobStore().add_multiple_services()
    .then(r => {
      toast.show('Sherbimet u shtuan me sukses.', { ...{ type: 'success', duration: 4000 } });
    })
  }
}

function remove_from_job(job, service, job_to_remove) {
  if(confirm("Hiq sherbimin "+ service.id + " nga fleta e punes?")){
    useJobStore().edit_service_job(job, service)
    .then(res => {
      if(res){
        // remove service from job_to_remove
        useJobStore().process_jobs.map(j => {
          if(j.guid == job_to_remove.guid){
            j.document_services = j.document_services.filter(s => s.guid != service.guid)
          }
          return j
        })
        var toast_message = res && res.message == 'service_removed_success' ? 'Sherbimi ' + service.id + ' u hoq me sukses.' : ''
        toast.show(toast_message, { ...{ type: 'success', duration: 4000 } });
      }
    })
  }
}

function save_service_requested_for(job, service) {
  
  if(confirm("Doni te ruani daten e ketij sherbimi?")){

    useJobStore().edit_service_job(job, service)
    .then(res => {
      var toast_message = res && res.message == 'service_updated_success' ? 'Sherbimi ' + service.id + ' u perditesua me sukses.' : ''
      toast.show(toast_message, { ...{ type: 'success', duration: 4000 } });
    })
  }
}

async function start_assign_job_user(job){
  await useJobStore().update_job(job)
  .then(r => {
    if(r.data.message == 'job_updated')
      toast.show('Pergjegjesi u caktua me sukses.', { ...{ type: 'success', duration: 4000 } });
  })
}

function start_close_job(job){
  useJobStore().close_job(job).then(r => {
    if(r.data > 0){
      toast.show('Fleta e punes u perfundua me sukses.', { ...{ type: 'success', duration: 4000 } });
      useJobStore().process_jobs.map(pj => {
        if(pj.guid == job.guid){
          pj.status_id = 2
        }
        return pj
      })
    }
  })
}

// Datepicker range mode
const search_job_daterange = ref();
const startTime = ref({ hours: 0, minutes: 0, seconds: 0 });

// For demo purposes assign range from the current date
onMounted(() => {
  const startDate = new Date();
  // const endDate = new Date(new Date().setDate(startDate.getDate() + 7));
  const endDate = new Date();
  search_job_daterange.value = [startDate, endDate];
})

</script>

<script>
import { $vfm } from 'vue-final-modal'
// import {ref} from "vue"
import { useJobStore } from '@/stores/job'
import { defineAsyncComponent, inject, ref, onMounted } from "vue";

  export default {
    data() {
      return {
        search_services_val: '',
        jobs_search_val: '',
        show_jobs_search: false,
        show_jobs_calendar_search: false,
        job_search_date_range: [],
        job_user_search: null,
      }
    },
    methods: {
      async open_print_job_modal_start(job){
        var params = {}
        // if(!job.document_services){ }
        await useJobStore().get_job_services(job)
        .then(r => {
          if(r.data.length > 0){
            job.document_services =  r.data
            params = { job }
          }
          else {
            params = {is_empty: true}
          }
          this.open_print_job_modal(params)
        })
      },
      open_print_job_modal(params){
        $vfm.show({
            component: defineAsyncComponent(
            () => import("./components/print-job-modal.vue")
            ), params
        })
      },
      toggle_jobs_search(toggle){
        this.show_jobs_search = !toggle
        
      },
      toggle_jobs_calendar_search(toggle){
        this.show_jobs_calendar_search = !toggle
      },
      update_job_date_range(d){
        this.job_search_date_range = d
      },


    },
    computed: {
      services_list(){
        var list = []
        if(useJobStore().service_type_filter != 'show_all'){
          list = useJobStore().process_document_service.filter(a => a.service_type_id == useJobStore().service_type_filter)
        }
        else
          list = useJobStore().process_document_service

        if(this.search_services_val != ''){
          list = list.filter(a => JSON.stringify(a).toLowerCase().includes(this.search_services_val.toLowerCase()))
        }
        return list
      },
      filtered_jobs() {
        var list = []
        
        // Filter by search field
        list = useJobStore().process_jobs.filter(a => JSON.stringify(a).toLowerCase().includes(this.jobs_search_val.toLowerCase()));

        // Filter by calendar
        if(this.job_search_date_range && this.job_search_date_range.length > 0){
          list = useJobStore().process_jobs.filter(j => {
            var min_date = new Date(j.min_date);
            var max_date = new Date(j.max_date);
            var range_0 = new Date(this.job_search_date_range[0]);
            var range_1 = new Date(this.job_search_date_range[1]);
            if(min_date >= range_0 && max_date <= range_1) 
              return true
          })
        }

        // Filter by users
        if(this.job_user_search){
          list = useJobStore().process_jobs.filter(j => {
            if(j.user_id == this.job_user_search || j.updated_by_id == this.job_user_search)
              return true
          })
        }
        return list
      }
    }
  }
</script>

<style>
.activities-page-wrap{
  height: calc(100vh - 90px);
  /* background: sandybrown; */
}
.job-list-wrap{
  height:93%;
  overflow-y: auto;
}
.selected-job-row{
  opacity:0.6;
  background: #ecfdf5;
}
.selected-job-highlight{
    background:pinkbox;
    box-shadow: rgba(118, 158, 134, 0.795) 0px 1px 2px 0px, rgba(95, 117, 94, 0.15) 0px 2px 6px 2px;
    border:solid 1px #99d6a1;
}
.collapsed-job {
  background: #ecf9f8;
}

.job-datepicket-search-wrap > div > .dp__input_wrap > .dp__input { height:29px!important; font-size:12px; }
</style>

