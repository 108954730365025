<template>
  <div v-for="(step, index) in sorted_steps" :key="step.sort_nr">
    <article class="box bg-white p-2 hover:bg-slate-50" style="height: 100%">
      <p class="subtitle mb-2 is-flex is-size-6 is-justify-content-start b border-b border-slate-200 pb-1 font-semibold">
        <small class="mr-2 text-gray-400">{{ index + 1 }}</small>
        <span>
          {{ step.name }}
        </span>

      </p>
      <p v-if="step.description" class="is-size-7">
        <i class="fa-solid fa-circle-info text-slate-400"></i>
        {{ step.description }}
      </p>
      <div class="">
        <!-- {{step.adds_items}} -->
        <nav class="panel mt-2 is-flex is-flex-direction-column mb-3">
          <label class="panel-block px-2 py-1 is-flex-direction-column is-align-items-flex-start">
            <div class="m-0 is-flex w-full">
              <i class="fa fa-cubes-stacked mr-1 text-slate-400"></i>
              <span> Produkt </span>
              <span class="ml-auto">
                <help-tooltip :entity="'is_service_help'"
                  :content="'Percaktohet menyra se si do shfaqet produkti ne momentin kur shtohet ne dokument.'"></help-tooltip>
              </span>
            </div>
            <div class="select is-small w-full">
              <select v-model="step.adds_items" @change="save_step(step, step.adds_items)" class="w-full" name="" id="">
                <option :value="0">Nuk shton produkte</option>
                <option :value="1">Shton produkt te thjeshte</option>
                <option :value="2">Shton produkte me aksesore</option>
              </select>
            </div>
          </label>
          <label
            v-if="props.selected_process.origin_type == 1 || props.selected_process.origin_type == 2 || props.selected_process.destination_type == 2"
            class="panel-block px-2 py-1 is-flex-direction-column is-align-items-flex-start">
            <div class="m-0 is-flex w-full">
              <i class="fa-solid fa-dolly mr-1 text-slate-400"></i>
              <span> Eshte transaksion </span>
              <span class="ml-auto">
                <help-tooltip :entity="'is_service_help'"
                  :content="'Ky opsion tregon nese behet Dalja nga Origjina ose Hyrje ne destinacion '"></help-tooltip>
              </span>
            </div>
            <div class="select is-small w-full">
              <select v-model="step.is_transaction" @change="save_step(step, step.is_transaction)" class="w-full" name=""
                id="">
                <option :value="0">Jo</option>
                <option v-if="show_is_transaction_origin(step)" :value="1">
                  Per origjinen (dalje)
                </option>
                <option v-if="show_is_transaction_destination(step)" :value="2">
                  Per destinacionin (hyrje)
                </option>
                <option v-if="show_is_transaction_destination(step)" :value="3">
                  Per Dalje dhe Hyrje
                </option>
                
              </select>
            </div>
            <p class="help">
              {{ is_transaction_help_text }}
            </p>
          </label>
          <label v-if="props.selected_process.origin_type == 3 || props.selected_process.destination_type == 4"
            class="panel-block px-2 py-1">
            <input v-model="step.creates_invoices" :true-value="1" :false-value="0"
              @change="save_step(step, step.creates_invoices)" type="checkbox"
              :disabled="!stepGeneralStatuses.destination_client_one_invoice && !step.creates_invoices" />
            Krijon Fature
            <span class="ml-auto">
              <help-tooltip :entity="'is_service_help'"
                :content="'Krijon fature per Furnitore nese eshte origjine ose per Klientin nese eshte destinacion.'"></help-tooltip>
            </span>
          </label>

          <p v-if="(props.selected_process.origin_type == 3 || props.selected_process.destination_type == 4)
            && (step.creates_invoices == 1 && stepGeneralStatuses.invoicable_once)"
            class="is-flex pl-1 mt-2 border-t border-slate-100 pt-1 bg-gray-100 p-2">
            <span>Fature</span>
            <a @click.prevent="show_invoice_krudh(step, 1)" href="" class="ml-auto">
              <i class="font-semibold fa-solid fa-plus text-xs"></i>
            </a>
          </p>

          <label v-if="step.creates_invoices == 1"
            class="panel-block px-2 py-1">
            <input v-model="step.mandates" :true-value="1" :false-value="0"
              @change="save_step(step, step.mandates)" type="checkbox"/>
            Mandate
            <span class="ml-auto">
              <help-tooltip :entity="'is_service_help'"
                :content="'Mund te shtohen mandate nga lista e meposhtme.'"></help-tooltip>
            </span>
          </label>
          <label v-if="step.mandates == 1"
            class="panel-block px-2 py-1 is-flex-direction-column is-align-items-flex-start">
            <p>Zgjidh tipin e mandatin</p>
            <div class="select is-small w-full">
              <select v-model="step.creditnote_type" @change="save_step(step, step.creditnote_type)" class="w-full">
                <option v-for="type in creditnote_types" :value="type.id" :key="type.id">
                  {{ type.name }}
                </option>
              </select>
            </div>
          </label>

          <!-- LOGIC: erp process type -->
          <label v-if="step.creates_invoices == 1"
            class="panel-block px-2 py-1">
            <input v-model="step.process_type" :true-value="1" :false-value="0"
              @change="save_step(step, step.process_type)" type="checkbox"/>
            Tipi Dokumentit ERP 
            <span class="ml-auto">
              <help-tooltip :entity="'is_service_help'"
                :content="'Mund te zgjidhet procesi nga lista e sistemit ERP.'"></help-tooltip>
            </span>
          </label>
          <label v-if="step.process_type == 1"
            class="panel-block px-2 py-1 is-flex-direction-column is-align-items-flex-start">
            <p>Zgjidh tipin e Procesit ERP</p>
            <div class="select is-small w-full">
              <select v-model="step.process_type_code" @change="save_step(step, step.process_type_code)" class="w-full">
                <optgroup v-for="type_group in process_types_groups" :label="type_group.name" :key="type_group.name">
                  <option v-for="type in type_group.types" :value="type.code" :key="type.code">
                    {{ type.name }}
                  </option>
                </optgroup>
              </select>
            </div>
          </label>

          <label class="panel-block px-2 py-1">
            <input v-model="step.delete_document_item" :true-value="1" :false-value="0"
              @change="save_step(step, step.delete_document_item)" type="checkbox" />
            Fshin produkte
            <span class="ml-auto">
              <!-- <help-tooltip
                  :entity="'is_service_help'"
                  :content="'Mund te printoje dokument.'"
                ></help-tooltip> -->
            </span>
          </label>

          <label class="panel-block px-2 py-1">
            <input v-model="step.ask_serial" :true-value="1" :false-value="0"
              @change="save_step(step, step.ask_serial)" type="checkbox" />
            Kerkon Numrin Serial
            <span class="ml-auto">
                <help-tooltip
                  :entity="'is_service_help'"
                  :content="'Kerkohet vendosja e numrave serial.'">
                </help-tooltip>
            </span>
          </label>

          <label class="panel-block px-2 py-1">
            <input v-model="step.gives_warranty" :true-value="1" :false-value="0"
              @change="save_step(step, step.gives_warranty)" type="checkbox" />
            Jep garanci
            <span class="ml-auto">
                <help-tooltip
                  :entity="'is_service_help'"
                  :content="'Mund te japi flete garancie.'">
                </help-tooltip>
            </span>
          </label>

          <label class="panel-block px-2 py-1">
            <input v-model="step.gives_discount" :true-value="1" :false-value="0"
              @change="save_step(step, step.gives_discount)" type="checkbox" />
            Krijon kerkese cmimi
            <span class="ml-auto">
                <help-tooltip
                  :entity="'is_service_help'"
                  :content="'Krijohet kerkese cmimi.'">
                </help-tooltip>
            </span>
          </label>

          <label class="panel-block px-2 py-1">
            <input v-model="step.print_document" :true-value="1" :false-value="0"
              @change="save_step(step, step.print_document)" type="checkbox" />
            Printon dokument
            <span class="ml-auto">
              <help-tooltip :entity="'is_service_help'" :content="'Mund te printoje dokument.'"></help-tooltip>
            </span>
          </label>


          <p v-if="step.print_document == 1" class="is-flex pl-1 mt-2 border-t border-slate-100 pt-1 bg-gray-100 p-2">
            <span>Dokument printimi</span>
            <a @click.prevent="show_invoice_krudh(step, 2)" href="" class="ml-auto">
              <i class="font-semibold fa-solid fa-plus text-xs"></i>
            </a>
          </p>

          <label class="panel-block px-2 py-1">
            <input v-model="step.can_see_price" :true-value="1" :false-value="0"
              @change="save_step(step, step.can_see_price)" type="checkbox" />
            Sheh cmimet
            <span class="ml-auto">
              <help-tooltip :entity="'is_service_help'"
                :content="'Ka mundesi te shohi cmimet e produkteve, sherbimeve dhe aksesoreve ne dokument.'"></help-tooltip>
            </span>
          </label>
          <label class="panel-block px-2 py-1 is-flex-direction-column is-align-items-flex-start">
            <div class="is-flex w-full">
              <span class="m-0">Sasite</span>

              <span class="ml-auto">
                <help-tooltip :entity="'is_service_help'"
                  :content="'Mund te shohe ose te modifikoje sasite ne dokument .'"></help-tooltip>
              </span>
            </div>
            <div class="select is-small w-full">
              <select v-model="step.can_see_amount" @change="save_step(step, step.can_see_amount)" class="w-full" name=""
                id="">
                <option :value="0">Shfaq sasite</option>
                <option :value="1">Modifikon sasite</option>
              </select>
            </div>
          </label>
          <label class="panel-block px-2 py-1" v-if="step.sort_nr > 0">
            <input v-model="step.is_service" :true-value="1" :false-value="0" @change="save_step(step, step.is_service)"
              type="checkbox" />
            <span> Eshte sherbim </span>
            <span class="ml-auto">
              <help-tooltip :entity="'is_service_help'"
                :content="' Ky hap do jete aktiv vetem nese produktet qe jane hedhur ne hapat e meparshem kane po ashtu te asenjuar nje sherbim te tipit te zgjedhur me poshte. Nese sherbimet e hedhura do jene te te njejtit tip atehere do hapet si hap'"></help-tooltip>
            </span>
          </label>
          <label v-if="step.is_service == 1 && step.sort_nr > 0"
            class="panel-block px-2 py-1 is-flex-direction-column is-align-items-flex-start">
            <p>Tipet e sherbimit</p>
            <div class="select is-small w-full">
              <select v-model="step.service_id" @change="save_step(step, step.service_id)" class="w-full">
                <option v-for="service in filterServiceTypes(step)" :value="service.guid" :key="service.guid">
                  {{ service.name }}
                </option>
              </select>
            </div>
          </label>

        </nav>
        <nav class="panel mt-2 is-flex is-flex-direction-column mb-3 pt-0">
          <div class="is-flex is-flex-direction-column border-b">
            <div class="font-semibold px-2 py-1 bg-gray-100">
              <i class="fa fa-cog mr-1"></i>
              Sherbime
            </div>
            <div class="is-flex is-justify-content-space-between px-2 mt-1">
              <label class="">
                <input v-model="step.adds_services" :true-value="1" :false-value="0"
                  @change="save_step(step, step.adds_services)" type="checkbox" />
                Shton
              </label>

              <label class="">
                <input v-model="step.can_edit_services" :true-value="1" :false-value="0"
                  @change="save_step(step, step.can_edit_services)" type="checkbox" />
                Modifikon
              </label>
            </div>
          </div>
        </nav>
        <nav class="panel mt-2 is-flex is-flex-direction-column mb-3 pt-0">
          <div class="is-flex is-flex-direction-column border-b ">
            <div class=" px-2 py-1 bg-gray-100 font-semibold">
              <i class="fa fa-cubes-stacked mr-2"></i>
              Aksesore
            </div>
            <div class="is-flex is-justify-content-space-between px-2 mt-1">
              <label>
                <input v-model="step.adds_accessories" :true-value="1" :false-value="0"
                  @change="save_step(step, step.adds_accessories)" type="checkbox" />
                Shton
              </label>
              <label>
                <input v-model="step.edits_accessories" :true-value="1" :false-value="0"
                  @change="save_step(step, step.edits_accessories)" type="checkbox" />
                Modifikon
              </label>
            </div>
          </div>
        </nav>

      </div>
      <p class="is-flex pl-1 mt-2 border-t border-slate-100 pt-1 bg-gray-100 p-2">
        <span>Kerkesa</span>
        <a @click.prevent="show_step_notification_krudh(step)" href="" class="ml-auto">
          <i class="fa-solid fa-bullhorn text-slate-400 text-xs"></i>
        </a>
      </p>
      <nav class="panel mt-2">
        <label v-for="dispute in step.disputes" :key="dispute.guid" class="panel-block px-2 py-1">
          <input :v-model="dispute.needs_confirmation" :checked="dispute.needs_confirmation"
            :value="dispute.needs_confirmation" @change="save_dispute(dispute)" type="checkbox" />
          {{ dispute.name }}
        </label>
      </nav>
    </article>
  </div>
</template>

<script setup>
import Api from "@/services/Api";
import { $vfm } from "vue-final-modal";
// import { usePrintStore } from '@/stores/print'
import { defineProps, defineAsyncComponent, inject, computed } from "vue";
import helpTooltip from '@/components/help-tooltip'
// const showInvoice = ref(false)

const props = defineProps([
  "sorted_steps",
  "selected_process",
  "service_types",
  "stepGeneralStatuses",
  "creditnote_types", 
  "process_types"
]);
const toast = inject("toast");

function filterServiceTypes(step) {
  var s_types = props.service_types.filter((s) => s.is_transaction == 0);
  var serviceOnce = Object.keys(props.stepGeneralStatuses.same_service_once);

  serviceOnce.map((so) => {
    if (so != step.service_id) {
      s_types = s_types.filter((st) => st.guid != so);
    }
  });
  return s_types;
}

function save_step(step) {
  if (
    props['stepGeneralStatuses'].origin_transaction_once
    && props['stepGeneralStatuses'].destination_transaction_once
    && props['stepGeneralStatuses'].invoicable_once

    // && props['stepGeneralStatuses'].same_service_once
  ) {
    if(step.creates_invoices == 0){
      step.mandates = 0
      step.creditnote_type = null
    }
    Api(true)
      .post("processes/save/step/config", {
        step,
        stepGeneralStatuses: props["stepGeneralStatuses"],
      })
      .then((r) => {
        if (r.data > 0) {
          toast.show("Ndryshimi u ruajt me sukses.", {
            ...{ type: "success", duration: 4000 },
          });
        }
      });
  }
  else {
    toast.show("Ju lutem kontrolloni konfigurimin e procesit", {
      ...{ type: "error", duration: 4000 },
    });
  }

  // Add toaster ...
}
function show_step_notification_krudh(step) {
  var params = {
    fields: [
      "code:Kodi",
      "name:Titulli",
      "description:Pershkrimi",
      "needs_confirmation: Pret konfirmim:checkbox-field",
      "is_required: Eshte i detyrueshem?:checkbox-field",
      // 'subdivide_documents: Ndan dokumente:checkbox-field'
      // 'icon:Ikona,*name:Emri',
    ],
    entity: "process_steps_disputes",
    allow_delete: true,
    // columns: ['icon:80px', 'name:130px'],
    title: "Kerkesa",
    where: [{ field: "step_id", operation: "=", value: step.guid }],
    defaults: [
      {
        field: "step_id",
        value: step.guid,
      },
    ],
  };
  $vfm.show({
    component: defineAsyncComponent(() =>
      import("@/components/krudh-modal.vue")
    ),
    params,
  });
}
function show_invoice_krudh(step, doc_type = null) {
  var params = {}
  params.step = step.guid
  params.doc_type = doc_type
  // var params = step.guid
  $vfm.show({
    component: defineAsyncComponent(() =>
      import("../components/print_template_modal.vue")
    ),
    params
  });
  // usePrintStore().get_print_documents(step.guid, doc_type)
}
function save_dispute(d) {
  // Set the changed value
  !d.needs_confirmation
    ? (d.needs_confirmation = 1)
    : (d.needs_confirmation = 0);

  Api(true)
    .post("processes/save/disputes", { dispute: d })
    .then((r) => {
      if (r.data[0].guid) {
        toast.show("Ndryshimi u ruajt me sukses.", {
          ...{ type: "success", duration: 4000 },
        });
      }
    });
}

function show_is_transaction_origin(step) {
  if (props.selected_process.origin_type == 1 || props.selected_process.origin_type == 2) {
    // check if step.guid == origin_steps
    if (!props.stepGeneralStatuses.origin_transaction_check.origin || step.is_transaction == 1) {
      return true
    }
  }
  else
    return false
}
function show_is_transaction_destination(step) {
  if (
    props.selected_process.destination_type == 2 ||
    props.selected_process.destination_type == 3 ||
    props.selected_process.destination_type == 4
  ) {

    if ((!props.stepGeneralStatuses.origin_transaction_check.destination)
      || (step.is_transaction == 2)) {
      return true
    }
  }
  else
    return false
}

const process_types_groups = computed(() => {
  return props.process_types.reduce((acc, type) => {
    if (!acc[type.doc_type]) {
      acc[type.doc_type] = { name: type.doc_type, types: [] }
    }
    acc[type.doc_type].types.push(type)
    return acc
  }, {})
})

const is_transaction_help_text = computed(() => {
  var string = ''
  // if origin is 0
  // props.selected_process.origin_type == 0

  // if(!props.selected_process.origin_type){
  //   string = "Info when origin is not set"
  // }

  // 
  return string
})


</script>

<style scoped>
.panel-block input {
  width: 20px;
}
</style>
