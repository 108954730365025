import Api from "@/services/Api";
import helpers from "@/helpers.js";

export default {
    async getProducts(page, per_page){
        var product_list = []
        await Api(true).post("product/product_list", {page, per_page})
        .then(r => {
            product_list = JSON.parse(r.data).map(e => {
                // if(e.vat !== null && e.price) {
                //     e.price = e.price * (1 + (e.vat / 100))
                // }
                e.label = e.name
                return e
            })
        })
        return product_list;
    },

    async get(p){
        var product = {}
        await Api(true).post("product/details", p)
        .then(r => {    
            product = r.data
        })
        return product;
    },

    async createProduct(product) {
        await Api(true).post("product/create", product)
        .then(r => {
            product = r.data
        })
        return product
    },

    async editProduct(product) {
        await Api(true).post("product/edit", product)
        .then(r => {
            product = r.data
        })
        return product
    },
    async getUnits() {
        var units = []
        await Api(true).post("unit/list", {})
        .then(r => {
            units = r.data.map(e => {
                // e.id = e.name
                e.label = e.name
                return e
            })
        })
        return units
    },

    async getMaterials() {
        var materials = []
        await Api(true).post('material/list', {})
        .then(r => {
            materials = r.data.map(e => {
                e.label = e.name
                return e
            })
        })
        return materials
    },

    async getBrands() {
        var brands = []
        await Api(true).post('brand/list', {})
        .then(r => {
            brands = r.data.map(e => {
                e.label = e.name
                return e
            })
        })
        return brands
    },

    async getWarranties() {
        var warranties = []
        await Api(true).post('warranties/list', {})
        .then(r => {
            warranties = r.data.map(e => {
                e.label = e.name
                e.id = e.guid
                return e
            })
        })
        return warranties
    },

    async getVat() {
        var vat = []
        await Api(true).post('vat/list', {})
        .then(r => {
            vat = r.data.map(e => {
                e.label = e.value
                e.id = e.value
                return e
            })
        })
        return vat
    },

    async getProductRecipes() {
        var product_recipes = []
        await Api(true).post('product/product_recipe', {})
        .then(r => {
            product_recipes = r.data
        })
        return product_recipes
    },

    async createProductRecipe(product_recipe) {
        await Api(true).post("product/product_recipe/create", product_recipe)
        .then(r => {
            product_recipe = r.data
        })
        return product_recipe
    },
    async editProductRecipe(product_recipe) {
        await Api(true).post("product/product_recipe/edit", product_recipe)
        .then(r => {
            product_recipe = r.data
        })
        return product_recipe
    },
    async get_exclusive_entity(product, only_connected = null) {
        var exclusives = {}
        await Api(true).post("product/get/exclusive", { product, only_connected })
            .then(r => {
                exclusives = r.data
                exclusives.services_with_services_types.map(e => {
                    e.label = e.name
                    e.id = e.guid
                    return e
                })
                exclusives.services_with_services_types = [...helpers.toTree(
                    exclusives.services_with_services_types,
                    "id"
                )]
            })
        return exclusives
    },
    async getManagers() {
        var managers = []
        await Api(true).post('product/managers/list', {})
        .then(r => {
            managers = r.data.map(e => {
                e.label = e.name
                return e
            })
        })
        return managers
    },
    async getOriginCountries() {
        var countries = []
        await Api(true).post('product/countries/list', {})
        .then(r => {
            countries = r.data.map(e => {
                e.label = e.name
                return e
            })
        })
        return countries
    },
    async get_product_diaries(guid) {
        var diaries = []
        await Api(true).post('product/get/diaries', guid)
        .then(r => {
            diaries = r.data
        })
        return diaries
    },
    async get_product_processes(guid) {
        var diaries = []
        await Api(true).post('product/get/processes', guid)
        .then(r => {
            diaries = r.data
        })
        return diaries
    }
}
