<template>
  <div class="p-2 bg-gray-50 min-h-screen">
    <!-- Header Section -->
    <div class="bg-white rounded-lg shadow-sm p-2 mb-2">
      <div class="flex items-center justify-between">
        <h2 class="text-xl font-semibold text-gray-800">Gjendje</h2>
        
        <div class="flex items-center gap-4">
          <!-- POS Selection -->
          <div class="flex flex-col">
            <label class="text-xs text-gray-500 mb-1">Pika e shitjes</label>
            <select 
              v-model="selected_pos" 
              @change="get_pos_warehouses(selected_pos)"
              class="px-3 py-2 bg-white border border-gray-300 rounded-md text-sm focus:ring-2 focus:ring-blue-500 focus:border-blue-500">
              <option v-for="pos in pos_list" :key="pos.guid" :value="pos.guid">
                {{ pos.name }}
              </option>
            </select>
          </div>

          <!-- Search -->
          <div class="flex flex-col">
            <label class="text-xs text-gray-500 mb-1">Kërkimi</label>
            <div class="relative flex items-center">
              <input 
                v-model="search_val" 
                class="pl-9 pr-3 py-2 bg-white border border-gray-300 rounded-md text-sm focus:ring-2 focus:ring-blue-500 focus:border-blue-500 w-full"
                type="text" 
                placeholder="Kërko produkt...">
              <div class="absolute left-3 flex items-center h-full text-gray-400">
                <i class="fa fa-search"></i>
              </div>
            </div>
          </div>

          <!-- Refresh Button -->
          <button 
            @click="get_stock()" 
            class="mt-6 p-2 hover:bg-gray-100 rounded-full transition-colors">
            <i class="fa fa-refresh text-gray-500"></i>
          </button>
        </div>
      </div>
    </div>

    <!-- Table Section -->
    <div v-if="selected_pos != null" class="bg-white rounded-lg shadow-sm overflow-x-auto">
      <table class="w-full border-collapse">
        <thead>
          <tr class="bg-gray-50">
            <th class="py-2 px-2 text-left font-medium text-gray-700 border-b">Produkti</th>
            <template v-for="warehouse in warehouses_list" :key="warehouse.guid">
              <th class="py-3 px-4 text-center text-xs text-gray-700 border-b" colspan="4">
                {{ warehouse.name.replace('MAGAZINA', 'M.') }}
              </th>
            </template>
          </tr>
          <tr class="bg-gray-50">
            <th class="py-3 px-4 text-left font-medium text-gray-700 border-b"></th>
            <template v-for="warehouse in warehouses_list" :key="warehouse.guid">
              <th class="py-2 px-3 text-center text-sm font-medium text-gray-600 border-b" 
                  title="Gjendja totale ne stok">Stok</th>
              <th class="py-2 px-3 text-center text-sm font-medium text-gray-600 border-b" 
                  title="Gjendja e rezervuar">Ditar</th>
              <th class="py-2 px-3 text-center text-sm font-medium text-gray-600 border-b" 
                  title="Gjendja ne proces">Proces</th>
              <th class="py-2 px-3 text-center text-sm font-medium text-gray-600 border-b" 
                  title="Gjendja e disponueshme">Disp.</th>
            </template>
          </tr>
        </thead>
        <tbody>
          <tr v-for="product in filtered_products" 
              :key="product.guid"
              class="hover:bg-blue-50 transition-colors">
            <td class="py-3 px-4 border-b sticky left-0 bg-white">
              {{ product.name }}
            </td>
            <template v-for="warehouse in warehouses_list" :key="warehouse.guid">
              <!-- Stock -->
              <td class="py-3 px-4 text-center border-b text-xs">
                <div :class="[(product[warehouse.guid] || 0) == 0 ? 'text-gray-400' : 'border rounded-full p-1' ]" class="text-center">
                  {{ product[warehouse.guid] }}
                </div>
              </td>
              
              <!-- Diary -->
              <td class="py-3 px-4 text-center border-b">
                <div class="flex justify-center gap-1">
                  <span v-if="product[warehouse.guid + '_diary_out']" 
                        class="text-red-600">
                    -{{ product[warehouse.guid + '_diary_out'] }}
                  </span>
                  <span v-if="product[warehouse.guid + '_diary_in']" 
                        class="text-blue-600">
                    +{{ product[warehouse.guid + '_diary_in'] }}
                  </span>
                </div>
              </td>
              
              <!-- Process -->
              <td class="py-3 px-4 text-center border-b">
                <div class="flex justify-center gap-1">
                  <span v-if="product[warehouse.guid + '_out']" 
                        @click="toggle_stock_info_modal(product, warehouse)"
                        class="text-red-600 cursor-pointer hover:underline">
                    -{{ product[warehouse.guid + '_out'] }}
                  </span>
                  <span v-if="product[warehouse.guid + '_in']" 
                        @click="toggle_stock_info_modal(product, warehouse)"
                        class="text-blue-600 cursor-pointer hover:underline">
                    +{{ product[warehouse.guid + '_in'] }}
                  </span>
                </div>
              </td>
              
              <!-- Available -->
              <td class="py-3 px-4 text-center border-b">
                <span :class="{
                  'text-gray-400': getAvailableStock(product, warehouse) == 0,
                  'font-semibold': getAvailableStock(product, warehouse) > 0
                }">
                  {{ getAvailableStock(product, warehouse) }}
                </span>
              </td>
            </template>
          </tr>
        </tbody>
      </table>
      
      <!-- Pagination and Results Counter -->
      <div class="p-4 border-t flex items-center justify-between">
        <span class="text-sm text-gray-500">
          {{ totalFilteredProducts }} nga {{ products_list.length }} produkte
        </span>
        <div class="flex items-center gap-2">
          <button 
            @click="currentPage = Math.max(1, currentPage - 1)"
            :disabled="currentPage === 1"
            class="px-3 py-1 rounded border" 
            :class="currentPage === 1 ? 'bg-gray-100 text-gray-400' : 'hover:bg-gray-50'">
            <i class="fa fa-chevron-left"></i>
          </button>
          <span class="text-sm text-gray-600">
            Faqe {{ currentPage }} nga {{ totalPages }}
          </span>
          <button 
            @click="currentPage = Math.min(totalPages, currentPage + 1)"
            :disabled="currentPage === totalPages"
            class="px-3 py-1 rounded border"
            :class="currentPage === totalPages ? 'bg-gray-100 text-gray-400' : 'hover:bg-gray-50'">
            <i class="fa fa-chevron-right"></i>
          </button>
        </div>
        <div class="flex items-center gap-2">
          <label class="text-sm text-gray-500">Trego të gjitha</label>
          <input 
            type="checkbox" 
            v-model="showAllProducts"
            class="rounded border-gray-300 text-blue-600 focus:ring-blue-500">
        </div>
      </div>
    </div>

    <!-- Loading State -->
    <div v-else class="flex items-center justify-center h-64">
      <div class="flex flex-col items-center gap-3">
        <i class="fa fa-spinner fa-spin fa-2x text-blue-500"></i>
        <span class="text-gray-500">Faqja po ngarkohet...</span>
      </div>
    </div>

    <!-- Modals -->
    <add-stock-modal 
      v-if="show_add_stock_modal" 
      @toggle_stock_modal="toggle_stock_modal" />
    <stock-info-modal 
      v-if="show_stock_info_modal" 
      :selected_stock_product="selected_stock_product"
      :selected_stock_warehouse="selected_stock_warehouse"
      @toggle_stock_info_modal="toggle_stock_info_modal" />
  </div>
</template>

<script>
import Api from "@/services/Api";
import WarehousesServices from "@/services/Warehouse";
import ProductsServices from "@/services/Product";
import addStockModal from "./components/add-stock-modal";
import StockInfoModal from "./components/stock-info-modal";
import permissionMixin from "@/mixins/permissions-mixin.js";
// import { storeToRefs } from 'pinia';
// import { useDocumentStore } from "@/stores/document";
export default {
  mixins: [permissionMixin],
  components: {
    addStockModal,
    StockInfoModal
  },
  data() {
		return {
      warehouses: [],
      products: [],
      stock: [],
      pos_list: [],
      selected_pos: null,
      warehouses_of_pos: [],
      search_val: '',
      pending_stock: [],
      diary_stock: [],
      show_add_stock_modal: false,
      show_stock_info_modal: false,
      selected_stock_product: {},
      selected_stock_warehouse: {},
      current_user: {},
			currentPage: 1,
      itemsPerPage: 15,
      showAllProducts: false
    }
  },
  async created() {
    if (!this.am_allowed('stock.can_see')) {
      window.location.href = '/home'
    } else {
      this.warehouses = await WarehousesServices.getWarehouses()
      this.current_user = JSON.parse(localStorage.user)
      await this.get_user_pos()
      await this.get_stock()
    }
  },
  methods: {
    async get_stock() {
      this.stock = [] 
      this.pending_stock = []
      this.diary_stock = []
      this.products = await ProductsServices.getProducts()

      await this.get_pos_warehouses()
      await Api(true).post("stock/get/all")
        .then(r => {
          this.stock = r.data[0]
          this.pending_stock = r.data[1]
          this.diary_stock = r.data[2]

          // attach to each product the warehouse availability, 
          // default 0  
          this.products = [...this.products
          // .filter(p => {
          //   // is in this.stock or is in this.pending_stock
          //   return this.stock.find(s => s.product_id == p.guid) || this.pending_stock.find(s => s.product_id == p.guid)
          // })
					.map(p => {
						p.has_stock = this.stock.find(s => s.product_id == p.guid) || this.pending_stock.find(s => s.product_id == p.guid) 
						return p
					})
          .map(p => {
            this.warehouses.map(w => {
              // p[w.guid] = this.get_product_stock(p.guid, w.guid)
              // p[w.guid + '_out'] = this.get_product_pending_stock(p.guid, w.guid, 'out')
              // p[w.guid + '_in'] = this.get_product_pending_stock(p.guid, w.guid, 'in')
							if(p.has_stock){ 
								p = {
									...p, 
									[w.guid]: this.get_product_stock(p.guid, w.guid),
									[w.guid + '_out']: this.get_product_pending_stock(p.guid, w.guid, 'out'),
									[w.guid + '_in']: this.get_product_pending_stock(p.guid, w.guid, 'in'),
									[w.guid + '_diary_in']: this.get_product_pending_stock(p.guid, w.guid, 'diary_in'),
									[w.guid + '_diary_out']: this.get_product_pending_stock(p.guid, w.guid, 'diary_out')
								}
							} 
            })
            return p
          })]
        })
    },
    async get_user_pos() {
      await Api(true).post('/invoice/get/assigned/pos', { user: this.current_user })
      .then(r => {
        this.pos_list = r.data
        this.selected_pos = this.pos_list[0].guid
        this.get_pos_warehouses()
      })
    },
    get_product_stock(p, w) {
      var amount = 0
      this.stock.map(s => {
        if (s.product_id == p && s.warehouse_id == w)
          amount += Number(s.amount)
      })
      return amount
    },
    get_product_pending_stock(p, w, type) {
      var amount = 0
      if(['in', 'out'].includes(type) ){
        this.pending_stock.map(s => {
          if (s.product_id == p && s['warehouse_' + type] == w)
            if(s.amount)
              amount += Number(s.amount)
        })
      }
      else if(['diary_in', 'diary_out'].includes(type) ){
        this.diary_stock.map( s => {
          if (s.entity_id == p && s['warehouse_' + type] == w){
            if(s.amount){
              amount += Number(s.amount)
            }
          }
        })
      }
      return amount
    },
    async get_pos_warehouses() {
      if (this.selected_pos) {
        Api(true).post("pos/warehouses/list", { pos_id: this.selected_pos })
          .then(res => {
            this.warehouses_of_pos = res.data
          })
      }
    },
    toggle_stock_modal() {
      this.show_add_stock_modal = !this.show_add_stock_modal
    },
    toggle_stock_info_modal(p, w) {
      this.show_stock_info_modal = !this.show_stock_info_modal
      if (this.show_stock_info_modal) {
        this.selected_stock_product = p
        this.selected_stock_warehouse = w
      }
      else {
        this.selected_stock_product = {}
        this.selected_stock_warehouse = {}
      }
    },

    // show_stock_info_modal
    // available_stock(product){
    //   console.log(product)
    // }

		getAvailableStock(product, warehouse) {
      return product[warehouse.guid] - 
             product[warehouse.guid + '_diary_out'] - 
             product[warehouse.guid + '_out'] || '-';
    },

  },
  computed: {
    warehouses_list() {
      if (this.selected_pos == null) {
        return this.warehouses
      }
      else {
        return this.warehouses.filter(w => {
          var found = false
          this.warehouses_of_pos.map(wp => {
            if (wp.warehouse_id == w.guid) {
              found = true
            }
          })
          return found
        })
        .sort((a, b) => {
          // sort by type_id 
          if(this.warehouses_of_pos.find(w => w.warehouse_id == a.guid).type_id > this.warehouses_of_pos.find(w => w.warehouse_id == b.guid).type_id){
            return 1
          }
          else{
            return -1
          }
        })
      }
    },
    products_list() {
      return [...this.products]
    },
		filtered_products() {
      let filtered = this.products_list;
      
      // Apply stock filter if showAllProducts is false
      if (!this.showAllProducts) {
        filtered = filtered.filter(product => {
          let has_stock = false;
          this.warehouses_list.forEach(w => {
            if (product[w.guid] > 0 || 
                product[w.guid + '_out'] > 0 || 
                product[w.guid + '_in'] > 0 ||
                product[w.guid + '_diary_out'] > 0 || 
                product[w.guid + '_diary_in'] > 0) {
              has_stock = true;
            }
          });
          return has_stock;
        });
      }

      // Apply search filter
      if (this.search_val) {
        filtered = filtered.filter(product => 
          JSON.stringify(product).toLowerCase().includes(this.search_val.toLowerCase())
        );
      }

      // Calculate pagination slice
      const startIndex = (this.currentPage - 1) * this.itemsPerPage;
      const endIndex = startIndex + this.itemsPerPage;
      
      return filtered.slice(startIndex, endIndex);
    },
		totalFilteredProducts() {
      let filtered = [...this.products_list];
      
      // Apply stock filter if showAllProducts is false
      if (!this.showAllProducts) {
        filtered = filtered.filter(product => {
          let has_stock = false;
          this.warehouses_list.forEach(w => {
            if (product[w.guid] > 0 || 
                product[w.guid + '_out'] > 0 || 
                product[w.guid + '_in'] > 0 ||
                product[w.guid + '_diary_out'] > 0 || 
                product[w.guid + '_diary_in'] > 0) {
              has_stock = true;
            }
          });
          return has_stock;
        });
      }

      // Apply search filter
      if (this.search_val) {
        filtered = filtered.filter(product => 
          JSON.stringify(product).toLowerCase().includes(this.search_val.toLowerCase())
        );
      }

      return filtered.length;
    },
    
    totalPages() {
      return Math.max(1, Math.ceil(this.totalFilteredProducts / this.itemsPerPage));
    }
  }
}
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
