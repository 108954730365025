import { defineStore } from "pinia";
import Api from "@/services/Api";
import "@/services/Customer";
import Customer from "../services/Customer";
import { useSearchProcess } from "@/stores/search_process";
import { useProcessStore } from "@/stores/process";
import moment from "moment";
import numeral from "numeral";
import permissionsMixin from '@/mixins/permissions-mixin.js'
import PointsOfSaleServices from "@/services/PointOfSale";
import { useMainSearchStore } from '@/stores/main_search'
// import { useRouter } from 'vue-router';

export const useDiaryStore = defineStore({
  id: "diary",

  state: () => ({
    diaries: [],
    assigned_poses: [],
    customer_create_form: null,
    customers: [],
    loan_institutions: [],
    comments: [],
    products: [],
    diary: {},
    sort: localStorage.sort ? JSON.parse(localStorage.sort) : {},
    previews_diaries: [],
    // Desk process
    desk_processes_loading: false,
    desk_processes: [],
    selected_desk_process: null, // v-model of select
    desk_process: {}, // selected process
    desk_process_edit: false,
    postpone_value: 0,
    postpone_diary_loading: false,
    countdown: "", // Initialize countdown
    intervalId: null,
    loading_request_discount: false,
    // loading_employee_request_discount: false,
    selected_for_discount: [],
    discount_requests: {},

    // Discount list
    discount_list_mode: false,
    discount_request: { head: {}, details: [] },
    employee_discount_mode: 0, // 1: request discount, 2 employee discount mode
    loading_close_discount: false,
    loading_process: false,
    discount_total_mode: false,
    total_discount_value: 0,
    save_total_discount_mode: false,

    // Process
    selected_for_process: [],

    // Filters
    diary_statuses: [],
    diary_filter_status: null,
    diary_filter_text: null,
    show_status_dropdown: false,
    diary_filter_id: null,
    items_per_page: 10,
    current_page: 1,

    // Search item mode
      // 1: Product, 2: Service, 3: free line
    search_item_mode: 1,
    show_search_modes: false,

    // Add service
    service_search_val: '',
    service_search_results: {},

    // Free line (product)
    add_free_line_mode: false,
    new_free_line_description: '',
    free_line_products_loading: false,
    free_line_list: {},
    selected_diary_detail: null,

    show_close_statuses: false,

    show_approvers_list: false,
    selected_approver: null,
    discount_view_table: false,
    process: {
      origin_type: 1,
      origin: ''
    },
    discount_show_history: false,
    all_services: [],
    toggleAddProduct: false,
    
    show_service_modal: false,
    seleced_service_type: '',
    service_selected_product: null,

    // Pickup
    show_pickup_modal: false,
    pickup_selected_product: {},
    pickup_pos_list: [],
    pickup_warehouse_list: [],

    // wait modal
    show_wait_modal: false,
    customer_waiting_mode: false,
    reject_reasons: [],
    customer_waiting_edit_mode: false,
    loading_postpone: false,

    // Manage time modal
    manage_time_mode: 0, // 0:shites, 1:klient

    // Close diary modal
    show_close_modal: false,

    is_process_discount: false
  }),

  getters: {
    current_user() {
      return JSON.parse(localStorage.user);
    },
    diaries_list() {
      var list = [];
      // Filters conditions
      if (this.discount_list_mode) {
        list = this.diaries.filter((a) => a.status_id == 6);
      }
      else if (this.customer_waiting_mode) {
        list = this.diaries.filter((a) => a.requested_by_customer != null && (a.status_id != 5 && a.status_id != 7) );
      }
      else if(this.diary_filter_status){
        list = this.diaries.filter((a) => a.status_id == this.diary_filter_status);
      } 
      else if(this.diary_filter_text){
        const searchTerm = this.diary_filter_text.toLowerCase();
        list = this.diaries.filter(item => {
          // Convert object values to strings and check if searchValue is in them
          const itemValues = Object.values(item).map(value => String(value).toLowerCase());
          return itemValues.some(value => value.includes(searchTerm));
        });
      }
      else if(this.diary_filter_id){
        list = this.diaries.filter((a) => a.id == this.diary_filter_id);
      }
      else {
        list = this.diaries;
      }
      // Pagination 
      list = list.slice((this.current_page - 1) * this.items_per_page, this.current_page * this.items_per_page)
      return list;
    },
    filtersEnabled(){
      return this.diary_filter_status != null || this.diary_filter_text != null
    },
    can_give_discount() {
      var can_give = true;
      this.diary.discount_request.map((d) => {
        if (d.by_employee == this.current_user.id) {
          can_give = false;
        }
      });
      return can_give;
    },
    total_pages() {
      return Math.ceil(this.diaries.length / this.items_per_page)
    },
    diary_totals(){
      var a = {
        total: 0, // totali perpara zbritjes ()
        total_product: 0, // Tot product before discount
        services_total: 0, 

        transport_total: 0,
        mountable_service: 0,

        total_discounted:0, // the amount discounteod (Zbritja e miratuar)
        total_after_discount: 0, // for products (Totali i ditarit pas zbritjes)

        processed_total: 0 // The total of proccessed products
      }

      if(this.diary && this.diary.products){
        this.diary?.products.map(p => {

          if(p.related_entity == 1){
            a.total_product += (parseFloat(p.price) ? parseFloat(p.price) : 0) * parseFloat(p.amount)
            a.total_after_discount += (parseFloat(p.discounted_price) ? parseFloat(p.discounted_price) : parseFloat(p.price) ) * parseFloat(p.amount)
            if(parseFloat(p.discounted_price) > 0){
              a.total_discounted += (parseFloat(p.price) - parseFloat(p.discounted_price))
            }
          }

          if(p.related_entity == 2){
            a.services_total += parseFloat(p.price) ? parseFloat(p.price) : 0
            a.total_after_discount += parseFloat(p.price) ? parseFloat(p.price) : 0
          }

          a.total += (parseFloat(p.price) ? parseFloat(p.price) : 0) * parseFloat(p.amount)

          if(p.process_document_id){
            a.processed_total += parseFloat(p.discounted_price) ? parseFloat(p.discounted_price) : parseFloat(p.price)
          }

          // Transport total
          if(p.service_type == 1){
            a.transport_total += parseFloat(p.price)
          }

          // Mountable total
          if(p.service_type == 2){
            a.mountable_service += parseFloat(p.price)
          }
        })
      }
      return a
    },
    discount_request_diary_totals(){
      var a = {
        total: 0, // totali perpara zbritjes ()
        total_product: 0, // Tot product before discount
        services_total: 0, 

        transport_total: 0,
        mountable_service: 0,

        total_discounted:0, // the amount discounteod (Zbritja e miratuar)
        total_after_discount: 0, // for products (Totali i ditarit pas zbritjes)

        processed_total: 0 // The total of proccessed products
      }

      if(this.discount_request && this.discount_request.details){
        this.discount_request.details.map(p => {

          a.total_product += (parseFloat(p.product_price) ? parseFloat(p.product_price) : 0) * parseFloat(p.product_amount)
          a.total_after_discount += (parseFloat(p.product_discounted_price) ? parseFloat(p.product_discounted_price) : parseFloat(p.product_price) ) * parseFloat(p.product_amount)
          if(parseFloat(p.product_discounted_price) > 0){
            a.total_discounted += (parseFloat(p.product_price) - parseFloat(p.product_discounted_price))
          }

          a.total += (parseFloat(p.product_price) ? parseFloat(p.product_price) : 0) * parseFloat(p.product_amount)
        })
      }
      return a
    },
    active_total(){
      var tot = 0
      this.products.filter(a => a.is_active == 1 && a.related_entity == 1).map(p => {
        if(p.discounted_price > 0){
          tot = tot + parseFloat(p.discounted_price)
        } 
        else {
          tot = tot + parseFloat(p.price)
        }
      })
      return tot
    },
    active_total_no_discount(){
      var tot = 0
      this.products.filter(a => a.is_active == 1 && a.related_entity == 1 ).map(p => {
        tot = tot + parseFloat(p.price)
      })
      return tot
    },
    process_modal_total(){
      var tot = 0
      this.products.filter(a => {
        if(a.is_active == 1 && a.related_entity == 1) return true
        else if(a.related_entity == 2 && a.is_active == 1 && a.parent_entity == null) return true
      }).map(p => {
        if(parseFloat(p.discounted_price) > 0){
          tot = tot + parseFloat(p.discounted_price)
        } 
        else {
          tot = tot + parseFloat(p.price)
        }
        if(p.related_entity == 1 && p.transport_service?.guid) {
          tot = tot + parseFloat(p.transport_service.price)
        }
        if(p.related_entity == 1 && p.mountable_service?.guid) {
          tot = tot + parseFloat(p.mountable_service.price)
        }
      })
      return tot
    },
    process_modal_no_discount(){ 
      var tot = 0
      this.products.filter(a => {
        if(a.is_active == 1 && a.related_entity == 1) return true
        else if(a.related_entity == 2 && a.parent_entity == null) return true
      }).map(p => {
        tot = tot + parseFloat(p.price)

        if (p.transport_service?.guid) {
          tot = tot + parseFloat(p.transport_service.price)
        }
        if (p.mountable_service?.guid) {
          tot = tot + parseFloat(p.mountable_service.price)
        }
      })
      return tot
    },
    average_discount(){
      var a = {
        value: 0,
        percentage: 0
      }
      var before_tot = 0
      var after_tot = 0
      this.products.filter(a => a.is_active == 1 && a.related_entity == 1 ).map(p => {
        before_tot = before_tot + parseFloat(p.price)
        if(p.discounted_price > 0){
          after_tot = after_tot + parseFloat(p.discounted_price)
        }
        else {
          after_tot = after_tot + parseFloat(p.price)
        }
      })
      a.percentage = 100 - ((parseFloat(after_tot) * 100)/parseFloat(before_tot))
      a.value = parseFloat(before_tot) - parseFloat(after_tot)
      return a
    },
    have_discount(){
      var a = false
      this.products.map(p => {
        if(p.discounted_price > 0) a = true
      })
      return a
    },
    can_edit_employee_discount() {
      var a = true
      this.diary.discount_request.map((d) => {
        if (d.by_employee == 0 || d.by_employee != this.current_user.id) {
          a = false;
        }
      });
      return a
    },
    can_confirm_pickup() {
      var a = true
      if ((this.pickup_selected_product.pickup_service.pickup_type == 1 || this.pickup_selected_product.pickup_service.pickup_type == 3) && !this.pickup_selected_product.pickup_service.pickup_date) {
        a = false
      }
      else if (
        this.pickup_selected_product.pickup_service.pickup_type == 2 &&
        (!this.pickup_selected_product.pickup_service.pickup_date ||
        !this.pickup_selected_product.pickup_service.pickup_pos)
      ){
        a = false
      }
      return a
    },
    selected_pos_name() {
      var a = ''
      a = this.assigned_poses.filter(i => i.id == this.diary?.pos_id )
      // console.log('...', this.assigned_poses)
      return a
    },
    can_give_total_discount() {
      return true
    }
  },

  actions: {
    async createDiary(form) {
      await Api(true)
        .post("/diary/create", form)
        .then((res) => {
          this.diary = res.data;
          this.products = []
        });
      return this.diary;
    },
    async editDiary(payload) {
      this.diary = null;
      await Api(true)
        .post("/diary/edit", payload)
        .then((res) => {
          this.diary = res.data;
          // ser origin of process
          this.process.origin = res.data.pos_id
        });
      return this.diary;
    },
    async getDiary(id) {
      await Api(true)
        .post("/diary/get", { id })
        .then((res) => {
          this.diary = res.data;
          this.comments = res.data.comments;
          if (res.data.products.length > 0) {
            this.products = [...[]]
            this.products = [...res.data.products];

            this.products.map(p => {
              res.data.products.map(r => {
                if (r.parent_entity == p.guid) {
                  if(r.service_type == 1 && !r.pickup_type){
                    p.has_transport = 1
                    p.transport_service_temp = null
                    p.transport_service = {}
                    p.transport_service.guid = r.entity_id
                    p.transport_service.detail_guid = r.guid
                    p.transport_service.name = r.service_name
                    p.transport_service.code = r.service_code
                    p.transport_service.price = r.service_price
                    p.transport_service.requested_at = r.requested_at
                    p.transport_service.detail_guid = r.guid
                    p.transport_service.contact = r.service_contact
                    p.transport_service.address = r.service_address
                    p.transport_service.already_exist = true
                    p.transport_service.info_expanded = false
                  }
                  else if(r.service_type == 2){
                    p.has_installation = 1
                    p.mountable_service = {}
                    p.mountable_service.guid = r.entity_id
                    p.mountable_service.detail_guid = r.guid
                    p.mountable_service.name = r.service_name
                    p.mountable_service.code = r.service_code
                    p.mountable_service.price = r.service_price
                    p.mountable_service.requested_at = r.requested_at
                    p.mountable_service.detail_guid = r.guid
                    p.mountable_service.contact = r.service_contact
                    p.mountable_service.address = r.service_address
                    p.mountable_service.already_exist = true
                    p.mountable_service.info_expanded = false

                  }
                  else if(r.service_type == 9){
                    p.has_reservation = 1
                    p.reservation_service = {}
                    p.reservation_service.guid = r.entity_id
                    p.reservation_service.detail_guid = r.guid
                    p.reservation_service.name = r.service_name
                    p.reservation_service.code = r.service_code
                    p.reservation_service.price = r.service_price
                    p.reservation_service.requested_at = r.requested_at
                    p.reservation_service.detail_guid = r.guid
                    p.reservation_service.contact = r.service_contact
                    p.reservation_service.address = r.service_address
                    p.reservation_service.already_exist = true
                    p.reservation_service.info_expanded = false

                  }
                  else if(r.service_type == 1 && r.pickup_type != null){
                    p.has_pickup = 1
                    p.pickup_service = {...r}
                    p.pickup_service.pickup_guid = r.guid
                    p.pickup_service.service_type = r.service_type
                    p.pickup_service.pickup_type = r.pickup_type
                    p.pickup_service.pickup_type_original = r.pickup_type
                    p.pickup_service.pickup_warehouse = r.pickup_warehouse
                    p.pickup_service.pickup_warehouse_original = r.pickup_warehouse
                    p.pickup_service.pickup_pos = r.pickup_pos
                    p.pickup_service.pickup_pos_original = r.pickup_pos
                    p.pickup_service.pickup_date = r.pickup_date
                    p.pickup_service.pickup_text = r.pickup_text
                    p.pickup_service.same_location = r.same_location
                    p.pickup_service.same_location_original = r.same_location
                  }
                }
                p.change_amount = false
                p.original_amount = p.amount
              })
              return p
            })
          }
          this.previews_diaries = res.data.previews_diaries;
          // Discount requests
          if (res.data.discount_request?.length > 0) {
            res.data.discount_request.map((r) => {
              if (!this.discount_requests[r.guid])
                this.discount_requests[r.guid] = { head: {}, details: [] };
              this.discount_requests[r.guid]["head"] = r;
              res.data.discount_request_details.map((d) => {
                if (d.discount_request_id == r.guid) {
                  this.discount_requests[r.guid]["details"].push(d);
                }
              });
            });
          }
        });
      return this.diary;
    },
    async getDiaries(payload) {
      await Api(true)
        .post("/diary/list", payload)
        .then((res) => {
          this.diaries = res.data;
          // sort by sort
          Object.keys(this.sort).forEach((key) => {
            this.sortDiariesBy(key);
          });
        });
      return this.diaries;
    },
    async getAssignedPoses() {
      await Api(true)
        .post("/invoice/get/my/pos", {})
        .then((res) => {
          this.assigned_poses = [...res.data];
        });
    },
    async getCustomers() {
      this.customers = await Customer.getCustomers();
    },
    async getLoanInstitutions() {
      return await Api(true)
        .post("diary/loan/institutions", {})
        .then((res) => {
          this.loan_institutions = res.data;
        });
    },
    async addComment(comment) {
      if (this.diary) {
        Api(true)
          .post("diary/comment/add", { diary_id: this.diary.guid, comment })
          .then((res) => {
            this.comments.unshift(res.data);
          });
      }
    },
    isSameDayAsToday(date) {
      const today = new Date();
      date = new Date(date);
      return (
        date.getDate() == today.getDate() && date.getMonth() == today.getMonth()
      );
    },
    levenshtein(a, b) {
      const an = a.length;
      const bn = b.length;
      const matrix = new Array(an + 1);

      for (let i = 0; i <= an; i++) {
        matrix[i] = new Array(bn + 1);
        matrix[i][0] = i;
      }

      for (let j = 1; j <= bn; j++) {
        matrix[0][j] = j;
      }

      for (let i = 1; i <= an; i++) {
        for (let j = 1; j <= bn; j++) {
          const substitutionCost = a[i - 1] === b[j - 1] ? 0 : 1;
          matrix[i][j] = Math.min(
            matrix[i - 1][j] + 1, // deletion
            matrix[i][j - 1] + 1, // insertion
            matrix[i - 1][j - 1] + substitutionCost // substitution
          );
        }
      }

      return matrix[an][bn];
    },
    sortBy(key) {
      // sort diary by key storing sort direction per key
      if (this.sort[key] == "asc") {
        this.sort[key] = "desc";
        this.diaries.sort((a, b) => {
          return a[key] > b[key] ? 1 : -1;
        });
      } else {
        this.sort[key] = "asc";
        this.diaries.sort((a, b) => {
          return a[key] < b[key] ? 1 : -1;
        });
      }

      localStorage.sort = JSON.stringify(this.sort);
    },
    sortDiariesBy(key) {
      this.diaries.sort((a, b) => {
        return a[key] > b[key] ? 1 : -1;
      });
    },
    async expandProduct(product) {
      var extended_data = null;
      await Api(true)
        .post("/search/expanded/data", {
          product: product,
          pos_id: this.diary.pos_id
        })
        .then((res) => {
          extended_data = res.data;
        });
      return extended_data;
    },
    add_diary_product(product, diary_id) {
      if (product.amount <= 0) { // Out of stock product
        return Api(true)
          .post("/diary/add/product/nostock", { product, diary_id, diary: this.diary })
          .then(() => {
            // this.products = [...[]];
            // this.products = [...res.data];
            this.getDiary(this.diary.guid)
            return { toast: "success" };
          })
          .catch((error) => {
            // Handle errors if needed
            throw error; // Re-throw the error to propagate it to the caller
          });
      } else { // Add product with stock
        product.amount = 1 // Make amount 1 to fix the product.amount bug
        return Api(true)
          .post("/diary/add/product", { product, diary_id, diary: this.diary })
          .then(() => {
            this.getDiary(this.diary.guid)
            return { toast: "success" };
          })
          .catch((error) => {
            // Handle errors if needed
            throw error; // Re-throw the error to propagate it to the caller
          });
      }
    },
    async toggle_pickup_modal(product = null) {
      if (product && product?.is_active < 2) {
        this.show_pickup_modal = !this.show_pickup_modal
        if (this.show_pickup_modal) {
          if(!product.pickup_service) product.pickup_service = {}
          if (!product.pickup_service.pickup_type) {
            product.pickup_service.pickup_type = 1
            product.pickup_service.pickup_warehouse = 0
            product.pickup_service.pickup_pos = 0
          }
          this.pickup_selected_product = product
          // Get POS and Warehouse list
          var pickup_list = await PointsOfSaleServices.getPointsOfSale()
          this.pickup_pos_list = pickup_list.filter(a => a.guid != this.diary.pos_id)
          var all_warehouses = await PointsOfSaleServices.getWarehouses()
          this.pickup_warehouse_list = all_warehouses.filter(a => {
            if (a.can_pickup == 1) {
              return true
            }
          })
        }
        else {
          this.pickup_selected_product = {}
        }
      }
      else {
        this.show_pickup_modal = false
      }
    },
    async save_pickup_location() {
      var toast = {}
      await Api(true).post("/diary/save/pickup", {
        product: this.pickup_selected_product,
        diary: this.diary
      })
      .then(res => {
        if(res.data == 1){
          this.getDiary(this.diary.guid)
          toast.message = 'success'
          this.show_pickup_modal = false
        }
      })
      return toast
    },
    async delete_product_pickup(product) {
      var toast = {}
      if (confirm("Doni te hiqni pickup nga ky produykt?")) {
        await Api(true).post("/diary/delete/product/pickup", {
          pickup_id: product.pickup_service.pickup_guid,
          diary_id: product.diary_id
        })
        .then(res => {
          if (res.data == 1) {
            this.getDiary(this.diary.guid)
            // Show toaster
            toast.message = 'success'
          }
        })
      }
      return toast
    },
    async get_desk_processes() {
      this.desk_processes_loading = true;
      await Api(true)
        .post("/diary/get/desk/processes")
        .then((res) => {
          this.desk_processes = res.data;
        });
      this.desk_processes_loading = false;
    },
    select_desk_process() {
      this.desk_processes.map((pr) => {
        if (this.selected_desk_process.guid == pr.guid) {
          this.desk_process = { ...pr };
          this.desk_process_edit = false;
        }
      });
    },
    toggle_result_active(product, accessory) {
      useSearchProcess().search_results = [
        ...useSearchProcess().search_results.map((p) => {
          if (p.guid == product.guid) {
            if (accessory) {
              p.accesories = p.accesories.map((acc) => {
                if (acc.guid == accessory.guid) {
                  acc.is_active = !acc.is_active;
                }
                return acc;
              });
            } else {
              p.is_active = !p.is_active;
            }
          }
          return p;
        }),
      ];
    },
    async toggle_diary_product_active(product) {
      var rr = {};
      this.products = [
        ...this.products.map((p) => {
          if (product.guid == p.guid) {
            p.loading_is_active = 1;
          }
          return p;
        }),
      ];
      await Api(true)
        .post("/diary/detail/toggle/active", { detail: product })
        .then((res) => {
          this.products = this.products.map((p) => {
            if (product.guid == p.guid) {
              if (res.data == 1) {
                p.is_active = !p.is_active;
                rr = { toast: "show", is_active: p.is_active };
              }
              p.loading_is_active = false;
            }
            return p;
          });
        })
        .catch(() => {
          rr = { toast: "request_error" };
          // if (error.response && error.response.status === 500) {
          //   rr = {toast: 'request_error_500'}
          // }
        });
      // handle the request error (error 500)
      return rr;
    },
    async remove_diary_product(p) {
      var rr = null;
      if (confirm("Doni te hiqni kete produkt nga ditari?")) {
        // Loading delete
        this.products = this.products.map((pr) => {
          if (p.guid == pr.guid) {
            pr.loading_delete = true
          }
          return pr
        })
        await Api(true)
          .post("/diary/remove/detail", { detail: p })
          .then((res) => {
            if (res.data > 0) {
              this.products = [
                ...this.products.filter((prod) => prod.guid != p.guid),
              ];
              this.getDiary(this.diary.guid)
              rr = { toast: "success" };
            }
          })
          .catch((error) => {
          // Handle errors if needed
          this.products = this.products.map((pr) => {
            if (p.guid == pr.guid) {
              pr.loading_delete = false
            }
            return pr
          })
          throw error; // Re-throw the error to propagate it to the caller
        });
      }
      return rr;
    },
    process_to_desk() {
      this.loading_process = true;
      if (confirm("Doni te procesoni produktet e zgjedhura?")) {
        var toaster = null
        Api(true)
          .post("/diary/start/process", {
            diary: this.diary,
            diary_details: this.selected_for_process,
            selected_process: this.selected_desk_process.guid,
          })
          .then(res => {
            if (res.data.message == 'diary_expired') {
              // return toaster
              toaster = { toast: "success" };
            }
            else {
              window.location.reload();
            }
          })
          .catch(() => {
            // window.location.reload();
          });
        return toaster;
      }
    },
    async postpone_diary() {
      this.postpone_diary_loading = true;
      var rr = null;
      await Api(true)
        .post("/diary/postpone", {
          diary: this.diary,
          value: this.postpone_value,
        })
        .then((res) => {
          if (res.data.updated == 1) {
            rr = { toast: "success" };
            this.diary.seconds_left[0].seconds_difference =
              res.data.seconds_left[0].seconds_difference;
            this.updateCountdown();
            // window.location.reload();
            this.getDiary(this.diary.guid)
          }
          this.postpone_diary_loading = false;
          this.postpone_value = 0;
        });
      return rr;
    },
    // Count doun - Valid until
    startCountdown() {
      // Update countdown every second
      this.updateCountdown();
      this.intervalId = setInterval(() => this.updateCountdown(), 1000);
    },
    stopCountdown() {
      // Clear interval to stop the countdown
      clearInterval(this.intervalId);
    },
    updateCountdown() {
      // Check if the necessary properties exist
      // var value = this.diary.seconds_left[0].seconds_difference
      if ( this.diary &&
        this.diary?.seconds_left &&
        this.diary.seconds_left.length > 0 &&
        this.diary.seconds_left[0].seconds_difference !== undefined
      ) {
        this.diary.seconds_left[0].seconds_difference =
          this.diary.seconds_left[0].seconds_difference - 1;
        var secondsDifference = this.diary.seconds_left[0].seconds_difference;

        // Calculate days, hours, minutes, and seconds
        const days = Math.floor(secondsDifference / (60 * 60 * 24));
        const hours = Math.floor(
          (secondsDifference % (60 * 60 * 24)) / (60 * 60)
        );
        const minutes = Math.floor((secondsDifference % (60 * 60)) / 60);
        const seconds = secondsDifference % 60;

        // Update the countdown string
        this.countdown = `${days}d ${hours}h ${minutes}m ${seconds}s`;
      } else {
        // Handle the case where necessary properties are not available
        this.countdown = "0d 0h 0m 0s"; // or any other default value
      }
    },
    // Discount
    toggle_discount_product(product) {
      if (this.selected_for_discount.includes(product)) {
        // Product is already in the array, so remove it
        this.selected_for_discount = this.selected_for_discount.filter(
          (p) => p !== product
        );
      } else {
        // Product is not in the array, so add it
        this.selected_for_discount.push(product);
      }
    },
    toggle_process_product(product) {
      if (this.selected_for_process.includes(product)) {
        // Product is already in the array, so remove it
        this.selected_for_process = this.selected_for_process.filter(
          (p) => p !== product
        );
      } else {
        // Product is not in the array, so add it
        this.selected_for_process.push(product);
      }
    },
    async request_disctount(is_process = false) {
      this.loading_request_discount = true;
      await setTimeout(() => {}, 3000);
      await Api(true)
        .post("/diary/request/discount", {
          diary: is_process ? useProcessStore().process.document_data : this.diary,
          details: this.selected_for_discount,
          postpone_value: this.postpone_value,
          is_process: is_process
        })
        .then((res) => {
          if (res.data == 1) {
            window.location.reload();
          }
        })
        .catch(() => {
          this.loading_request_discount = false
          window.location.reload();
        });
      // Refres after
    },
    async employee_request_discount(is_process = false) {
      this.loading_request_discount = true;
      this.selected_for_discount = this.selected_for_discount.map(p => {
        p.discounted_price = p.temp_discounted_price
        return p
      })

      await Api(true)
        .post("/diary/request/discount", {
          diary: is_process ? useProcessStore().process.document_data : this.diary,
          details: this.selected_for_discount,
          postpone_value: this.postpone_value,
          employee_discount_mode: this.employee_discount_mode,
          is_process: is_process ? 1 : 0
        })
        .then(() => {
          // window.location.reload();
        })
        .catch(() => {
          // window.location.reload();
        });
    },
    is_expired(date) {
      const currentDate = new Date();
      const validUntilDate = new Date(date);
      // Compare the dates
      return currentDate > validUntilDate;
    },
    get_pos_diary_discount_list() {
      Api(true).post("/diary/discount/list", {});
    },
    async get_diary_discount_request(id, is_process = false) {
      await Api(true)
        .post("/diary/get/discount", { request_id: id, is_process: is_process ? 1 : 0 })
        .then((res) => {
          // this.discount_request = {...{}}
          this.discount_request.details = [];
          this.discount_request.details = [...res.data.details];
          this.discount_request.head = res.data.head[0];
        });
    },
    async accept_discount(request_detail, is_process = false) {
      var r = {};
      await Api(true)
        .post("/diary/accept/discount", { request_detail, is_process })
        .then((res) => {
          if (res.data.updated == 1) {
            r = { toaster: "success" };
            this.discount_request.details = this.discount_request.details.map(
              (a) => {
                if (a.guid == res.data.updated_request_detail.guid) {
                  a.discount_status = 2;
                  a.updated_request_detail =
                    res.data.updated_request_detail.updated_request_detail;
                  a.discounted_value =
                    res.data.updated_request_detail.discounted_value;
                  a.discounted_percent =
                    res.data.updated_request_detail.discounted_percent;
                }
                return a;
              }
            );
          }
        });
      return r;
    },
    async decline_discount_detail(request_detail) {
      var r = {};
      await Api(true)
        .post("/diary/decline/discount/detail", { request_detail })
        .then((res) => {
          if (res.data == 1) {
            r = { toaster: "success" };
            this.discount_request.details = this.discount_request.details.map(
              (a) => {
                if (a.guid == request_detail.guid) {
                  a.discount_status = 3;
                  a.discount_value = null;
                }
                return a;
              }
            );
          }
        });
      return r;
    },
    toggle_discount_type(detail) {
      this.discount_request.details = this.discount_request.details.map((a) => {
        if (a.guid == detail.guid) {
          if (a.discount_type == 1) a.discount_type = 0;
          else a.discount_type = 1;

          // Reset the discount_value
          a.discount_value = null
        }
        return a;
      });
    },
    price_after_discount(detail) {
      var total = 0;
      if (detail.discount_value) {
        if (detail.discount_type == 0) {
          // Calculate with currency
          if (detail.discount_value) {
            total = parseFloat(detail.product_value) - parseFloat(detail.discount_value);
          } else {
            total = 0;
          }
        } else if (detail.discount_type == 1) {
          // Calculate with %
          if (detail.discount_value) {
            var discountPercentage = parseFloat(detail.discount_value) / 100;
            total =
              parseFloat(detail.product_value) - parseFloat(detail.product_value) * discountPercentage;
          } else {
            total = 0;
          }
        }
      } else {
        total = parseFloat(detail.product_value);
      }
      return total;
    },
    async get_customer_previews_diaries(customer_id, diary_id) {
      await Api(true)
        .post("/diary/customer/previews", { customer_id, diary_id })
        .then((res) => {
          this.previews_diaries = res.data;
        });
    },
    async close_discount_request(is_process = false) {
      this.loading_close_discount = true;
      await Api(true)
        .post("/diary/close/discount/request", {
          discount_request: this.discount_request,
          is_process: is_process
        })
        .then((res) => {
          if (res.data == 1) {
            window.location.reload();
          } else {
            this.loading_close_discount = false;
          }
        })
        .catch(() => {
          window.location.reload();
        });
    },
    async reject_discount_request(is_process = false) {
      if (confirm("Doni te refuzoni kete kerkese?")) {
        await Api(true)
          .post("/diary/reject/discount/request", {
            discount_request: this.discount_request,
            is_process: is_process
          })
          .then((res) => {
            if (res.data == 1) {
              window.location.reload();
            }
          });
      }
    },
    formatDate(date) {
      return moment(date, "YYYY-MM-DD HH:mm:ss").format("DD.MM.YYYY HH:mm");
    },
    calc_prod_discount_value(product) {
      this.products = this.products.map((p) => {
        if (product.guid == p.guid) {

          if(product.employee_discount_type == 1){ // If discount is %
            // Check if max_employee_discount is greater than employee_discount_value.
            if(p.employee_discount_value > this.diary.max_employee_discount){
              p.employee_discount_value = this.diary.max_employee_discount
              this.calc_prod_discount_value(p)
            }
            // Calculate the discount if 
            if(p.discounted_price == 0){
              p.temp_discounted_price =
                parseFloat(product.price) -
                (parseFloat(product.employee_discount_value) * parseFloat(product.price)) / 100;
            }
            else { // If already has discount
              //  Check if actual discount is greater than max_discount
              p.actual_discount = ((parseFloat(p.price) - parseFloat(p.discounted_price))/parseFloat(p.price)) * 100
              if(p.actual_discount > product.employee_discount_value){
                p.discount_warning = true
                p.temp_discounted_price = null
              } else {
                p.discount_warning = false
                // Ok to change discounted price. This will be saved
                if(!p.original_discounted_price){
                  p.original_discounted_price = parseFloat(p.discounted_price)
                }
                p.temp_discounted_price =
                  parseFloat(product.price) -
                (parseFloat(product.employee_discount_value) * parseFloat(product.price)) / 100;
              }
            }
          }

          else if(product.employee_discount_type == 0){ // If employee_discount_type is currency value
            // Check if max_employee_discount is greater than employee_discount_value.
            
            // Convert currency value to percentage
            const percentageDiscount = (parseFloat(p.employee_discount_value) / parseFloat(p.price)) * 100;
            
            // Check if max_employee_discount is greater than employee_discount_value.
            if (percentageDiscount > this.diary.max_employee_discount) {
              // Calculate the maximum discount in currency value based on the product price and max_employee_discount
              const maxDiscountPercentage = parseFloat(this.diary.max_employee_discount);
              const productPrice = parseFloat(p.price);
              // Calculate the maximum discount in currency value
              const maxDiscountCurrency = (maxDiscountPercentage / 100) * productPrice;
              // Set the employee_discount_value to the calculated maximum discount
              p.employee_discount_value = maxDiscountCurrency;
              // Recursively call the function to recalculate with the updated discount value
              this.calc_prod_discount_value(p);
              return p
            }

            // Calculate the discount in percentage
            if (p.discounted_price == 0) {
              // Calculate the discount if no existing discount
              p.temp_discounted_price = parseFloat(product.price) - ((percentageDiscount * parseFloat(product.price)) / 100);
            } else { // If already has discount
              // Check if actual discount is greater than max_discount
              p.actual_discount = ((parseFloat(p.price) - parseFloat(p.discounted_price)) / parseFloat(p.price)) * 100;
              if (p.actual_discount > percentageDiscount) {
                p.discount_warning = true;
                p.temp_discounted_price = null;
              } else {
                p.discount_warning = false;
                // Ok to change discounted price. This will be saved
                if (!p.original_discounted_price) {
                  p.original_discounted_price = parseFloat(p.discounted_price);
                }
                p.temp_discounted_price = parseFloat(product.price) - (percentageDiscount * parseFloat(product.price)) / 100;
              }
            }

          }
        }
        return p;
      });
    },
    // calc_prod_discount_value_request(product) {
    //   this.products = this.products.map((p) => {
    //     if (product.guid == p.guid) {

    //       if(product.employee_discount_type == 1){ // If discount is %
    //         // Check if max_employee_discount is greater than discount_value.
    //         if(p.discount_value > this.diary.max_employee_discount){
    //           p.discount_value = this.diary.max_employee_discount
    //           this.calc_prod_discount_value(p)
    //         }
    //         // Calculate the discount if 
    //         if(p.discounted_price == 0){
    //           p.discounted_price =
    //             parseFloat(product.price) -
    //             (parseFloat(product.discount_value) * parseFloat(product.price)) / 100;
    //         }
    //         else { // If already has discount
    //           //  Check if actual discount is greater than max_discount
    //           p.actual_discount = ((parseFloat(p.price) - parseFloat(p.discounted_price))/parseFloat(p.price)) * 100
    //           if(p.actual_discount > product.discount_value){
    //             p.discount_warning = true
    //             p.discounted_price = null
    //           } else {
    //             p.discount_warning = false
    //             // Ok to change discounted price. This will be saved
    //             if(!p.original_discounted_price){
    //               p.original_discounted_price = parseFloat(p.discounted_price)
    //             }
    //             p.discounted_price =
    //               parseFloat(product.price) -
    //             (parseFloat(product.discount_value) * parseFloat(product.price)) / 100;
    //           }
    //         }
    //       }

    //       else if(product.employee_discount_type == 0){ // If employee_discount_type is currency value
    //         // Check if max_employee_discount is greater than discount_value.
            
    //         // Convert currency value to percentage
    //         const percentageDiscount = (parseFloat(p.discount_value) / parseFloat(p.price)) * 100;
            
    //         // Check if max_employee_discount is greater than discount_value.
    //         if (percentageDiscount > this.diary.max_employee_discount) {
    //           // Calculate the maximum discount in currency value based on the product price and max_employee_discount
    //           const maxDiscountPercentage = parseFloat(this.diary.max_employee_discount);
    //           const productPrice = parseFloat(p.price);
    //           // Calculate the maximum discount in currency value
    //           const maxDiscountCurrency = (maxDiscountPercentage / 100) * productPrice;
    //           // Set the discount_value to the calculated maximum discount
    //           p.discount_value = maxDiscountCurrency;
    //           // Recursively call the function to recalculate with the updated discount value
    //           this.calc_prod_discount_value(p);
    //           return p
    //         }

    //         // Calculate the discount in percentage
    //         if (p.discounted_price == 0) {
    //           // Calculate the discount if no existing discount
    //           p.discounted_price = parseFloat(product.price) - (percentageDiscount * parseFloat(product.price)) / 100;
    //         } else { // If already has discount
    //           // Check if actual discount is greater than max_discount
    //           p.actual_discount = ((parseFloat(p.price) - parseFloat(p.discounted_price)) / parseFloat(p.price)) * 100;
    //           if (p.actual_discount > percentageDiscount) {
    //             p.discount_warning = true;
    //             p.discounted_price = null;
    //           } else {
    //             p.discount_warning = false;
    //             // Ok to change discounted price. This will be saved
    //             if (!p.original_discounted_price) {
    //               p.original_discounted_price = parseFloat(p.discounted_price);
    //             }
    //             p.discounted_price = parseFloat(product.price) - (percentageDiscount * parseFloat(product.price)) / 100;
    //           }
    //         }

    //       }
    //     }
    //     return p;
    //   });
    // },
    calc_prod_discount_value_1(product) {
      console.log('product', product)
      this.discount_request.details = this.discount_request.details.map((p) => {
        if (product.guid == p.guid) {
          console.log('p', p)
          if(product.discount_type == 1){ // If discount is %
            // Check if max_employee_discount is greater than employee_discount_value.
            if (parseInt(p.discount_value) > this.discount_request?.head.max_employee_discount) {
              p.discount_value = this.discount_request?.head.max_employee_discount
              this.calc_prod_discount_value_1(p)
            }
            // Calculate the discount if 
            if(p.discounted_price == 0){
              p.temp_discounted_price =
                parseInt(product.product_value) -
                (parseInt(product.discount_value) * parseInt(product.product_value)) / 100;
            }
            else { // If already has discount
              //  Check if actual discount is greater than max_discount
              p.actual_discount = ((parseInt(p.product_value) - parseInt(p.discounted_price))/parseInt(p.product_value)) * 100
              if(p.actual_discount > product.discount_value){
                p.discount_warning = true
                p.temp_discounted_price = null
              } else {
                p.discount_warning = false
                // Ok to change discounted price. This will be saved
                if(!p.original_discounted_price){
                  p.original_discounted_price = parseInt(p.discounted_price)
                }
                p.temp_discounted_price =
                  parseInt(product.product_value) -
                (parseInt(product.employee_discount_value) * parseInt(product.product_value)) / 100;
              }
            }
          }
          else if(product.discount_type == 0){ // If discount_type is currency value
            // Check if max_employee_discount is greater than discount_value.
            
            // Convert currency value to percentage
            var percentageDiscount = 0
            percentageDiscount = (parseInt(product.discount_value) / parseInt(product.product_price)) * 100;
            
            // Check if max_employee_discount is greater than discount_value.
            if(parseInt(percentageDiscount) > this.discount_request?.head.max_employee_discount){
              // Calculate the maximum discount in currency value based on the product price and max_employee_discount
              const maxDiscountPercentage = parseInt(this.discount_request?.head.max_employee_discount);
              const productPrice = parseInt(p.product_price);
              // Calculate the maximum discount in currency value
              const maxDiscountCurrency = (maxDiscountPercentage / 100) * productPrice;
              // Set the discount_value to the calculated maximum discount
              p.discount_value = maxDiscountCurrency;
              // Recursively call the function to recalculate with the updated discount value
              this.calc_prod_discount_value_1(p);
              return p
            }

            // Calculate the discount in percentage
            if (p.discounted_price == 0) {
              // Calculate the discount if no existing discount
              p.temp_discounted_price = parseInt(product.product_value) - (percentageDiscount * parseInt(product.product_value)) / 100;
            } else { // If already has discount
              // Check if actual discount is greater than max_discount
              p.actual_discount = ((parseInt(p.product_value) - parseInt(p.discounted_price)) / parseInt(p.product_value)) * 100;
              if (p.actual_discount > percentageDiscount) {
                p.discount_warning = true;
                p.temp_discounted_price = null;
              } else {
                p.discount_warning = false;
                // Ok to change discounted price. This will be saved
                if (!p.original_discounted_price) {
                  p.original_discounted_price = parseInt(p.discounted_price);
                }
                p.temp_discounted_price = parseInt(product.product_value) - (percentageDiscount * parseInt(product.product_value)) / 100;
              }
            }

          }
        }
        return p;
      });
    },

    product_discount_value(p){
      var a = 0
      a = ((p.price - p.discounted_price)/p.price) * 100
      return a
    },
    formatPercentage(value) {
      // Convert the string to a float
      const floatValue = parseFloat(value);

      // Check if the converted value is a valid number
      if (!isNaN(floatValue)) {
        // Format the number as a percentage with two decimal places
        return numeral(floatValue).format("0.00%");  // Updated format string
      }

      // If the value is not a number, return it as is
      return floatValue;
      // // Convert the string to a float
      // const floatValue = parseFloat(value);
      // // Check if the converted value is a valid number
      // if (!isNaN(floatValue)) {
      //   // Format the number as a percentage with one decimal place
      //   return numeral(floatValue / 100).format("0.00%");
      // }
      // // If the value is not a number, return it as is
      // return value;
    },
    price_filter(price) {
      return numeral(price).format('0,0') + ' Lek'
    },
    price_value_filter(price) {
      return numeral(price).format('0.00')
    },
    amount_filter(price) {
      return numeral(price).format('0,0')
    },
    async get_diary_statuses() {
      await Api(true).post("/diary/statuses")
        .then((res) => {
          this.diary_statuses = res.data;
        });
    },
    async filter_diaries(){
      await Api(true).post("/diary/filter", {
        diary_filter_status: this.diary_filter_status,
        diary_filter_text: this.diary_filter_text,
      })
    },
    resetFilters(){
      this.diary_filter_status = null
      this.diary_filter_text = null
    },
    change_page(page) {
      this.current_page = page
    },
    toggle_free_line_mode(){
      this.add_free_line_mode = !this.add_free_line_mode
    },
    async create_free_line(){
      var rr = null
      await Api(true).post("/diary/create/free/line", {
        diary_id: this.diary.guid,
        free_line: this.new_free_line_description
      })
      .then(r => {
        if(r.data.length > 0){
          this.getDiary(this.diary.guid)
          // this.products = [...r.data];
          // TODO: Close search modal
          this.new_free_line_description = ""
          this.search_item_mode = 1
          rr = 'success'
        }
      })
      return rr
    },
    async get_free_products(){
      await Api(true).post("/diary/get/free/line", {})
      .then(res => {
        if(res.data.length > 0){
          res.data.map(rr => {
            if(!this.free_line_list[rr.diary_guid]) this.free_line_list[rr.diary_guid] = []
            this.free_line_list[rr.diary_guid].push(rr)
          })
        }
        this.free_line_products_loading = false
      })
    },
    async replace_free_product(result){
      var resp = null
      await Api(true).post("/diary/replace/free/line", {
        result,
        diary_detail: this.selected_diary_detail
      })
      .then(r => {
        if(r.data == 1){
          // window.location.reload();
          this.free_line_list[this.selected_diary_detail.diary_guid] = this.free_line_list[this.selected_diary_detail.diary_guid].filter(a => a.detail_guid != this.selected_diary_detail.detail_guid)
          // if is the last detail, remove diary
          if(this.free_line_list[this.selected_diary_detail.diary_guid].length == 0){
            delete this.free_line_list[this.selected_diary_detail.diary_guid]
          }
          this.selected_diary_detail = null
          resp = {toast: 'success'}
        }
      })
      return resp
    },
    async close_diary(status){
      if (confirm("Doni te mbyllni kete ditar?")) {
        this.loading = true
        var a = null
        await Api(true).post("/diary/close/diary", {
          diary: this.diary,
          status
        }).then(res => {
          if(res.data > 0){

            // const router = useRouter();
            // Assuming 'Ditar Shiko' is the name of your route
            // router.push({ name: 'Ditar Shiko', params: { id: res } });
            // router.push({ name: 'Ditar te Hapur', path: '/diary/list' });
             
            this.loading = false
            this.show_close_statuses = false
            a = 'deleted_success'
          }
        })
        return a
      }
    },
    toggle_employee_discount_type(product){
      this.products = [...this.products.map(p => {
        if(p.guid == product.guid){
          if (p.employee_discount_type == 0) { p.employee_discount_type = 1 }
          else { p.employee_discount_type = 0 }
          p.employee_discount_value = null
          this.calc_prod_discount_value(p)
        }
        return p
      })]
    },
    cancel_employee_discount() {
      this.employee_discount_mode = 0
      this.products = [...this.products.map(p => {
        p.employee_discount_value = null
        this.calc_prod_discount_value(p)
        return p
      })]
    },
    async start_request_discount(is_process = false){
      // Get list of discount approvers
      await Api(true).post("/diary/discount/approvers", {
        current_max_discount: is_process ? useProcessStore().process.document_data.max_employee_discount : this.diary.max_employee_discount
      })
      .then(res => {
        this.discount_approvers = res.data
      })
      this.employee_discount_mode = 1
    },
    toggle_approvers_list(){
      this.show_approvers_list = !this.show_approvers_list

    },
    set_diary_approver(user, is_process){
      if (is_process) {
        useProcessStore().process.document_data.discount_assigned_to = user.id
      } else {
        this.diary.discount_assigned_to = user.id
      }
      this.selected_approver = user
      this.show_approvers_list = false

    },
    async save_warehouse_change(product){
      setTimeout(async () =>{
        return await Api(true).post("/diary/save/warehouse/change", { product })
          .then(res => {
            this.products = this.products.map(p => {
              if(p.guid == res.data[0].guid){
                // res.data[0].product_id = p.guid
                p = res.data[0]
              }
              return p
            })
          })
      }, 200);
    }, 
    async getOtherWarehousesOf(product){ 
      // delete product.loaded_other_warehouse
      product.other_warehouse = []
      await Api(true).post("/diary/get/other/warehouses", { product })
      .then(res => {
          this.products = this.products.map(p => {
            if(p.guid == product.guid){
              p.other_warehouse = res.data
              p.from_other_warehouse_id = ''
              p.loaded_other_warehouse = true
            }
            return p
          })
      })
    },
    // save_diary_detail_service(product, type){
    //   if(type == 'transport'){
    //     product.transport_service = {...{}}
    //     product.transport_service = {...product.transport_service_temp}
    //   } else if (type == 'mountable') {
    //     product.mountable_service = {...{}}
    //     product.mountable_service = {...product.mountable_service_temp}
    //   }
    //   Api(true).post("/diary/save/service", {product, type})
    //   .then(res => {
    //     if(res.data == 1){
    //       this.getDiary(this.diary.guid)
    //     }
    //   })
    // },
    async get_services(){
      await Api(true).post("/diary/get/services")
      .then(res => {
        this.all_services = res.data
      })
    },
    toggle_product_service(product, type){
      this.products.map(p => {
        if(p.guid == product.guid){
          if(type == 'transport'){
            p.has_transport ? p.has_transport = false : p.has_transport = true
            p.transport_service = null
          }
          else if(type == 'mountable'){
            p.has_installation ? p.has_installation = false : p.has_installation = true
            p.mountable_service = null
          }
          else if(type == 'reservation'){
            p.has_reservation ? p.has_reservation = false : p.has_reservation = true
            p.reservation_service = null
          }
        }
        return p
      })
    },
    delete_related_service(product){
      if (confirm("Doni te hiqni sherbimin nga ky produkt?")) {
        Api(true).post("/diary/delete/related/service", product)
        .then(res => {
          if(res.data == 1){
            this.getDiary(this.diary.guid)
          }
        })
      }
    },
    search_service_diary(type = null){
      Api(true).post("/diary/search/service", {
        diary: this.diary, 
        search: this.service_search_val,
        type: this.seleced_service_type ? this.seleced_service_type : type
      })
      .then(res => {
        // this.service_search_results
        this.service_search_results = {}
        if(res.data.length > 0){
          res.data.map(r => {
            if(!this.service_search_results[r.type_name]) this.service_search_results[r.type_name] = []
            this.service_search_results[r.type_name].push(r)
          })
        }
      })
    },
    toggle_expand_service(service, key){
      this.service_search_results[key].map(s => {
        if(s.guid == service.guid){
          s.expanded = !s.expanded
        }
        return s
      })
    },
    async add_diary_service(service){
      var i = ''
      await Api(true).post("/diary/add/service", {
        service, 
        diary_id: this.diary.guid,
        diary_detail: this.service_selected_product
      })
      .then(res => {
        if(res.data == 1){
          // Get diary
          this.getDiary(this.diary.guid)
          // Reset modal
          this.show_search_modes = false,
          // Product services
          this.seleced_service_type = ''
          this.service_selected_product = null
          this.show_service_modal = false
          i = 'success'
        }
      })
      return i
    },
    toggle_service_modal(type, product) {
      if (!product) {
        this.show_service_modal = false
      }
      if (product?.is_active < 2) {
        // Toggle modal
        this.show_service_modal = !this.show_service_modal
        // Clear search input, clear search results
        this.service_search_val = ''
        this.service_search_results = {}
        if(this.show_service_modal){
          this.seleced_service_type = type
          this.service_selected_product = {...product}
        }
        else {
          this.seleced_service_type = ''
          this.service_selected_product = null
        }
      }
    },
    test() {
      // Object.assign(this, permissionsMixin);
      permissionsMixin.methods.am_allowed('diary_roles.can_see_request_discount')
      // try it
    },
    searchProductToggle() {
      this.toggleAddProduct = true
      useSearchProcess().search_results = []
      useMainSearchStore().products_cart = []
      useSearchProcess().search_val = ''
      this.seleced_service_type = ''
      this.service_selected_product = null
      this.service_search_val = ''
      this.service_search_results = {}
    },
    edit_employee_discount() {
      this.employee_discount_mode = 2
      this.products = this.products.map(p => {
        if (parseInt(p.discounted_percent) > 0) {
          p.employee_discount_value = parseInt(p.discounted_percent)
          p.temp_discounted_price = p.discounted_price
          
          // p.employee_discount_type
          this.selected_for_discount.push(p)
          // this.calc_prod_discount_value(p)
        }
        return p
      })
    },
    format_percentage(value) {
      // Round the value to two decimal places
      const roundedValue = Math.round(value * 100) / 100;
      // Convert the rounded value to a string with 2 decimal places and add the percentage sign
      return roundedValue.toFixed(2) + '%';
    },
    select_all_products_discount() {
      if (this.selected_for_discount.length == 0) {
        this.selected_for_discount = this.products.filter(a => a.is_active == 1 && a.related_entity == 1 )
      }
      else {
        this.selected_for_discount = []
      }
    },
    reactivate_diary() {
      if (confirm("Doni te riaktivizoni kete ditar?")) {
        Api(true).post("/diary/reactivate", { diary: this.diary })
        .then(res => {
          if (res.data == 1) {
            window.location.reload();
          }
        })
      }
    },
    can_edit_amount(product) {
      // TODO: Check if can add product
      console.log(product)
      return true
      
    },
    async save_item_amount(product) {
      var toast = {}
      await Api(true).post("/diary/save/amount", {
        diary: this.diary,
        product
      }).then(res => {
        if (res.data.message == 'success') {
          toast.message = res.data.message
          this.products = [...this.products.map(p => {
            if (p.guid == product.guid) {
              p.amount = res.data.amount
              p.original_amount = res.data.amount
              p.change_amount = false
            }
            return p
          })]
        }
      })
      return toast
    },
    toggle_edit_amount(product) {
      this.products = [...this.products.map(p => {
        if (p.guid == product.guid) {
          if(!p.change_amount) p.change_amount = true
          else (p.change_amount = false)
        }
        return p
      })]
    },
    async cancel_discount_request(discount) {
      if (confirm("Doni te anulloni kerkesen per zbritje?")) {
        await Api(true).post("/diary/cancel/discount", { discount })
        .then(res => {
          if (res.data > 0) {
            window.location.reload();
          }
        })
      }
    },
    toggle_open_wait_modal() {
      this.show_wait_modal = !this.show_wait_modal
    },
    async save_postpone_request() {
      this.loading_postpone = true
      var toast = {}
      await Api(true).post("/diary/save/postpone/request", { 
        diary: this.diary
      })
      .then(res => {
        if (res.data > 0) {
          this.loading_postpone = false
          this.customer_waiting_edit_mode = false
          toast.message = 'success'
          this.getDiary(this.diary.guid)
        }
      })
      .catch(() => {
        this.loading_postpone = false
        this.customer_waiting_edit_mode = false
        toast.message = 'error'
      });
      console.log('toast...', toast)
      return toast
    },
    async start_cancel_customer_waiting() {
      this.diary.show_cancel_waiting = true
      // get reasoins
      await Api(true).post("/configuration/rejection/reasons/list")
      .then(res => {
        this.reject_reasons = res.data
      })
    },
    save_customer_waiting_reject() {
      Api(true).post("/diary/reject/customer/waiting", {
        diary: this.diary
      }).then(res => {
        if (res.data > 0) {
          window.location.reload();
        }
      })
    },
    toggle_time_mode(mode) {
      this.manage_time_mode = mode
    },
    async toggle_close_modal() {
      this.show_close_modal = !this.show_close_modal
      if(this.show_close_modal) {
        await Api(true).post("/configuration/rejection/reasons/list")
        .then(res => {
          this.reject_reasons = res.data
        })
      }
    },
    toggle_total_discount_mode() {
      this.discount_total_mode = !this.discount_total_mode
      this.total_discount_value = 0
      if (this.discount_total_mode) {
        // check if there are already discounts in the products
        var found = false
        this.discount_request.details.map(dr => {
          if(found) return
          if (dr.discount_status > 1) {
            if (confirm("Njeri ose disa nga produktet e kane skonton. Ne qofte se doni ti jepni ulje mbi totalin, ulja e produkteve duhet anulluar. Doni ta anulloni skonton individuale te produkteve?")) {
              found = true
              Api(true).post("/diary/discount/request/cancel", {
                'discount_request': this.discount_request
              }).then( async res => {
                if (res.data > 0) {
                  await this.get_diary_discount_request(this.discount_request.head.guid)
                }
              })
            }
            return dr
          }
          // console.log('dr', dr)
        })
        // If there are discounts - confirm to remove and remove discounts with api
        // Make discounts disable
      }
    },
    calculate_discount_total() {
      // console.log('total: ', useDiaryStore().diary_totals.total_product)
      var overflows = false
      var total_discount_percentage = (this.total_discount_value / this.diary_totals.total_product) * 100
      this.discount_request.details = this.discount_request.details.map(dr => {
        dr.total_percent_weight = (dr.product_price * 100) / this.diary_totals.total_product
        var discount_value = (dr.total_percent_weight * total_discount_percentage) / 100
        dr.discount_value = numeral(discount_value).format('0,0.00');
        if (this.discount_request.head.max_employee_discount < dr.discount_value) {
          alert('...')
          overflows = true
        }
          
        dr.discount_type = 1
        console.log('dr', dr)
        return dr
      })

      this.save_total_discount_mode = true
      if (overflows) {
        this.discount_request.details = this.discount_request.details.map(dr => {
          dr.discount_value = 0
          dr.discount_type = 0
          return dr
        })
        this.save_total_discount_mode = false
      }
    },
    save_total_discount(is_process = false) {
      if (confirm("Jeni te sigurt qe doni te jepni kete skonto?")) {
        Api(true).post("/diary/save/total/discount", {
          discount_request: this.discount_request,
          is_process: is_process
        }).then(res => {
          if (res.data.length > 0) {
            window.location.reload();
          }
        })
      }
    }
  }
})
