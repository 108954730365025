`<template>
  <div class="min-h-screen bg-gray-50 p-8">
    <!-- Koka -->
    <div class="mb-8">
      <h1 class="text-2xl font-semibold text-gray-800">Paneli</h1>
      <p class="text-gray-500">Përmbledhje e metrikave të biznesit</p>
    </div>

    <!-- Bllok Statistikash -->
    <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6 mb-8">
      <!-- Shitje -->
      <div class="bg-white rounded-lg shadow-md p-6">
        <div class="flex items-center justify-between mb-4">
          <div class="p-3 rounded-full bg-sky-700">
            <svg xmlns="http://www.w3.org/2000/svg" class="w-6 h-6 text-white" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 7h8m0 0v8m0-8l-8 8-4-4-6 6" />
            </svg>
          </div>
          <h3 class="text-gray-500 text-sm font-medium">SHITJE</h3>
        </div>
        <p class="text-2xl font-bold">{{ formatNumber(dashboardStore.sales) }} ALL</p>
      </div>

      <!-- Blerje -->
      <div class="bg-white rounded-lg shadow-md p-6">
        <div class="flex items-center justify-between mb-4">
          <div class="p-3 rounded-full bg-cyan-700">
            <svg xmlns="http://www.w3.org/2000/svg" class="w-6 h-6 text-white" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 3h2l.4 2M7 13h10l4-8H5.4M7 13L5.4 5M7 13l-2.293 2.293c-.63.63-.184 1.707.707 1.707H17m0 0a2 2 0 100 4 2 2 0 000-4zm-8 2a2 2 0 11-4 0 2 2 0 014 0z" />
            </svg>
          </div>
          <h3 class="text-gray-500 text-sm font-medium">BLERJE</h3>
        </div>
        <p class="text-2xl font-bold">{{ formatNumber(dashboardStore.purchases) }} ALL</p>
      </div>

      <!-- Profit -->
      <div class="bg-white rounded-lg shadow-md p-6">
        <div class="flex items-center justify-between mb-4">
          <div class="p-3 rounded-full bg-green-700">
            <svg xmlns="http://www.w3.org/2000/svg" class="w-6 h-6 text-white" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 8c-1.657 0-3 .895-3 2s1.343 2 3 2 3 .895 3 2-1.343 2-3 2m0-8c1.11 0 2.08.402 2.599 1M12 8V7m0 1v8m0 0v1m0-1c-1.11 0-2.08-.402-2.599-1M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
            </svg>
          </div>
          <h3 class="text-gray-500 text-sm font-medium">PROFIT</h3>
        </div>
        <p class="text-2xl font-bold">{{ formatNumber(dashboardStore.profit) }} ALL</p>
      </div>

      <!-- Porosi në Pritje -->
      <div class="bg-white rounded-lg shadow-md p-6">
        <div class="flex items-center justify-between mb-4">
          <div class="p-3 rounded-full bg-orange-600">
            <svg xmlns="http://www.w3.org/2000/svg" class="w-6 h-6 text-white" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z" />
            </svg>
          </div>
          <h3 class="text-gray-500 text-sm font-medium">POROSI NË PRITJE</h3>
        </div>
        <p class="text-2xl font-bold">{{ dashboardStore.pending_orders }}</p>
      </div>
    </div>

    <!-- Seksioni i Artikujve -->
    <div class="font-light border-blue-50 border-b-2 mb-6">ARTIKUJ</div>
    <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
      <!-- Artikuj të Sapo Ardhur -->
      <div class="bg-white rounded-lg shadow-md">
        <div class="bg-sky-700 p-3 rounded-t-lg">
          <h3 class="text-white text-sm font-medium">TË SAPO ARDHURA</h3>
        </div>
        <div class="p-4">
          <ul class="divide-y divide-gray-200">
            <li v-for="article in dashboardStore.new_articles" 
                :key="article.guid"
                class="py-3 flex justify-between items-center">
              <span class="text-sm text-gray-600 truncate max-w-[200px]">{{ article.name }}</span>
              <span class="text-sm font-semibold">{{ article.total_quantity }}</span>
            </li>
          </ul>
        </div>
      </div>

      <!-- Artikuj në Limit -->
      <div class="bg-white rounded-lg shadow-md">
        <div class="bg-red-700 p-3 rounded-t-lg">
          <h3 class="text-white text-sm font-medium">NË LIMIT</h3>
        </div>
        <div class="p-4">
          <ul class="divide-y divide-gray-200">
            <li v-for="article in dashboardStore.stock_limit_articles" 
                :key="article.guid"
                class="py-3 flex justify-between items-center">
              <span class="text-sm text-gray-600 truncate max-w-[200px]">{{ article.name }}</span>
              <span class="text-sm font-semibold">{{ article.total_quantity }}</span>
            </li>
          </ul>
        </div>
      </div>

      <!-- Artikujt më të Shitur -->
      <div class="bg-white rounded-lg shadow-md">
        <div class="bg-green-700 p-3 rounded-t-lg">
          <h3 class="text-white text-sm font-medium">MË TË SHITUR</h3>
        </div>
        <div class="p-4">
          <ul class="divide-y divide-gray-200">
            <li v-for="article in dashboardStore.most_sold_articles" 
                :key="article.guid"
                class="py-3 flex justify-between items-center">
              <span class="text-sm text-gray-600 truncate max-w-[200px]">{{ article.name }}</span>
              <span class="text-sm font-semibold">{{ Math.abs(article.total_quantity) }}</span>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { onMounted } from 'vue'
import { useDashboardStore } from '@/stores/dashboard'

const dashboardStore = useDashboardStore()

const formatNumber = (value) => {
	if (!value) return 0
  return new Intl.NumberFormat('sq-AL').format(value)
}

onMounted(() => {
  dashboardStore.getReports()
})
</script>`
