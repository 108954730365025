
<template>
    <div>
        <span @click="toggle_validate_modal()" 
            class="px-3 py-2 ml-2 rounded is-clickable" 
            :class="{'bg-orange-300' : useProcessStore().process.pending_discount}"
            style="box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;" >
            <i class="fa-solid fa-hand-holding-dollar"></i>
            <i v-if="useProcessStore().process.pending_discount" class="fa-solid fa-hourglass-half ml-2"></i>
        </span>
        <!-- BLOCK: Product service modal -->
        <div class="h-screen w-screen fixed top-0 left-0 bg-black bg-opacity-50 flex items-start justify-center" style="z-index:999;"
            v-if="useProcessStore().show_process_discount_modal" @click.self="toggle_validate_modal()">
            <div class="p-0 bg-white mt-20 flex-col products-search">
                <div class="p-2 bg-slate-100 text-center">
                    Kerko cmim
                </div>
                <div class="flex flex-col text-14px">
                    <div class="flex flex-col  mb-2">
                        <div class="flex flex-col w-full" style="overflow-y:scroll; max-height:500px;">
                            <div class="flex items center text-slate-400 items-top" style="font-size:13px;">
                                <span class="pl-1" style="width:30px;">
                                    <span>
                                    <span @click="useDiaryStore().select_all_products_discount()">
                                        <i v-if="useDiaryStore().selected_for_discount.length == useDiaryStore().products.filter(a => a.is_active == 1 && a.related_entity == 1).length" class="fa-regular fa-square-check"></i>
                                        <i v-else class="fa-regular fa-square"></i>
                                    </span>
                                    </span>
                                </span>
                                <span class="w-1/2">Emri i Produktit</span>
                                <small 
                                    class="ml-auto mr-2 text-slate-400 text-right" style="width:120px;">Zbritje</small>
                                <!-- <small class="ml-auto mr-2 text-slate-400 text-right" style="width:50px;">Sasia</small> -->
                                <div class="ml-auto" style="width:180px;">
                                    <div class="flex flex-col">
                                    <small class="  text-center border-b">Cmimi</small>
                                    <div class="flex">
                                        <small class=" text-center w-1/2 border-r">Produkt</small>
                                        <small class=" text-center w-1/2">Aktual</small>
                                    </div>
                                    </div>
                                </div>
                            </div>

                            <div v-for="(product, index) in useProcessStore().product_list" :key="index"
                                class="flex  cursor-pointer items-top border-b border-t border-slate-100 ">
                                <span class="px-2" style="width:30px;">
                                    <span v-if=" 
                                    useProcessStore().process.document_data.discount_request &&
                                    useProcessStore().process.document_data.discount_request.length < 5 && 
                                    useDiaryStore().employee_discount_mode != 0">
                                        <span @click="useDiaryStore().toggle_discount_product(product)">
                                            <i v-if="useDiaryStore().selected_for_discount.includes(product)" class="fa-regular fa-square-check"></i>
                                            <i v-else class="fa-regular fa-square"></i>
                                        </span>
                                    </span>

                                    

                                </span>
                                <span class="w-1/2 flex flex-col">
                                    {{product.name}}
                                    <small class="text-slate-400 text-10px ml-1">{{product.code}}</small>

                                </span>
                                <div v-if="useDiaryStore().employee_discount_mode == 2"
                                    class="ml-auto mr-2  text-right" style="width:120px;">
                                    <div v-if="useDiaryStore().selected_for_discount.includes(product)">
                                    <div class="flex flex-col">
                                        <div class="flex">
                                            <!-- :class="{'border-red-500': product.employee_discount_value > useProcessStore().process.document_data.max_employee_discount}" -->
                                        <input v-model="product.employee_discount_value" @keyup="useProcessStore().calc_prod_discount_value(product)" type="number" 
                                            style="-moz-appearance: none; -webkit-appearance: none; margin: 0; "
                                            class="border-t border-b border-l rounded-l text-right px-1 text-black"> 
                                        <a @click.prevent="useProcessStore().toggle_employee_discount_type(product)" 
                                            class="border border-r rounded-r px-2 text-14px flex items-center">
                                            <span v-if="product.employee_discount_type == 1"> % </span>
                                            <span v-if="!product.employee_discount_type"> Leke </span>
                                        </a>
                                        </div>
                                        <div class="flex text-slate-400"
                                        :class="{'text-red-500!important': product.employee_discount_value > useProcessStore().process.document_data.max_employee_discount}">
                                        <small class="text-8px ">Skonto max {{useDiaryStore().format_percentage(useProcessStore().process.document_data.max_employee_discount)}}.</small>
                                        </div>
                                    </div>
                                    </div>
                                </div>
                                <div class="flex ml-auto" style="width:180px;">
                                    <span class="w-1/2 text-right border-r pr-2 " >
                                        <span>
                                            {{useDiaryStore().price_value_filter(product.price)}} 
                                            <span class="text-slate-400 text-10px">Leke</span>
                                        </span>
                                    </span>
                                    <span class="flex flex-col w-1/2 text-right text-green-700 font-semibold" >
                                    <span >
                                        <span :class="{'line-through opacity-50': product.temp_discounted_price}">
                                            {{product.discounted_price > 0 ? useDiaryStore().price_value_filter(product.discounted_price) : 
                                            useDiaryStore().price_value_filter(product.price)}} 
                                        </span>
                                        <span class="text-slate-400 text-10px">Leke</span>
                                    </span>
                                    <span v-if="product.discounted_price > 0" class="text-8px text-slate-400 flex flex-col">
                                        <span> 
                                        Zbritje {{useDiaryStore().format_percentage(useDiaryStore().product_discount_value(product))}}
                                        ({{useDiaryStore().price_value_filter(parseFloat(product.price) - parseFloat(product.discounted_price))}}Leke)
                                        </span>
                                    </span>
                                    <div v-if="product.temp_discounted_price"
                                        class="text-green-700"
                                        style="white-space: nowrap;">
                                        {{ useDiaryStore().price_value_filter(product.temp_discounted_price)}} 
                                        <span class="text-slate-400 text-10px">Leke</span>
                                        </div>
                                    </span>
                                </div>
                            </div>

                            <div class="w-full md:ml-auto md:w-2/3 md:flex justify-end my-2">
                                <span v-if="useProcessStore().process.document_data?.status_id == 6"
                                    class="border border-slate-300 rounded px-2 py-1 text-sm">
                                    Ne pritje te kerkeses per cmim
                                </span>
                                <div v-else-if="useProcessStore().process.document_data.discount_request && useProcessStore().process.document_data.discount_request.length < 5" >
                                    <div v-if="useDiaryStore().employee_discount_mode == 0" class="flex w-full justify-end">
                                        <a v-if="useProcessStore().can_give_discount && useProcessStore().process.document_data.max_employee_discount > 0" 
                                        @click.prevent="useDiaryStore().employee_discount_mode = 2"
                                        class="px-2 py-1 bg-slate-600 border border-slate-900 text-white hover:text-white rounded text-sm mr-2"
                                        href="">
                                        Jep Zbritje</a>
                                        <a v-if="am_allowed('diary_roles.can_request_discount')"
                                        @click.prevent="useDiaryStore().start_request_discount(1)" 
                                        class="px-2 py-1 bg-slate-600 border border-slate-900 text-white hover:text-white rounded text-sm" href="">
                                        Kerko Zbritje</a>
                                    </div>
                                    <div v-else-if="useDiaryStore().employee_discount_mode == 1">
                                        <span v-if="useProcessStore().loading_request_discount"
                                        class=" px-2 py-1 bg-slate-600 border border-slate-900 text-white hover:text-white rounded text-sm" >
                                        <i class="fa fa-spinner fa-spin"></i>
                                        </span>
                                        <div v-else class="flex items-end ">
                                        <div class="flex flex-col mr-2 ml-auto w-48">
                                            <span class="text-slate-400 text-10px text-right">Kush do e aprovoje kerkesen </span>

                                            <div class="relative flex justify-end">
                                                <span class="text-slate-400 text-10px flex items-center mr-2">
                                                    {{useDiaryStore().selected_approver ? useDiaryStore().selected_approver.name : ''}}
                                                </span>
                                                <a @click.prevent="useDiaryStore().toggle_approvers_list()" href="" 
                                                    class="border rounded  pt-1 px-2 bg-white">
                                                    <i class="fa-solid fa-angle-down"></i>
                                                </a>
                                                <div v-if="useDiaryStore().show_approvers_list" 
                                                    class="border rounded bg-white absolute w-40 md:right-0" style="top: 30px;">
                                                    <div v-for="(approver, index) in useDiaryStore().discount_approvers" :key="index"
                                                    :class="{'bg-slate-100': useDiaryStore().diary.discount_assigned_to == approver.id}"
                                                    @click.prevent="useDiaryStore().set_diary_approver(approver, 1)"
                                                    class="w-full text-14px px-1 flex border-b cursor-pointer hover:bg-slate-100">
                                                        <span> {{approver.name}} </span>
                                                        <span class="text-right ml-auto">
                                                            ({{approver.max_employee_discount}})
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="flex flex-col">
                                            <div class="flex justify-end text-12px text-slate-400 mr-2 mb-2">
                                            <span @click="useDiaryStore().employee_discount_mode = 0" class="">
                                                <i class="fa-solid fa-xmark"></i>
                                            </span>
                                            </div>
                                            <span v-if="useDiaryStore().selected_for_discount.length == 0"
                                            class="text-8px text-slate-400">
                                            Zgjidh nje ose disa nga produktet me siper. 
                                            </span>
                                            <a 
                                            @click.prevent="useDiaryStore().request_disctount(true)"
                                            :class="{'opacity-50': useDiaryStore().selected_for_discount.length == 0}"
                                            class=" px-2 py-1 bg-slate-600 border border-slate-900 text-white hover:text-white rounded text-sm" 
                                            style="width: max-content;"
                                            href="">Dergo Per Konfirmim Zbritje</a>
                                        </div>
                                        </div>
                                    </div>
                                    <div v-if="useDiaryStore().employee_discount_mode == 2" key="" class="flex flex-col">
                                        <div class="flex justify-end text-12px text-slate-400 mr-2 mb-2">
                                        <span @click="useProcessStore().cancel_employee_discount()" class="">
                                            <i class="fa-solid fa-xmark"></i>
                                        </span>
                                        </div>
                                        <span v-if="useProcessStore().loading_request_discount"
                                        class=" px-2 py-1 bg-slate-600 border border-slate-900 text-white hover:text-white rounded text-sm" >
                                        <i class="fa fa-spinner fa-spin"></i>
                                        </span>
                                        <a v-else
                                        @click.prevent="useDiaryStore().employee_request_discount(true)"
                                        :class="{'opacity-50': useDiaryStore().selected_for_discount.length == 0}"
                                        class=" px-2 py-1 bg-slate-600 border border-slate-900 text-white hover:text-white rounded text-sm" 
                                        href="">Konfirmo </a>
                                    </div>
                                </div>
                            </div>


                            <!-- Total row -->
                            <div class="flex pl-2">
                                <div class="w-1/2 text-slate-400">Totali i produkteve</div>
                                <div style="width:30px;"></div>
                                <div class="flex ml-auto " style="width:180px;"> 
                                    <span class="w-1/2 py-2 text-right border-r pr-2">
                                    <span v-if="useProcessStore().have_discount">
                                        {{ useDiaryStore().price_value_filter(useProcessStore().active_total_no_discount) }}
                                        <span class="text-slate-400 text-10px">Leke</span>
                                    </span> 
                                    </span>
                                    <div class="w-1/2 py-2 flex flex-col  ">
                                    <span class="text-right text-green-700 font-semibold">
                                        {{useDiaryStore().price_value_filter(useProcessStore().active_total)}}
                                        <span class="text-slate-400 text-10px">Leke</span>
                                    </span>
                                    <span v-if="useProcessStore().average_discount.value > 0"
                                        class="flex flex-col text-10px text-right">
                                        <span>
                                        <!-- Zbritje {{useDiaryStore().average_discount.percentage.toFixed(2)}}% -->
                                        Zbritje {{useDiaryStore().format_percentage(useDiaryStore().price_value_filter(useProcessStore().average_discount.percentage))}}
                                        </span>
                                        <span>
                                        ({{useDiaryStore().price_value_filter(useProcessStore().average_discount.value)}}
                                        <span class=" text-10px">Leke</span>)
                                        </span>
                                    </span>
                                    </div>
                                </div>
                            </div>

                            <div v-if="useProcessStore().process.document_data.discount_request && useProcessStore().process.document_data.discount_request.length > 0" 
                                class="border rounded p-1  border-blue-500 my-3">
                                <div class="text-14px flex justify-between">
                                    <span>
                                        Kerkesat per cmim 
                                    </span>
                                    <span>
                                        {{useProcessStore().process.document_data.discount_request ? useProcessStore().process.document_data.discount_request.length : 0}} / 5 max
                                    </span>
                                </div>
                                <table class="table w-full table-products is-narrow ">
                                    <thead>
                                        <tr class="faded-header">
                                            <th class="text-10px text-slate-100">ID</th>
                                            <th class="text-10px text-slate-100">Kerkuar nga</th>
                                            <th class="text-10px text-slate-100">Kerkuar me</th>
                                            <th class="text-10px text-slate-100">Caktuar per</th>
                                            <th class="text-10px text-slate-100">Perditesuar nga</th>
                                            <th class="text-10px text-slate-100">Perditesuar me</th>
                                            <th class="text-10px text-slate-100">Statusi</th>
                                            <th class="text-right">#</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="request in useProcessStore().process.document_data.discount_request" :key="request.guid" 
                                            >
                                            <td>{{request.id}}</td>
                                            <td>{{request.requested_by_name}}</td>
                                            <td>{{useDiaryStore().formatDate(request.created_at)}}</td>
                                            <td>
                                            {{request.assigned_to_name ? request.assigned_to_name : 'Pa percaktuar'}}
                                            </td>
                                            <td>
                                            {{request.status_updated_by_name ? request.status_updated_by_name : ''}}
                                            <span v-if="request.by_employee == 1 && request.request_status == 2">
                                                <i class="fa-solid fa-user"></i>
                                            </span>
                                            </td>
                                            <td>
                                            <span v-if="request.by_employee == 1 && request.request_status == 2">
                                                {{ useDiaryStore().formatDate(request.created_at) }}
                                            </span>
                                            <span v-else>
                                                {{request.status_updated_at ? useDiaryStore().formatDate(request.status_updated_at) : ''}}
                                            </span>
                                            </td>
                                            <td :class="{
                                            'text-red-700': request.request_status == 3, 
                                            'text-green-700': request.request_status == 2 || request.by_employee == 1,
                                            'text-blue-500' : request.request_status == 1
                                            }">
                                                <span v-if="request.by_employee == 1 && request.request_status == 2">
                                                <i class='fa-solid fa-check'></i>
                                                </span>
                                                <span v-else-if="request.request_status == 1">
                                                <i class="fa-solid fa-hourglass-half"></i>
                                                </span>
                                                <span v-else-if="request.request_status == 2">
                                                <i class='fa-solid fa-check'></i>
                                                </span>
                                                <span v-else-if="request.request_status == 3">
                                                <i class="fa-solid fa-xmark"></i>
                                                </span>
                                                <span v-else-if="request.request_status == 4">
                                                <i class="fa-solid fa-trash-can text-red-500"></i>
                                                </span>

                                            <!-- {{
                                                request.by_employee == 1 && request.request_status == 2 ? 'Zbritje nga operatori' :
                                                request.request_status == 1 ? 'Ne pritje te komfirmimit' :
                                                request.request_status == 2 ? "<i class='fa-solid fa-check'></i>" : 
                                                request.request_status == 3 ? 'Kerkese e refuzuar' : ''
                                            }} -->
                                            </td>
                                            <td class="text-right flex justify-end">
                                            <div @click="open_discount_template(request.guid)" 
                                                class="cursor-pointer">
                                                <i class="fa-solid fa-up-right-from-square"></i>
                                            </div>
                                            <div v-if="request.request_status == 1 && (
                                                useDiaryStore().current_user.id == request.by_employee || 
                                                am_allowed('diary_roles.can_cancel_discount')
                                            )" 
                                                @click="useDiaryStore().cancel_discount_request(request)"
                                                class="cursor-pointer mx-2 text-red-500"
                                                title="I anulluar">
                                                <i class="fa-solid fa-trash-can"></i>
                                            </div>
                                            
                                            <div v-if="useProcessStore().can_edit_employee_discount"
                                                @click.prevent="useDiaryStore().edit_employee_discount()"
                                                class="ml-2 cursor-pointer">
                                                <i class="fa-solid fa-pencil"></i>
                                            </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>


                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
    import { useProcessStore } from '@/stores/process'
    import { useDiaryStore } from '@/stores/diary'
    
</script>

<script>
import permissionMixin from '@/mixins/permissions-mixin.js'
export default {
    mixins: [permissionMixin],
    data() {

    },
    methods: {
        toggle_validate_modal(){
            useProcessStore().show_process_discount_modal = !useProcessStore().show_process_discount_modal
        },
        open_discount_template(request_id) {
            this.$router.push({ name: 'Konfirmo zbritje proces', params: { id: request_id, is_process: 1 } })
        }
    }
}
</script>
<style >
    .products-search {
        min-width: 80%;
        margin-left: 30px;
        margin-right: 30px;
    }
</style>