<template>
  <div class="w-full">
    <label class="block text-sm font-medium text-gray-700">Grupo sipas</label>
    
    <!-- Available Columns -->
    <div class="relative">
      <a
        @click.prevent="toggleDropdown"
        class="w-full flex items-center justify-between px-3 py-2 border border-gray-300 rounded-md shadow-sm bg-white text-sm text-gray-500 hover:bg-gray-50"
      >
        <span>Shto kolona</span>
        <i class="fas fa-chevron-down"></i>
      </a>

      <!-- Dropdown -->
      <div
        v-if="isOpen"
        class="absolute z-10 mt-1 w-full bg-white rounded-md shadow-lg border border-gray-200 py-1"
      >
        <div class="max-h-48 overflow-y-auto">
          <button
            v-for="column in availableColumns"
            :key="column.value"
            @click="addColumn(column.value)"
            class="w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 flex items-center justify-between group"
          >
            <span>{{ column.label }}</span>
            <i class="fas fa-plus text-gray-400 opacity-0 group-hover:opacity-100 transition-opacity"></i>
          </button>
        </div>
      </div>
    </div>
    <!-- Selected Groups -->
    <div class="bg-white rounded-md border border-gray-300 p-2 min-h-[120px]">
      <div v-if="modelValue.length === 0" class="flex items-center justify-center h-20 text-gray-400 text-sm">
        Zgjidh kolonat për grupim
      </div>
      
      <draggable
        v-model="localValue"
        class="space-y-2"
        handle=".drag-handle"
        item-key="value"
        @end="updateOrder"
      >
        <template #item="{ element }">
          <div class="flex items-center justify-between bg-gray-50 p-2 rounded border border-gray-200 group">
            <div class="flex items-center space-x-2">
              <button class="drag-handle text-gray-400 hover:text-gray-600 cursor-move opacity-0 group-hover:opacity-100 transition-opacity">
                <i class="fas fa-grip-vertical"></i>
              </button>
              <span class="text-sm text-gray-700 px-2">{{ getColumnLabel(element) }}</span>
            </div>
            <button 
              @click="removeColumn(element)"
              class="text-gray-400 hover:text-red-500 opacity-0 group-hover:opacity-100 transition-opacity"
            >
              <i class="fas fa-times"></i>
            </button>
          </div>
        </template>
      </draggable>
    </div>

  </div>
</template>

<script setup>
import { ref, computed, watch, onMounted, onUnmounted, defineProps, defineEmits } from 'vue'
import draggable from 'vuedraggable'

const props = defineProps({
  modelValue: {
    type: Array,
    required: true
  }
})

const emit = defineEmits(['update:modelValue'])

const isOpen = ref(false)
const localValue = ref([...props.modelValue])

// Available columns configuration
const allColumns = [
  { value: 'created_at_date', label: 'Data' },
  { value: 'created_by', label: 'Operatori' },
  { value: 'pos_name', label: 'Dyqani' },
  { value: 'customer_name', label: 'Klienti' },
  { value: 'supplier_name', label: 'Furnitori' },
  { value: 'status', label: 'Statusi' },
  { value: 'document_price', label: 'Vlera' }
]

// Computed available columns (not already selected)
const availableColumns = computed(() => {
  return allColumns?.filter(col => !localValue.value.includes(col.value))
})

// Get label for a column value
const getColumnLabel = (value) => {
  const column = allColumns.find(col => col.value === value)
  return column ? column.label : value
}

// Toggle dropdown
const toggleDropdown = () => {
  isOpen.value = !isOpen.value
}

// // Close dropdown when clicking outside
// const closeDropdown = (e) => {
//   if (!e.target.closest('.group-selector')) {
//     isOpen.value = false
//   }
// }

// Add column to selection
const addColumn = (value) => {
  localValue.value.push(value)
  emit('update:modelValue', localValue.value)
  isOpen.value = false
}

// Remove column from selection
const removeColumn = (value) => {
  localValue.value = localValue.value.filter(col => col !== value)
  emit('update:modelValue', localValue.value)
}

// Update order after drag
const updateOrder = () => {
  emit('update:modelValue', localValue.value)
}

// Watch for external changes
watch(() => props.modelValue, (newVal) => {
  localValue.value = [...newVal]
}, { deep: true })

// Add click outside listener
onMounted(() => {
  // document.addEventListener('click', closeDropdown)
})

onUnmounted(() => {
  // document.removeEventListener('click', closeDropdown)
})
</script>

<style scoped>
.group-selector {
  position: relative;
}

.overflow-y-auto::-webkit-scrollbar {
  width: 4px;
}

.overflow-y-auto::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 2px;
}

.overflow-y-auto::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 2px;
}

.overflow-y-auto::-webkit-scrollbar-thumb:hover {
  background: #666;
}
</style>
