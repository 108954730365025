
<template>
    <div >
        <span @click="toggle_validate_modal()" class="px-3 py-2 ml-2 rounded is-clickable" style="box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
            background: rgb(255, 255, 255);" >
            <i class="fa-solid fa-list-check"></i>
        </span>
        <!-- BLOCK: Product service modal -->
        <div class="h-screen w-screen fixed top-0 left-0 bg-black bg-opacity-50 flex items-start justify-center" style="z-index:999;"
            v-if="useProcessStore().show_validate_modal" @click.self="toggle_validate_modal()">
            <div class="p-0 bg-white mt-20 flex-col products-search">
                <div class="p-2 bg-slate-100 text-center">
                    Valido produktet
                </div>
                <div  v-show="true" class="px-3 py-2">
                    <input v-model="validate_search" type="text" id="hidden_search" 
                    @keyup.enter="search_product_validate()" placeholder="Skano barkodin e produktit" class="border border-slate-700 px-2">
                </div>
                <div class="flex flex-col text-14px">
                    <div class="flex flex-col  mb-2">
                        <div v-for="product in useProcessStore().product_list" :key="product.guid"
                         class="mb-2">
                            <div class="flex justify-between items-center w-full border-b p-4" :class="[product.validating ? 'text-green-500 font-bold' : '']">
                                <div>{{product.name}}</div>
                                <div class="w-1/3 relative flex" >
                                        <!-- :ref="'serial_input' + product.detail_id" -->
                                    <input @keyup.enter="start_save_pdi_serial(product)"
                                        v-model="product.serial_number"
                                        :ref="'serial_input_' + product.detail_id"
                                        class="border-t border-b border-l rounded-l px-1 text-right pl-5">
                                    <span class="fa-solid fa-barcode absolute left-2 top-1/2 transform -translate-y-1/2 "></span>
                                    <a @click.prevent="start_save_pdi_serial(product)" class="bg-teal-300 border rounded-r px-2 flex justify-center items-center text-white hover:text-white" href="">
                                        <i class="fa-solid fa-check"></i>
                                    </a>
                                    <a @click.prevent="clear_serial_number(product)" class="bg-red-300 border rounded-r flex justify-center items-center  border-gray-50 p-4 text-white hover:text-white" href="">
                                        <i class="fa-solid fa-xmark"></i>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
    import { useProcessStore } from '@/stores/process'
</script>

<script>
export default {

    data() {
        return {
            validate_search: "",
        }
    },
    methods: {
        toggle_validate_modal(){
            useProcessStore().show_validate_modal = !useProcessStore().show_validate_modal
            if(useProcessStore().show_validate_modal) {
                setTimeout(() => {
                    document.getElementById("hidden_search").focus()
                }, 500)
            }
        },
        async start_save_pdi_serial(product){
            var duplicated = 0
             useProcessStore().product_list.map(p => {
                if(p.serial_number == product.serial_number){
                    duplicated = duplicated + 1
                }
            })
            if(duplicated > 1){
                product.serial_number = null
                this.$toast.error('Nuk mund te vendosni te njejtin numer serial me shume se 1 here!')
                return
            }
            var a = await useProcessStore().save_pdi_serial(product)
            if(a.message == 'success'){

                // check if there are others to validate as have no serial 
                var not_validated = false 
                useProcessStore().product_list.map(p => {
                    if(!p.serial_number) {
                        not_validated = true
                    }
                })
                if(!not_validated) { 
                    useProcessStore().show_validate_modal = false
                    this.$toast.success('Produkted u validuan me sukses!')
                }
                else {
                    this.validate_search = ""
                    document.getElementById("hidden_search").focus()
                    this.$toast.success('Numri serial u ruajt me sukses!')
                }
            } else {
                this.$toast.error('Numri serial nuk u ruajt!')
            }
        },
        async clear_serial_number(product){
            product.serial_number = null
            await useProcessStore().save_pdi_serial(product)
        },
        search_product_validate(){
            var found = false

            for(var i = 0; i < useProcessStore().product_list.length; i++){
                if(useProcessStore().product_list[i].barcode == this.validate_search && useProcessStore().product_list[i].serial_number != "")
                {
                    this.$refs['serial_input_' + useProcessStore().product_list[i].detail_id][0].focus()
                    useProcessStore().product_list[i].validating = true
                    found = true
                } else {
                    useProcessStore().product_list[i].validating = false
                }
            }
            if(!found){
                this.$toast.error('Ky produkt nuk eshte ne liste!')
            }

        }
    }, 
    created() {
        // setTimeout(() => {
        //     document.getElementById("hidden_search").focus()
        // }, 500)
    },
}
</script>
<style >
    .products-search {
        min-width: 80%;
        margin-left: 30px;
        margin-right: 30px;
    }
</style>